<template>
  <div class="row no-gutters justify-content-center">
    <ul class="event-menu">
      <li
        :class="getEventMenuItemCssClass('current')"
        class="event-menu__item"
        @click="setEventCardsState('current')"
      >
        {{ $t('message.event.current') }}
      </li>
      <li
        :class="getEventMenuItemCssClass('upcoming')"
        @click="setEventCardsState('upcoming')"
      >
        {{ $t('message.event.upcoming') }}
      </li>
      <li
        :class="getEventMenuItemCssClass('all')"
        class="event-menu__item"
        @click="setEventCardsState('all')"
      >
        {{ $t('message.all') }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SwBlogEventCardListMenu',

  computed: {
    /** @returns String */
    eventCardsState () {
      return this.$store.getters['eventCards/getEventCardsState']
    }
  },

  methods: {
    setEventCardsState (state) {
      this.$store.dispatch('eventCards/setEventCardsState', state)
    },

    getEventMenuItemCssClass (itemStateName) {
      const classList = ['event-menu__item']
      if (this.eventCardsState === itemStateName) {
        classList.push('event-menu__item_active')
      }
      return classList
    }
  }
}
</script>

<style lang="scss" scoped>
.event-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 28px;
  list-style: none;
  padding: 0;

  @media screen and (max-width: 400px) {
    flex-direction: column;
    text-align: center;
  }

  .event-menu__item {
    margin: 0 8px;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--blog-event-list-menu);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      margin: 0 4px;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    @media screen and (max-width: 400px) {
      margin: 4px 0;
    }

    &.event-menu__item_active,
    &:hover,
    &:active {
      color: var(--blog-event-list-hover-menu);
    }
  }
}
</style>
