import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type {EducationTrajectory[]} */
    trajectories: [],
    scores: new Map(),
    isLoaded: false
})

const state = initialState()

const getters = {
  /**
   * @param state
   * @return {EducationTrajectory[]}
   */
    getTrajectories(state) {
        return state.trajectories
    },

    getScores(state) {
        return state.scores
    },

    hasResult(state) {
        return state.scores.size !== 0
    },

    isLoaded(state) {
        return state.isLoaded
    },

    questionsNum(state) {
      let value = 0
      state.trajectories.forEach(trajectory => value += trajectory.questions.length)
      return value
    }
}
const mutations = {
    ADD_TRAJECTORY(state, trajectory) {
        state.trajectories.push(trajectory)
    },

    SET_TRAJECTORIES(state, cardList) {
        state.trajectories = cardList
    },

    SET_SCORES(state, scores) {
        state.scores = scores
    },

    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },

    SET_IS_LOADED(state, isLoadedState) {
        state.isLoaded = isLoadedState
    }
}
const actions = {
    reset({ commit }) {
        commit('RESET')
    },

    setScores(context, scores) {
        context.commit('SET_SCORES', scores)
    },

    async fetchTrajectories(context, params) {
        context.commit('SET_IS_LOADED', false)
        const apiReadClient =
        new ApiReadClient('/api/education/trajectories', { params }, 'EducationTrajectory')
        await apiReadClient.execute()
        if (apiReadClient.hasError()) {
            for (const error of apiReadClient.errors) {
                context.dispatch('error/addError', new SwError(error), { root: true })
            }
        } else {
            for (const trajectory of apiReadClient.data) {
                context.commit('ADD_TRAJECTORY', trajectory)
            }
        }
        context.commit('SET_IS_LOADED', true)
    }
}

export default {
    namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
