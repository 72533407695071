<template>
  <div class="navigator-test-trajectory">
    <Transition name="fade" mode="out-in">
      <sw-trajectory-question
          v-if="trajectory && currQuestion"
          :key="`navigator_test_trajectory_${trajectory.id}_question_${currQuestion.id}`"
          :question-index="questionIndex"
          :question="currQuestion"
          @trajectory:question:answered="questionAnswered"
      />
    </Transition>
  </div>
</template>

<script>
import EducationTrajectory from '../../../classes/education/EducationTrajectory';
import SwTrajectoryQuestion from './TrajectoryQuestion';

export default {
  name: 'SwNavigatorTrajectoryTest',
  components: {SwTrajectoryQuestion},
  props: {
    questionIndex: {
      type: Number,
      required: true
    },
    trajectory: {
      type: EducationTrajectory,
      required: true
    }
  },

  emits: ['trajectory:test:completed', 'trajectory:question:answered'],

  data () {
    return {
      currIndex: 0,
      score: 0
    }
  },

  computed: {
    currQuestion () {
      return this.trajectory.questions[this.currIndex]
    }
  },

  watch: {
    trajectory: {
      deep: true,
      handler () {
        this.score = 0
        this.currIndex = 0
      }
    }
  },

  methods: {
    questionAnswered (data) {
      this.$emit('trajectory:question:answered')

      this.score += data.point
      if (this.currIndex === this.trajectory.questions.length - 1) {
        this.$emit('trajectory:test:completed', {id: this.trajectory.id, score: this.score})
      } else {
        this.currIndex++;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../sass/utils";

</style>
