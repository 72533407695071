<template>
  <div
    class="content-page-header"
  >
    <div class="row no-gutters justify-content-center justify-content-md-start">
      <sw-go-to-frontpage
        :content-id="contentId"
        :title="sectionName"
        :breadcrumbs="breadcrumbs"
      />
    </div>
    <div class="row no-gutters justify-content-center justify-content-md-start content-page-header__title">
      {{ title }}
    </div>
    <div
      v-if="bgImageUrl"
      class="row no-gutters justify-content-center justify-content-md-start content-page-header__img"
    >
      <img :src="bgImageUrl">
    </div>
  </div>
</template>

<script>
import SwGoToFrontpage from './control/GoToFrontpage'

export default {
  name: 'SwContentPageHeader',
  components: { SwGoToFrontpage },
  props: {
    bgImageUrl: {
      type: String,
      default: ''
    },
    contentId: {
      type: String,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    breadcrumbs: {
      type: Array,
      required: false,
    }
  },

  computed: {
    headerBgImage () {
      return `linear-gradient(180deg, var(--blog-event-card-main-image-start-gradient) 0%,
        var(--blog-event-card-main-image-end-gradient) 100%),
        url('${this.bgImageUrl}')`
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.content-page-header {
  display: flex;
  flex-direction: column;
  max-width: 1232px;
  width: 100%;
  color: var(--content-page-header-title);

  @media screen and (max-width: 1296px) {
    padding: 0 2.58vw;
  }

  .content-page-header__img {
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
      margin-bottom: 34px;
    }

    @media screen and (max-width: 375px) {
      margin-top: 18px;
      margin-bottom: 18px;
    }

    img {
      height: 384px;
      border-radius: 32px;

      @media screen and (max-width: 992px) {
        height: 320px;
      }

      @media screen and (max-width: 768px) {
        height: 230px;
      }

      @media screen and (max-width: 568px) {
        height: 200px;
      }
    }
  }

  .breadcrumbs__delimiter {
    background-image: url("/static/img/icon/right-bracket-dark.png");
  }

  .content-page-header__title {
    margin: 20px 0;
    text-align: left;
    font-size: 2.5rem;
    line-height: 3.5rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.75rem, 2.5rem);
    }

    @media screen and (max-width: 992px) {
      line-height: 2.5rem;
    }

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    @media screen and (max-width: 375px) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }
}
</style>
