import { createWebHistory, createRouter } from 'vue-router'
import Frontpage from '../views/Frontpage'
import LayoutDefault from '../layouts/LayoutDefault'
import EducationCourseView from '../views/education/EducationCourseView'
import TeamView from '../views/team/TeamView'
import GalleryAlbumView from '../views/gallery/GalleryAlbumView'
import BlogEventView from '../views/blog/event/BlogEventView'
import ContactsView from '../views/contacts/ContactsView'
import BlogTribuneView from '../views/blog/tribune/BlogTribuneView'
import AboutView from '../views/about/AboutView'
import PageNotFoundView from '../views/errors/PageNotFoundView'
import LayoutFrontPage from '../layouts/LayoutFrontPage'
import BlogPostView from '../views/blog/post/BlogPostView'
import DocsView from '../views/docs/DocsView'
import NavigatorView from '../views/education/navigator/NavigatorView';
import NavigatorTestView from '../views/education/navigator/NavigatorTestView';
import EducationTrajectoryView from '../views/education/trajectories/EducationTrajectoryView';
import TrajectoryEducationCourseView from '../views/education/trajectories/EducationCourseView';
import NavigatorResultView from '../views/education/navigator/NavigatorResultView';
import AtlasView from '../views/atlas/AtlasView';
import GrantsView from '../views/grants/GrantsView.vue';

window.VueRouter = require('vue-router')

const history = createWebHistory()
const routes = [
  {
        path: '/',
        name: 'frontpage',
        component: Frontpage,
        meta: {
            layout: LayoutFrontPage,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/atlas',
        name: 'atlasView',
        component: AtlasView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/education/courses/:courseIdentifier',
        name: 'educationCourseView',
        component: EducationCourseView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/education/trajectories/:trajectorySlug/courses/:courseSlug',
        name: 'trajectoryEducationCourseView',
        component: TrajectoryEducationCourseView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/education/trajectories/:trajectoryIdentifier',
        name: 'educationTrajectoryView',
        component: EducationTrajectoryView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/gallery/albums/:albumIdentifier',
        name: 'galleryAlbumView',
        component: GalleryAlbumView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
      path: '/grants',
      name: 'grantsView',
      component: GrantsView,
      meta: {
        layout: LayoutDefault,
        stayOnLanguageChange: false
      }
    },
    {
        path: '/blog/events/:eventIdentifier',
        name: 'blogEventView',
        component: BlogEventView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/blog/tribunes/:tribuneIdentifier',
        name: 'blogTribuneView',
        component: BlogTribuneView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/blog/posts/:postIdentifier',
        name: 'blogPostView',
        component: BlogPostView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/team',
        name: 'team',
        component: TeamView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/docs',
        name: 'docs',
        component: DocsView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/navigator',
        name: 'navigatorView',
        component: NavigatorView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/navigator/test',
        name: 'navigatorTestView',
        component: NavigatorTestView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/navigator/result',
        name: 'navigatorResultView',
        component: NavigatorResultView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: true
        }
    },
    {
        path: '/error/404',
        name: 'pageNotFound',
        component: PageNotFoundView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'pageNotFoundGlobal',
        component: PageNotFoundView,
        meta: {
            layout: LayoutDefault,
            stayOnLanguageChange: false
        }
    }
    ]

    const router = createRouter({
        routes,
        history,
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                return {el: to.hash, behavior: 'smooth'}
            } else if (to.params.contentId === undefined) {
                return {el: '#header', behavior: 'auto'}
            }
        }
    })

export default router
