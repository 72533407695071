<template>
  <div
    v-if="isLoadingData"
    v-loading="isLoadingData"
    class="content-waiting-loader"
  />
  <section
    v-if="event"
    :key="`blog-event-view-${event.slug}`"
  >
    <div class="blog-event-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
          :content-id="`blog-event-${event.slug}`"
          :section-name="$t('message.event.events')"
          :title="event.title"
          :bg-image-url="event.featuredImage"
        />
      </div>
      <div class="blog-event-view__body content-view__body">
        <sw-content-page-section>
          <template #header>
            {{ $t('message.details') }}
          </template>
          <template #main>
            <div class="row no-gutters d-flex flex-column flex-xs-row flex-nowrap">
              <div class="col-auto col-lg-3 mr-3 mr-lg-0">
                <div class="date-block date-block_b">
                  {{ event.getLocaleIntervalDates(languageCode) }}
                </div>
              </div>
              <div class="col flex-grow-1 mt-3 mt-xs-0">
                <div class="blog-event-view__location-organization">
                  <div class="blog-event-view__location">
                    {{ event.location }}
                  </div>
                  <div class="blog-event-view__organization">
                    {{ event.organization }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters blog-event-view__additional-info">
              <div class="col-4 col-sm-3 col-lg-3 blog-event-view__additional-info-title">
                {{ $t('message.event.organizer') }}
              </div>
              <div class="col-8 col-sm-9 col-lg-9 blog-event-view__additional-info-text">
                {{ event.organizer }}
              </div>
            </div>
            <div class="row no-gutters blog-event-view__additional-info">
              <div class="col-4 col-sm-3 col-lg-3 blog-event-view__additional-info-title">
                {{ $t('message.event.conducting_format') }}
              </div>
              <div class="col-8 col-sm-9 col-lg-9 blog-event-view__additional-info-text">
                {{ event.conductingFormat }}
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col">
                <div
                  class="blog-event-view__description"
                  v-html="event.description"
                />
              </div>
            </div>
          </template>
        </sw-content-page-section>
        <sw-content-page-section
          v-if="event.hasGalleryAlbum()"
        >
          <template #header>
            {{ $t('message.gallery.gallery') }}
          </template>
          <template #main>
            <sw-gallery-album-inside-view
              :component-key="`blog-event-view-gallery-album-${event.slug}`"
              :gallery-album="event.getFirstGalleryAlbum()"
            />
          </template>
        </sw-content-page-section>
        <sw-content-page-section
          :margin-bottom="false"
        >
          <template #header>
            {{ $t('message.event.participants') }}
          </template>
          <template #main>
            <div
              class="blog-event-view__participants-text"
              v-html="event.participants"
            />
          </template>
        </sw-content-page-section>
      </div>
    </div>
  </section>
</template>

<script>
import ApiReadClient from '../../../classes/api/ApiReadClient'
import SwError from '../../../classes/error/Error'
import BlogEvent from '../../../classes/blog/event/BlogEvent'
import SwContentPageHeader from '../../../components/page/ContentPageHeader'
import SwGalleryAlbumInsideView from '../../../components/gallery/GalleryAlbumInsideView'
import LoadingData from '../../../mixins/LoadingData'
import SwContentPageSection from '../../../components/page/ContentPageSection'

export default {
  name: 'SwBlogEventView',
  components: { SwContentPageSection, SwGalleryAlbumInsideView, SwContentPageHeader },
  mixins: [LoadingData],

  data () {
    return {
      eventIdentifier: null,
      /** @type BlogEvent */
      event: null
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  },

  created () {
    this.startDataLoading()
    this.eventIdentifier = this.$route.params.eventIdentifier ?? null
    this.getEvent()
  },

  methods: {
    async getEvent () {
      const params = new URLSearchParams()
      params.append('include', 'tags,albums')
      const apiReadClient =
        new ApiReadClient(`/api/blog/events/${this.eventIdentifier}`, { params }, 'BlogEvent')
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({ name: 'pageNotFound' })
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), { root: true })
          }
        }
      } else {
        this.event = apiReadClient.getFirst()
      }
      this.dataLoaded()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.blog-event-view {
  .blog-event-view__body {
    .blog-event-view__location-organization,
    .blog-event-view__additional-info {
      font-size: 1rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 1rem);
      }
    }

    .blog-event-view__location-organization {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      font-weight: bold;

      .blog-event-view__location {
        display: flex;
        flex-direction: row;
        padding-right: 12px;
        color: var(--blog-event-location);

        @media screen and (max-width: 992px) {
          margin: 0;
        }

        &::before {
          display: inline-block;
          content: '';
          width: 14px;
          min-width: 14px;
          height: 18px;
          background: transparent url("/static/img/icon/location-dark.svg") no-repeat center 3px;
        }
      }

      .blog-event-view__organization {
        display: flex;
        flex-direction: row;
        color: var(--blog-event-organizer);
      }
    }

    .blog-event-view__additional-info {
      margin-top: 23px;
      color: var(--text-main);

      @media screen and (max-width: 1280px) {
        margin-top: 1.8vw;
      }

      .blog-event-view__additional-info-text {
        opacity: 0.6;
      }
    }

    .blog-event-view__description {
      margin-top: 27px;
      font-size: 1.25rem;
      color: var(--content-main-text);

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.875rem, 1.25rem);
      }
    }

    .blog-event-view__participants {
      margin-top: 103px;
      font-size: 1.25rem;
      color: var(--content-main-text);

      @media screen and (max-width: 1280px) {
        margin-top: 8vw;
        @include fluid-font-size(48rem, 80rem, 0.875rem, 1.25rem);
      }

      @media screen and (max-width: 768px) {
        margin-top: 33px;
      }
    }
  }
}
</style>
