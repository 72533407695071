/**
 * Статус загрузки данных
 * @type {{data(): {loadingView: boolean}, methods: {viewLoaded(): void}}}
 */
const LoadingData = {
  data () {
    return {
      /** @type Boolean Статус загрузки данных */
      isLoadingData: null
    }
  },

  methods: {
    /**
     * Установить статус начала загрузки
     */
    startDataLoading () {
      this.isLoadingData = true
    },

    /**
     * Установить статус окончания загрузки
     */
    dataLoaded () {
      this.isLoadingData = false
    }
  }
}
export default LoadingData
