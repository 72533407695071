<template>
  <div class="assets-page-header">
    <sw-go-to-frontpage
      :content-id="contentId"
      :title="title"
      :breadcrumbs="breadcrumbs"
    />
    <div
      v-if="showTitle"
      class="row no-gutters justify-content-start assets-page-header__title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
import SwGoToFrontpage from './control/GoToFrontpage'
export default {
  name: 'SwAssetsPageHeader',
  components: { SwGoToFrontpage },
  props: {
    contentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    menu: {
      type: Array,
      default: () => {
        return []
      }
    },
    breadcrumbs: {
      type: Array,
      required: false,
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.assets-page-header {
  display: flex;
  flex-direction: column;
  max-width: 1296px;
  width: 100%;
  height: auto;
  padding: 0 33px 0 33px;
  margin-bottom: 40px;
  color: var(--assets-page-header);

  @media screen and (max-width: 1296px) {
    padding: 3vw 2.58vw 0 2.58vw;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 2.58vw 0 2.58vw;
  }

  @media screen and (max-width: 568px) {
    margin-bottom: 0;
  }

  .assets-page-header__title {
    display: block;
    margin-top: 64px;
    padding-bottom: 49px;
    border-bottom: 0.5px solid var(--assets-page-header-border);
    font-size: 3.5rem;
    letter-spacing: -0.03em;
    font-feature-settings: 'kern' off;

    @media screen and (max-width: 1280px) {
      margin-top: 5vw;
      padding-bottom: 3.88vw;
      @include fluid-font-size(48rem, 80rem, 1.75rem, 3.5rem);
    }

    @media screen and (max-width: 768px) {
      margin-top: 8px;
      padding-bottom: 40px;
      font-weight: bold;
      text-align: center;
    }

    @media screen and (max-width: 568px) {
      border: none;
    }
  }

  .breadcrumbs__delimiter {
    background-image: url("/static/img/icon/right-bracket-dark.png");
  }
}
</style>
