/**
 * Правообладатель
 *
 * @module Education
 * @class EducationCourseRightHolder
 */
class EducationCourseRightHolder {
  constructor (attributes) {
    this.id = attributes.id
    this.name = attributes.name
    this.slug = attributes.slug
    this.description = attributes.description
  }

  /**
   * Идентификатор правообладателя
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Название правообладателя
   * @return {String}
   */
  get name () {
    return this._name
  }

  set name (value) {
    this._name = value
  }

  /**
   * Текстовый правообладателя
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Описание правообладателя
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }
}

export default EducationCourseRightHolder
