<template>
  <section
    :id="category.slug"
    class="row w-100 justify-content-center"
  >
    <div
      v-show="isLoadingData"
      v-loading="isLoadingData"
      class="content-waiting-loader"
    />
    <div
      v-if="isCoursesLoaded"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ category.title }}
        </template>
        <template #description>
          {{ category.description }}
        </template>
      </sw-front-page-block-header>
      <div
        v-if="courseCardList"
        class="row no-gutters row-cols-1 row-cols-lg-2 sw-card-list sw-card-list_col-1_col-lg-2"
      >
        <div
          v-for="courseCard in courseCardList"
          :key="`education-card-col-${courseCard.slug}`"
          class="col sw-card-list__col"
        >
          <sw-course-card
            :key="`education-card-${courseCard.slug}`"
            :course-card="courseCard"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setCourseCardList()"
      />
    </div>
  </section>
</template>

<script>
import SwCourseCard from './CourseCard'
import EducationCategory from '../../classes/education/EducationCategory'
import EducationCourseCard from '../../classes/education/EducationCourseCard'
import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader'
import Pagination from '../../classes/pagination/Pagination'
import SwShowMoreBtn from '../page/control/ShowMoreBtn'
import LoadingData from '../../mixins/LoadingData'
export default {
  name: 'SwCourseCardList',
  components: { SwShowMoreBtn, SwFrontPageBlockHeader, SwCourseCard },
  mixins: [LoadingData],

  props: {
    /**
     * Количество выводимых курсов в одной странице
     */
    pageSize: {
      type: Number,
      default: 20
    },
    /**
     * Категория для которой будут выведены курсы
     */
    category: {
      type: EducationCategory,
      required: true
    }
  },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    },

    isCoursesLoaded () {
      return this.$store.getters['courseCards/isLoaded']
    },

    /** @returns EducationCourseCard[] */
    courseCardList () {
      return this.$store.getters['courseCards/getCourseCardsByCategorySlug'](this.category.slug)
    },

    /** @returns Pagination|null */
    pagination () {
      return this.$store.getters['courseCards/getPaginationByCategorySlug'](this.category.slug)
    },

    /**
     * Еще доступны курсы для вывода?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Текущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    isCoursesLoaded () {
      if (this.isCoursesLoaded) {
        this.dataLoaded()
      }
    }
  },

  mounted () {
    if (this.courseCardList.length === 0) {
      this.startDataLoading()
      this.setCourseCardList()
    }
  },

  methods: {
    setCourseCardList () {
      this.$store.dispatch('courseCards/findCourseCards', this.courseCardListSearchParams())
    },

    courseCardListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      params.append('include', 'categories')
      params.append('filter[category_slug]', this.category.slug)
      return params
    }
  }
}
</script>
