<template>
  <div class="team-member-list">
    <div class="row no-gutters align-items-start row-cols-1 row-cols-xs-2">
      <div
        v-for="member in memberList"
        :key="'team-member-card-' + member.id"
        class="col"
      >
        <sw-team-member
          :member="member"
        />
      </div>
    </div>
    <sw-show-more-btn
      v-if="hasMoreData"
      :num="countMoreData"
      @show:more="$emit('show:more')"
    />
  </div>
</template>
<script>
import TeamMember from '../../classes/team/TeamMember'
import SwShowMoreBtn from '../page/control/ShowMoreBtn'
import SwTeamMember from './TeamMember'

export default {
  name: 'SwTeamMemberList',
  components: { SwTeamMember, SwShowMoreBtn },
  props: {
    /** @type TeamMember[] */
    memberList: {
      type: Array,
      required: true
    },
    hasMoreData: {
      type: Boolean,
      required: true
    },
    countMoreData: {
      type: Number,
      required: true
    }
  },
  emits: ['show:more']
}
</script>

<style lang="scss" scoped>
.team-member-list {
  margin-bottom: -70px;
  padding-bottom: 80px;

  @media screen and (max-width: 1280px) {
    margin-bottom: -5.47vw;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: -35px;
  }

  @media screen and (max-width: 568px) {
    margin-bottom: -27px;
    padding-bottom: 40px;
  }
}
</style>
