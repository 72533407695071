<template>
  <section class="docs component-view">
    <div class="row w-100 justify-content-center no-gutters component-view__header">
      <sw-assets-page-header
        content-id="docs"
        :title="$t('message.docs')"
      />
    </div>
    <div class="content-view component-view__content">
      <div class="content-view__body">
        <div class="docs">
          <a
            class="docs__link"
            href="/static/docs/career_guidance.pdf"
            target="_blank"
          >Методические рекомендации для организации профориентационной работы</a>
          <div class="docs__delimiter" />
          <a
            class="docs__link"
            href="/static/docs/social_adaptation.pdf"
            target="_blank"
          >Методические рекомендации для организации работы по социальной адаптации</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../components/page/AssetsPageHeader'

export default {
  name: 'DocsView',
  components: { SwAssetsPageHeader }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.docs {
  .docs__delimiter {
    margin-top: 20px;
  }

  .docs__link {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-main);
  }
}
</style>
