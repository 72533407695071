<template>
  <section
    :key="`education-course-content-${course.slug}`"
    @scroll="handleScroll()"
  >
    <div class="education-course-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
          :content-id="`education-course-${course.slug}`"
          :section-name="$t('message.education.course.courses')"
          :title="course.title"
          :bg-image-url="course.featuredImage"
          :breadcrumbs="breadcrumbs"
        />
      </div>
      <div class="education-course-view__body content-view__body">
        <sw-sticky-top-menu
          :menu-items="getStickyTopMenu()"
        />
        <sw-content-page-section id="info">
          <template #header>
            {{ $t('message.info') }}
          </template>
          <template #main>
            <div
              v-for="(infoField,index) in getInfoFields()"
              :key="`course-info-field-${index}`"
              class="education-course-view__info-field"
            >
              <div class="education-course-view__info-field-title">
                {{ infoField.title }}
              </div>
              <div
                class="education-course-view__info-field-value"
                v-html="infoField.value"
              />
            </div>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="sign_up">
          <template #main>
            <div
              class="sw-btn sw-btn_b sw-btn_primary"
              @click="signUpToCourse()"
            >
              {{ $t('message.education.course.sign_up') }}
            </div>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="content">
          <template #header>
            {{ $t('message.education.course.content') }}
          </template>
          <template #main>
            <div v-html="course.content" />
          </template>
        </sw-content-page-section>

        <sw-content-page-section
          v-if="course.curriculum"
          id="curriculum"
        >
          <template #header>
            {{ $t('message.education.course.curriculum') }}
          </template>
          <template #main>
            <div v-html="course.curriculum" />
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="authors">
          <template #header>
            {{ $t('message.education.course.authors') }}
          </template>
          <template #main>
            <sw-team-member-list
              :member-list="course.authors"
              :count-more-data="0"
              :has-more-data="false"
            />
          </template>
        </sw-content-page-section>

        <sw-content-page-section
          v-if="course.formedCompetencies"
          id="formed_competencies"
        >
          <template #header>
            {{ $t('message.education.course.formed_competencies') }}
          </template>
          <template #main>
            <div v-html="course.formedCompetencies" />
          </template>
        </sw-content-page-section>

        <sw-content-page-section
          v-if="course.learningOutcomes"
          id="learning_outcomes"
        >
          <template #header>
            {{ $t('message.education.course.learning_outcomes') }}
          </template>
          <template #main>
            <div v-html="course.learningOutcomes" />
          </template>
        </sw-content-page-section>

        <sw-content-page-section
          v-if="course.trainingDirections"
          id="training_directions"
          :margin-bottom="false"
        >
          <template #header>
            {{ $t('message.education.course.training_directions') }}
          </template>
          <template #main>
            <div v-html="course.trainingDirections" />
          </template>
        </sw-content-page-section>
      </div>
    </div>
  </section>
</template>

<script>
import EducationCourse from '../../../classes/education/EducationCourse';
import SwContentPageSection from '../../page/ContentPageSection';
import SwTeamMemberList from '../../team/TeamMemberList';
import SwStickyTopMenu from '../../page/control/StickyTopMenu';
import SwContentPageHeader from '../../page/ContentPageHeader';
import LocaleForValue from '../../../mixins/LocaleForValue';

export default {
  name: 'SwEducationCourseContent',
  components: {SwContentPageHeader, SwStickyTopMenu, SwTeamMemberList, SwContentPageSection  },
  mixins: [LocaleForValue],

  props: {
    course: {
      type: EducationCourse,
      required: true
    },
    courseUrl: {
      type: String,
      required: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  },

  methods: {
    signUpToCourse () {
      window.location.href = this.courseUrl ?? this.course.url
    },

    getInfoFields () {
      const infoFields = []
      if (this.course) {
        if (this.course.startDate) {
          infoFields.push({
            title: this.$t('message.education.course.start_date'),
            value: this.course.getLocaleStartDate(this.languageCode)
          })
        }
        if (this.course.endDate) {
          infoFields.push({
            title: this.$t('message.education.course.end_date'),
            value: this.course.getLocaleEndDate(this.languageCode)
          })
        }
        infoFields.push({
          title: this.$t('message.education.course.duration'),
          value: this.getLocaleForValue(this.course.duration, 'message.education.course.duration')
        })
        infoFields.push({
          title: this.$t('message.education.course.laboriousness'),
          value: this.getLocaleForValue(this.course.laboriousness, 'message.education.course.laboriousness')
        })
        infoFields.push({
          title: this.$t('message.education.course.labor_costs'),
          value: this.getLocaleForValue(this.course.laborCosts, 'message.education.course.labor_costs')
        })
        if (this.course.rightHolder) {
          const rightHolder = this.course.rightHolder
          infoFields.push({
            title: this.$t('message.education.course.right_holder'),
            value: rightHolder.name
          })
        }
        if (this.course.entranceRequirements) {
          infoFields.push({
            title: this.$t('message.education.course.entrance_requirements'),
            value: this.course.entranceRequirements
          })
        }
        if (this.course.hasCertificate) {
          infoFields.push({
            title: this.$t('message.education.course.has_certificate'),
            value: this.$t('message.there_is')
          })
        }
      }
      return infoFields
    },

    getStickyTopMenu () {
      const menu = []
      menu.push({ name: this.$t('message.info'), hash: 'info' })
      menu.push({ name: this.$t('message.education.course.content'), hash: 'content' })
      if (this.course.curriculum) {
        menu.push({ name: this.$t('message.education.course.curriculum'), hash: 'curriculum' })
      }
      menu.push({ name: this.$t('message.education.course.authors'), hash: 'authors' })
      if (this.course.formedCompetencies) {
        menu.push({ name: this.$t('message.education.course.formed_competencies'), hash: 'formed_competencies' })
      }
      if (this.course.learningOutcomes) {
        menu.push({ name: this.$t('message.education.course.learning_outcomes'), hash: 'learning_outcomes' })
      }
      if (this.course.trainingDirections) {
        menu.push({ name: this.$t('message.education.course.training_directions'), hash: 'training_directions' })
      }
      return menu
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.education-course-view {
  .education-course-view__body {
    .education-course-view__info-field {
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
      margin-bottom: 22px;
      border-bottom: 0.5px solid var(--light-border);
      font-size: 1.13rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1rem, 1.13rem);
      }

      @media screen and (max-width: 568px) {
        flex-direction: column;
      }

      .education-course-view__info-field-title {
       min-width: 350px;
        max-width: 350px;
        padding-right: 10px;
        color: var(--text-main);
        opacity: 0.6;

        @media screen and (max-width: 992px) {
          min-width: 35.29vw;
          max-width: 35.29vw;
        }

        @media screen and (max-width: 568px) {
          min-width: 100%;
          max-width: 100%;
        }
      }

      .education-course-view__info-field-value {
        font-weight: bold;
        color: var(--course-view-info-field-value);
      }
    }
  }
}
</style>
