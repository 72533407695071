/**
 * Partner
 *
 * @module Partner
 * @class Partner
 */
class Partner {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.featuredImage = attributes.featured_image
    this.url = attributes.url
  }

  /**
   * Идентификатор партнера
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Название партнера
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Ссылка на партнера
   * @return {String}
   */
  get url () {
    return this._url
  }

  set url (value) {
    this._url = value
  }

  /**
   * Заглавное изображение партнера
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }

  /**
   * Связанные объекты
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
  }

  /**
   * Url на уменьшенное изображение
   * @return {string}
   */
  getThumbnailImageUrl () {
    const url = new URL(this.featuredImage)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }
}
export default Partner
