<template>
  <div
    class="layout-default"
    data-theme="default"
  >
    <page-header />
    <sw-error />
    <router-view />
    <page-footer />
    <sw-page-back-top />
  </div>
</template>

<script>
import PageHeader from '../components/page/PageHeader'
import PageFooter from '../components/page/PageFooter'
import SwError from '../components/page/Error'
import SwPageBackTop from '../components/page/PageBackTop'

export default {
  name: 'LayoutDefault',
  components: { SwPageBackTop, SwError, PageFooter, PageHeader },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    }
  },

  created () {
    this.$i18n.locale = this.language
  }
}
</script>

<style lang="scss" scoped>
.layout-default {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
