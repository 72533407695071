<template>
  <section id="contacts" class="component-view">
    <div class="row w-100 justify-content-center no-gutters component-view__header">
      <sw-assets-page-header
        content-id="team-structure-list"
        :title="$t('message.contacts')"
      />
    </div>
    <div class="content-view component-view__content">
      <div class="content-view__body">
        <div class="row flex-column flex-xxs-row contact-person">
          <!--          <div class="col col-xxs-4 col-xs-auto d-flex justify-content-center justify-content-xxs-start">
            <img
              class="contact-person__photo"
              src="/static/img/contacts/Vorobeva-Ljudmila.jpg"
            >
          </div>
          <div class="col col-xxs-8 col-xs-auto col-sm-4 col-xl-auto mt-4 mt-xxs-0">
            <div class="contact-person__name">
              Воробьева Людмила Ивановна
            </div>
            <div class="contact-person__role">
              Менеджер проекта
            </div>
          </div>-->
          <div class="col col-sm-auto flex-fill mt-4 mt-sm-0">
            <div class="contact-person__contacts-list">
              <div class="contact-person__contact-row">
                <div class="contact-person__contact">
                  +7 (391) 206 22 10
                </div>
                <div class="contact-person__contact-type">
                  {{ $t('message.phone') }}
                </div>
              </div>
              <div class="contact-person__contact-row">
                <div class="contact-person__contact">
                  <a href="mailto:info@snoword.ru">info@snoword.ru</a>
                </div>
                <div class="contact-person__contact-type">
                  {{ $t('message.email') }}
                </div>
              </div>
              <div class="contact-person__contact-row">
                <div class="contact-person__contact">
                  <a
                    href="https://vk.com/snoword"
                    target="_blank"
                  >vk.com/snoword</a>
                </div>
                <div class="contact-person__contact-type">
                  {{ $t('message.vkontakte') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../components/page/AssetsPageHeader'
export default {
  name: 'ContactsView',
  components: { SwAssetsPageHeader }
}
</script>

<style lang="scss" scoped>
.contact-person {
  .contact-person__photo {
    display: block;
    width: 100%;
    max-width: 208px;
    height: 256px;
    border-radius: 40px;

    @media screen and (max-width: 768px) {
      width: 160px;
      height: 192px;
      min-width: 160px;
      min-height: 192px;
      border-radius: 24px;
    }

    @media screen and (max-width: 568px) {
      width: 128px;
      height: 168px;
      min-width: 128px;
      min-height: 168px;
      border-radius: 15px;
    }
  }

  .contact-person__name {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-main);

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
      font-weight: normal;
    }

    @media screen and (max-width: 376px) {
      text-align: center;
    }
  }

  .contact-person__role {
    font-size: 1.25rem;
    color: var(--contacts-person-role);

    @media screen and (max-width: 768px) {
      font-size: 0.88rem;
    }

    @media screen and (max-width: 376px) {
      text-align: center;
    }
  }

  .contact-person__contacts-list {
    padding: 27px 32px;
    background-color: var(--contacts-person-contacts-bg);
    border-radius: 24px;

    .contact-person__contact-row:not(:last-child) {
      padding-bottom: 33px;
      margin-bottom: 29px;
      border-bottom: 0.5px solid var(--contacts-person-contacts-delimiter);
    }

    .contact-person__contact-row {
      .contact-person__contact {
        font-size: 1.25rem;
        font-weight: bold;
        color: var(--text-main);

        @media screen and (max-width: 768px) {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      .contact-person__contact-type {
        font-size: 1rem;
        color: var(--contacts-person-contact-type);
        opacity: 1;
      }
    }
  }
}

</style>
