<template>
  <div
    v-if="isLoadingData"
    v-loading="isLoadingData"
    class="content-waiting-loader"
  />
  <div
    v-if="structureList.length"
    class="team-structure-list content-view"
  >
    <div class="team-structure-list__body content-view__body">
      <sw-sticky-top-menu
        :menu-items="structureListMenu()"
      />
      <sw-team-structure
        v-for="structure in structureList"
        :key="'team-structure-' + structure.id"
        :structure="structure"
      />
    </div>
  </div>
</template>

<script>
import TeamStructure from '../../classes/team/TeamStructure'
import SwTeamStructure from './TeamStructure'
import SwAssetsPageHeader from '../page/AssetsPageHeader'
import SwTeamPageIndicators from '../indicators/TeamPageIndicators'
import LoadingData from '../../mixins/LoadingData'
import ApiReadClient from '../../classes/api/ApiReadClient'
import SwError from '../../classes/error/Error'
import SwStickyTopMenu from '../page/control/StickyTopMenu'

export default {
  name: 'SwTeamStructureList',
  components: { SwStickyTopMenu, SwTeamPageIndicators, SwAssetsPageHeader, SwTeamStructure },
  mixins: [LoadingData],

  data () {
    return {
      loaded: false,
      /** @type TeamStructure[] */
      structureList: []
    }
  },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    }
  },

  watch: {
    language () {
      this.startDataLoading()
      this.getStructureList()
    }
  },

  created () {
    this.startDataLoading()
    this.getStructureList()
  },

  methods: {
    /**
     * Список структур команды
     */
    async getStructureList () {
      const apiReadClient =
        new ApiReadClient('/api/team/structures/', {}, 'TeamStructure')
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        for (const error of apiReadClient.errors) {
          this.$store.dispatch('error/addError', new SwError(error), { root: true })
        }
      } else {
        this.structureList = apiReadClient.data
      }
      this.dataLoaded()
    },

    structureListMenu () {
      return this.structureList.map(function (stucture) {
        const item = {}
        item.hash = stucture.slug
        item.name = stucture.title
        return item
      })
    }
  }
}
</script>

<style lang="scss">
.team-structure-list {
  .team-structure-list__body {
    .team-structure:not:first-child {
      border-top: 0.5px solid var(--light-border);
    }
  }
}
</style>
