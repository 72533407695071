/**
 * Участник команды
 *
 * @module Team
 * @class TeamMember
 */
class TeamMember {
  constructor (attributes) {
    this.id = attributes.id
    this.firstName = attributes.first_name
    this.lastName = attributes.last_name
    this.middleName = attributes.middle_name
    this.about = attributes.about
    this.photoUrl = attributes.photo_url
    this.contactUrl = attributes.contact_url
  }

  /**
   * Идентификатор
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Имя
   * @return {String}
   */
  get firstName () {
    return this._firstName
  }

  set firstName (value) {
    this._firstName = value
  }

  /**
   * Фамилия
   * @return {String}
   */
  get lastName () {
    return this._lastName
  }

  set lastName (value) {
    this._lastName = value
  }

  /**
   * Отчество
   * @return {String}
   */
  get middleName () {
    return this._middleName
  }

  set middleName (value) {
    this._middleName = value
  }

  /**
   * Полное имя
   * @param {String} language
   * @return {String}
   */
  getFullName (language = 'ru') {
    if (language === 'en') {
      return this.firstName + ' ' + this.lastName
    }
    return this.lastName + ' ' + this.firstName + (this.middleName ? ` ${this.middleName}` : '')
  }

  /**
   * Об участнике
   * @return {String}
   */
  get about () {
    return this._about
  }

  set about (value) {
    this._about = value
  }

  /**
   * Ссылка на фото
   * @return {String}
   */
  get photoUrl () {
    return this._photoUrl
  }

  set photoUrl (value) {
    this._photoUrl = value
  }

  /**
   * Url на уменьшенное фото
   * @return {string}
   */
  getThumbnailPhotoUrl () {
    const url = new URL(this.photoUrl)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }

  /**
   * Ссылка на контактную информацию
   * @return {String}
   */
  get contactUrl () {
    return this._contactUrl
  }

  set contactUrl (value) {
    this._contactUrl = value
  }
}

export default TeamMember
