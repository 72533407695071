import SwError from '../../../classes/error/Error'

const initialState = () => ({
  /** @type {SwError[]} */
  errors: []
})

const state = initialState()

const getters = {
  /** @returns {SwError[]} */
  getErrors (state) {
    return state.errors
  },

  /** @returns {SwError[]} */
  getUnShownErrors (state) {
    return state.errors.filter(error => !error.is_shown)
  }
}

const mutations = {
  ADD_ERROR (state, error) {
    state.errors.push(error)
  },

  SET_ERROR_IS_SHOWN (state, id) {
    const foundedIndex = state.errors.findIndex(error => error.id === id)
    if (foundedIndex !== -1) {
      state.errors[foundedIndex].is_shown = true
    }
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  /**
   *
   * @param commit
   * @param state
   * @param {SwError} error
   */
  addError ({ commit, state }, error) {
    error.id = state.errors.length + 1
    commit('ADD_ERROR', error)
  },

  setErrorIsShown ({ commit }, id) {
    commit('SET_ERROR_IS_SHOWN', id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
