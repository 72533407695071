<template>
  <header id="header" class="header">
    <nav class="navbar navbar-expand-xl">
      <div class="row no-gutters w-100 justify-content-between align-content-center">
        <div
          class="col-auto col-md-8 col-xl-auto flex-grow-1 flex-md-grow-0 order-2 order-xl-0 d-flex justify-content-center justify-content-xl-left align-self-center"
        >
          <router-link
            class="navbar-brand p-0"
            :to="{name: 'frontpage'}"
          >
            <img src="/static/img/header/header-logo-v2.svg">
          </router-link>
        </div>
        <div class="col-auto col-md-1 col-xl-auto order-1 d-flex justify-content-left align-self-center">
          <el-dropdown class="header__language-menu">
            <span class="header__language-menu-current">
              {{ currentLocale }}<i class="el-icon-arrow-down el-icon&#45;&#45;right" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(locale, localeKey) in locales"
                  :key="`locale-${localeKey}`"
                  class="el-dropdown-item_sw-header"
                  @click="changeLanguage(localeKey)"
                >
                  {{ locale }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="col-auto col-md-1 col-xl-auto order-0 order-xl-2 d-flex align-self-center">
          <el-dropdown>
            <button
              class="el-dropdown-link navbar-toggler"
            >
              <span class="navbar-toggler-icon" />
            </button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in getCollapsedMenuItems()"
                  :key="`header-menu-item-${index}`"
                  class="el-dropdown-item_sw-header"
                  @click="$router.push({name:item.to.name, params: {contentId: item.to.contentId}})"
                >
                  {{ $t(item.name) }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div
            class="collapse navbar-collapse justify-content-center"
          >
            <ul class="navbar-nav align-items-center">
              <template
                v-for="(item, index) in getExpandedMenuItems()"
                :key="`header-menu-item-${index}`"
              >
                <li
                  class="nav-link"
                  :class="{ 'active' : isMenuItemActive(item.to.name) }"
                  @click="$router.push({name: item.to.name, params: {contentId: item.to.contentId}})"
                >
                  {{ $t(item.name) }}
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="col-xl-auto d-none d-xl-flex order-0 order-xl-3 align-self-center">
          <el-dropdown class="header__about-menu">
            <span class="header__about-menu-title">
              {{ $t('message.about') }}<i class="el-icon-arrow-down el-icon&#45;&#45;right" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in getAboutMenuItems()"
                  :key="`about-menu-item-${index}`"
                  class="el-dropdown-item_sw-header"
                >
                  <li
                    class="nav-link"
                    :class="{ 'active' : isMenuItemActive(item.to.name) }"
                    @click="$router.push({name: item.to.name, params: {contentId: item.to.contentId}})"
                  >
                    {{ $t(item.name) }}
                  </li>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="col-auto col-md-2 col-xl-auto order-4 d-flex justify-content-end align-self-center">
          <a href="https://edu.snoword.ru/">
            <div class="header__login-btn">
              <div class="header__login-btn-text">
                {{ $t('message.login') }}
              </div>
              <div class="header__login-btn-icon" />
            </div>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

export default {
  name: 'SwPageHeader',

  data () {
    return {
      locales: { ru: 'Ru', en: 'En' },
      aboutMenuItems: [
        {
          to: { name: 'about' },
          name: 'message.about',
          visibleInExpanded: false,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'team' },
          name: 'message.team.team',
          visibleInExpanded: false,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'contacts' },
          name: 'message.contacts',
          visibleInExpanded: false,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'docs' },
          name: 'message.docs',
          visibleInExpanded: false,
          availableInLocales: ['Ru']
        }
      ],
      mainMenuItems: [
        {
          to: { name: 'frontpage', contentId: 'events' },
          name: 'message.event.events',
          visibleInExpanded: true,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'frontpage', contentId: 'posts' },
          name: 'message.post.posts',
          visibleInExpanded: true,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'frontpage', contentId: 'tribunes' },
          name: 'message.tribune.tribunes',
          visibleInExpanded: true,
          availableInLocales: ['Ru', 'En']
        },
        {
          to: { name: 'frontpage', contentId: 'gallery' },
          name: 'message.gallery.gallery',
          visibleInExpanded: true,
          availableInLocales: ['Ru', 'En']
        }
      ]
    }
  },

  mounted () {
    (function(){(function c(d,w,m,i) {
      window.supportAPIMethod = m;
      var s = d.createElement('script');
      s.id = 'supportScript';
      var id = 'e8ed1e184046527383445f7bfe9a212b';
      s.src = (!i ? 'https://lcab.talk-me.ru/support/support.js' : 'https://static.site-chat.me/support/support.int.js') + '?h=' + id;
      s.onerror = i ? undefined : function(){c(d,w,m,true)};
      w[m] = w[m] ? w[m] : function(){(w[m].q = w[m].q ? w[m].q : []).push(arguments);};
      (d.head ? d.head : d.body).appendChild(s);
    })(document,window,'TalkMe')})();
  },

  computed: {
    /**
     * Текущий язык сайта
     * @returns {String}
     */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    currentRouteName () {
      return this.$route.name
    },

    currentLocale () {
      return this.locales[this.language]
    },

    menuItems () {
      return this.mainMenuItems.concat(this.aboutMenuItems)
    }
  },

  methods: {
    /**
     * Get expanded menu items
     */
    getExpandedMenuItems () {
      return this.menuItems.filter(item => {
        return item.visibleInExpanded && item.availableInLocales.includes(this.currentLocale)
      })
    },

    /**
     * Get about menu items
     */
    getAboutMenuItems () {
      return this.aboutMenuItems.filter(item => {
        return item.availableInLocales.includes(this.currentLocale)
      })
    },

    /**
     * Get collapsed menu items
     */
    getCollapsedMenuItems () {
      return this.menuItems.filter(item => {
        return item.availableInLocales.includes(this.currentLocale)
      })
    },

    /**
     * @param {String} routeName
     */
    isMenuItemActive (routeName) {
      return this.currentRouteName !== 'frontpage' && this.currentRouteName === routeName
    },

    /**
     * Изменить язык
     * @param {string} locale
     */
    changeLanguage (locale) {
      if (locale !== this.language) {
        this.$i18n.locale = locale
        this.$store.dispatch('reset')
        this.$store.dispatch('language/setLanguage', locale)
        if (!this.$route.meta.stayOnLanguageChange) {
          this.$router.push({ name: 'frontpage' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-menu {
  min-width: 300px;

  .el-dropdown-item_sw-header {
    padding: 12px;
    font-size: 1.25rem;
    text-align: center;
  }
}

.header {
  width: 100%;
  min-width: 320px;
  height: 110px;
  min-height: 110px;
  display: flex;
  justify-content: center;

  .navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
    height: 80px;
    padding: 0 41px;
    border-bottom: 0.5px solid var(--header-navbar-btm-border);
    // background-color: var(--header-bg);

    @media screen and (max-width: 1280px) {
      padding: 0 3.2vw;
    }

    @media screen and (max-width: 768px) {
      height: 72px;
      padding: 0 16px;
    }

    .row {
      max-width: 1232px;

      .navbar-brand {
        margin: 0;

        img {
          width: 165px;
          height: 64px;

          @media screen and (max-width: 768px) {
            width: 121px;
            height: 48px;
          }
        }
      }

      .header__about-menu {
        width: 40px;
        display: flex;
        align-content: center;

        @media screen and (max-width: 768px) {
          margin-left: 10px;
        }

        .header__about-menu-title {
          font-size: 1.25rem;
          color: var(--header-language-current);

          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 768px) {
            margin-left: 10px;
          }
        }
      }

      .header__language-menu {
        width: 40px;
        display: flex;
        align-content: center;

        @media screen and (max-width: 768px) {
          margin-left: 10px;
        }

        .header__language-menu-current {
          font-size: 1.25rem;
          color: var(--header-language-current);

          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 768px) {
            margin-left: 10px;
          }
        }
      }

      .header__dropdown-menu {
        display: none;

        &.header__dropdown-menu_show {
          display: block;
        }

        .header__dropdown-menu-list {
          position: fixed;
          padding: 20px;
          background-color: #fff;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 16px;
          z-index: 1000;

          .header__dropdown-menu-item {
            list-style: none;
            font-size: 1.5rem;
          }

          .header__dropdown-menu-item:not(:last-child) {
            padding: 5px 0;
          }
        }
      }

      .navbar-toggler {
        width: 55px;
        height: 48px;
        border: 0.5px solid var(--header-navbar-btn-border);
        border-radius: 16px;

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
      }

      .nav-link {
        padding: 0 15px;
        text-align: center;
        color: var(--header-navbar-btn);
        font-size: 20px;
        line-height: 80px;
        cursor: pointer;
        border-bottom: 0.5px solid transparent;

        @media screen and (max-width: 1280px) {
          min-width: 8.37vw;
        }

        &:hover,
        &.active {
          color: var(--header-navbar-btn-hover);
          border-bottom-color: var(--header-navbar-btn-border-hover);
        }
      }

      .nav-delimiter {
        width: 8px;
        height: 8px;
        margin: 0 32px;
        border: 2px solid var(--header-delimiter);
        border-radius: 8px;

        @media screen and (max-width: 1280px) {
          margin: 0 2.5vw;
        }
      }

      .header__login-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 48px;
        color: var(--header-login-btn);
        background-color: var(--header-login-btn-bg);
        border-radius: 16px;

        @media screen and (max-width: 569px) {
          width: 55px;
          height: 48px;
        }

        &:hover {
          background-color: var(--header-login-btn-bg-hover);
        }

        .header__login-btn-text {
          display: flex;
          margin-right: 11px;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.5rem;
          color: var(--header-login-btn);

          @media screen and (max-width: 569px) {
            display: none;
          }
        }

        .header__login-btn-icon {
          display: block;
          content: '';
          width: 24px;
          height: 24px;
          background: transparent url('/static/img/icon/login-light.svg') no-repeat center;
        }
      }
    }
  }
}
</style>
