<template>
  <div
    :id="`partner-${partner.id}`"
    class="sw-card sw-card_partner"
  >
    <a
      :href="partner.url"
      target="_blank"
    >
      <div class="partner-card__body">
        <el-image
          :key="`partner-featured-image-${partner.id}`"
          :src="partner.featuredImage"
          class="partner-card__featured-image"
          lazy
        />
        <div class="sw-card__title-text sw-card__title-text_bold sw-card__title-text_dark sw-card__title-text_h-100 sw-card__title-text_text-center">
          {{ partner.title }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>

import Partner from '../../classes/partner/Partner'

export default {
  name: 'SwPartner',

  props: {
    partner: {
      type: Partner,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.sw-card {
  &.sw-card_partner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 608px;

    @media screen and (min-width: 992px) and (max-width: 1280px) {
      width: 98%;
    }

    .sw-card__title-text {
      opacity: 0.5;
    }

    &:hover {
      .sw-card__title-text {
        opacity: 1;
      }
    }

    .partner-card__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      .partner-card__featured-image {
        max-width: 176px;
        margin-bottom: 12px;
        border-radius: 8px;
      }
    }
  }
}
</style>
