<template>
  <div
    v-if="isLoadingData"
    v-loading="isLoadingData"
    class="content-waiting-loader"
  />
  <section
    v-if="post"
    :key="`blog-post-view-${post.slug}`"
    v-loading="isLoadingData"
  >
    <div class="blog-post-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
          :content-id="`blog-post-${post.slug}`"
          :section-name="$t('message.post.posts')"
          :title="post.title"
          :bg-image-url="post.featuredImage"
        />
      </div>
      <div class="blog-post-view__body content-view__body">
        <sw-content-page-section>
          <template #header>
            {{ $t('message.details') }}
          </template>
          <template #main>
            <div class="row no-gutters">
              <div class="col">
                <div class="date-block date-block_b">
                  {{ post.getLocalePublishedAtDate(languageCode) }}
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col">
                <div
                  class="blog-post-view__content"
                  v-html="post.content"
                />
              </div>
            </div>
          </template>
        </sw-content-page-section>
      </div>
    </div>
  </section>
</template>

<script>
import ApiReadClient from '../../../classes/api/ApiReadClient'
import SwError from '../../../classes/error/Error'
import SwContentPageHeader from '../../../components/page/ContentPageHeader'
import LoadingData from '../../../mixins/LoadingData'
import SwContentPageSection from '../../../components/page/ContentPageSection'

export default {
  name: 'SwBlogPostView',
  components: { SwContentPageSection, SwContentPageHeader },
  mixins: [LoadingData],

  data () {
    return {
      postIdentifier: null,
      /** @type BlogPost */
      post: null
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  },

  created () {
    this.postIdentifier = this.$route.params.postIdentifier ?? null
    this.getPost()
  },

  methods: {
    async getPost () {
      const apiReadClient =
        new ApiReadClient(`/api/blog/posts/${this.postIdentifier}`, {}, 'BlogPost')
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({ name: 'pageNotFound' })
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), { root: true })
          }
        }
      } else {
        this.post = apiReadClient.getFirst()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.blog-post-view {
  .blog-post-view__body {
    color: var(--text-main);

    .date-block {
      margin-bottom: 23px;
    }

    .blog-post-view__content {
      font-size: 1.13rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 1.13rem);
      }
    }
  }
}
</style>
