<template>
  <section
      id="frontpage_atlas"
      class="row w-100 justify-content-center"
  >
    <div class="atlas">
      <div class="frontpage-block">
        <sw-front-page-block-header>
          <template #title>
            {{ $t('message.atlas.atlas') }}
          </template>
        </sw-front-page-block-header>

        <div class="atlas__content">
          <div class="hint">
            <div class="hint__text">
              Научно-просветительский проект
            </div>
          </div>

          <div class="d-flex flex-column flex-xl-row mt-4 ml-4 mr-4 align-items-start align-items-xl-end">
            <div class="col-xl-6">
              <div class="atlas__description">
                <div>
                  Интерактивный атлас коренных малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока: языки и&nbsp;культуры.
                </div>
                <div class="mt-4 font-weight-bold">
                  Атлас&nbsp;&mdash; информационно-образовательный ресурс, содержащий систематизированные научные данные
                  об&nbsp;историко-культурном, социально-антропологическом и&nbsp;лингвистическом многообразии коренных
                  малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока.
                </div>
                <div class="mt-3">
                  Атлас рассказывает о&nbsp;культуре, языке, традициях и&nbsp;выдающихся представителях 30&nbsp;народов.
                  Кроме того, на&nbsp;сайте атласа размещены картографические данные, на&nbsp;которых можно изучать
                  разнообразие этнологических, культурных, экономических процессов.
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row w-100">
            <div class="col-6 d-flex justify-content-center">
              <div
                  class="sw-btn sw-btn_b sw-btn_primary mt-4"
                  @click="$router.push({name: 'atlasView' })"
              >
                {{ $t('message.more_details') }}
              </div>
            </div>
            <div
                class="col-6 d-flex justify-content-center">
              <a
                  href="https://atlaskmns.ru/"
                  target="_blank"
                  class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_blue sw-btn_arrow-right_up mt-4"
              >
                atlaskmns.ru
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader';

export default {
  name: 'SwFrontPageAtlas',
  components: {SwFrontPageBlockHeader}
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.atlas {
  .atlas__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1232px;
    height: max-content;
    padding: 30px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/static/img/frontpage/atlas/atlas-block-bg.png");
    color: var(--text-main);
    border-radius: 30px;

    .hint {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      background-image: url("/static/img/platform/atlas-logo.png");
      background-repeat: no-repeat;
      background-size: 230px;
      background-position: 40px center;

      .hint__text {
        padding: 14px 30px;
        font-size: 1.625rem;
        text-align: center;
        background: rgba(255, 255, 255, 0.80);
        border-radius: 36px;

        @media screen and (max-width: 1280px) {
          @include fluid-font-size(48rem, 80rem, 1.25rem, 1.625rem);
        }

        @media screen and (min-width: 769px) {
          font-weight: bold;
        }
      }
    }

    .atlas__description {
      width: 100%;
      padding: 43px 52px;
      font-size: 1.25rem;
      background: rgba(255, 255, 255, 0.80);
      border-radius: 17px;

      @media screen and (max-width: 769px) {
        padding: 20px 25px;
      }
    }
  }
}
</style>
