import Tag from '../../tag/Tag'
/**
 * Карточка трибуны
 *
 * @module Blog
 * @class BlogTribuneCard
 */
class BlogTribuneCard {
  dateTimeFormatOptions () {
    return { year: 'numeric', month: 'long', day: 'numeric' }
  };

  /**
   * @param {Object} attributes
   */
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.excerpt = attributes.excerpt
    this.featuredImage = attributes.featured_image
    this.interviewDate = attributes.interview_date
    this.authorPhoto = attributes.author_photo
    this.authorFullName = attributes.author_full_name
    this.authorAbout = attributes.author_about
  }

  /**
   * Идентификатор события
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок события
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор события
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Короткое описание события
   * @return {String}
   */
  get excerpt () {
    return this._excerpt
  }

  set excerpt (value) {
    this._excerpt = value
  }

  /**
   * Заглавное изображение события
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }

  /**
   * Дата интервью
   * @return {Date}
   */
  get interviewDate () {
    return this._interviewDate
  }

  set interviewDate (value) {
    this._interviewDate = new Date(value)
  }

  /**
   * Фото автора трибуны
   * @return {Date}
   */
  get authorPhoto () {
    return this._authorPhoto
  }

  set authorPhoto (value) {
    this._authorPhoto = value
  }

  /**
   * ФИО автора трибуны
   * @return {String}
   */
  get authorFullName () {
    return this._authorFullName
  }

  set authorFullName (value) {
    this._authorFullName = value
  }

  /**
   * Об авторе трибуы
   * @return {String}
   */
  get authorAbout () {
    return this._authorAbout
  }

  set authorAbout (value) {
    this._authorAbout = value
  }

  /**
   * Связанные объектв
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
    this.setTagList(relatedData)
  }

  /**
   * Список тегов, связанных с событием
   * @return {Tag[]}
   */
  get tagList () {
    return this._tagList
  }

  setTagList (relatedData) {
    this._tagList = []
    const tagsData = this.relationships?.tags?.data
    if (tagsData) {
      for (const tagObj of tagsData) {
        if (tagObj.type === 'Tag') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof Tag && tagObj.id === relatedObj.id) {
              this._tagList.push(relatedObj)
            }
          }
        }
      }
    }
  }

  /**
   * Url на уменьшенное фото автора
   * @return {string}
   */
  authorPhotoThumbnailUrl () {
    return this._getThumbnailUrl(this.authorPhoto)
  }

  /**
   * Url на уменьшенное заглавное изображение
   * @return {string}
   */
  featuredImageThumbnailUrl () {
    return this._getThumbnailUrl(this.featuredImage)
  }

  _getThumbnailUrl (imgUrl) {
    const url = new URL(imgUrl)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }

  /**
   * Дата интервью в languageCode формате
   * @param {string} languageCode
   * @return {string}
   */
  getLocaleInterviewDate (languageCode = 'ru-RU') {
    return this.interviewDate.toLocaleDateString(languageCode, this.dateTimeFormatOptions()).replace(/\s*г\./, '')
  }
}

export default BlogTribuneCard
