/**
 * Изображение из альбома галереи
 *
 * @module Gallery
 * @class AlbumPhoto
 */
class AlbumPhoto {
  constructor (photoObj) {
    this.title = photoObj.title
    this.photoUrl = photoObj.url
  }

  /**
   * Заголовок изображения
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Url изображения
   * @return {String}
   */
  get photoUrl () {
    return this._photoUrl
  }

  set photoUrl (value) {
    this._photoUrl = value
  }

  /**
   * Url на уменьшенное изображение
   * @return {string}
   */
  getThumbnailUrl () {
    const url = new URL(this.photoUrl)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }
}

export default AlbumPhoto
