import Axios from 'axios'
import classFactory from './ClassFactory'
import Pagination from '../pagination/Pagination'
import language from '../../store/modules/language/language'

/**
 * Api Client
 *
 * @module Api
 * @class ApiReadClient
 */
class ApiReadClient {
  constructor (url, params, expectedObjectType) {
    this.url = url
    this.params = this.handleRequestParams(params)
    this.expectedObjectType = expectedObjectType
    this.initCondition()
  }

  initCondition () {
    this._data = []
    this._included = []
    this._errors = []
    this._pagintation = {}
  }

  /**
   * Get site language
   *
   * @return {String}
   */
  get language () {
    return language.state.language
  }

  /**
   * Api url
   * @returns {String}
   */
  get url () {
    return this._url
  }

  set url (value) {
    this._url = value
  }

  /**
   * Api params
   * @returns {object}
   */
  get params () {
    return this._params
  }

  set params (value) {
    this._params = value
  }

  /**
   * Api Expected Object Type
   * @returns {object}
   */
  get expectedObjectType () {
    return this._expectedObjectType
  }

  set expectedObjectType (value) {
    this._expectedObjectType = value
  }

  /**
   * Pagination
   * @returns {Pagination}
   */
  get pagination () {
    return this._pagination
  }

  set pagination (value) {
    this._pagination = value
  }

  /**
   * @return {[]}
   */
  get data () {
    return this._data
  }

  /**
   * @return {[]}
   */
  get included () {
    return this._included
  }

  handleRequestParams (requestParams) {
    const paramsObj = requestParams ?? Object()
    const urlParams = paramsObj?.params ?? new URLSearchParams()
    urlParams.append('site_language', this.language)
    paramsObj.params = urlParams
    return paramsObj
  }

  addData (value) {
    this._data.push(value)
  }

  addIncluded (value) {
    this._included.push(value)
  }

  addError (value) {
    this._errors.push(value)
  }

  hasError () {
    return this._errors.length > 0
  }

  get errors () {
    return this._errors
  }

  isPageNotFound () {
    return this.errors.length === 1 && this.errors[0].status === 404
  }

  /**
   * Проверка валидности ответа от API
   * @param response
   * @return {boolean}
   */
  validateData (response) {
    if (response?.data?.data) {
      for (const obj of response.data.data) {
        if (obj.type !== this.expectedObjectType) {
          return false
        }
      }
    } else {
      return false
    }
    return true
  }

  /**
   * Ошибка возвращена Api?
   * @param errors
   * @return {boolean}
   */
  isApiErrorResponse (errors) {
    return errors.response?.data?.errors?.length
  }

  /**
   * В ответ включены данные связанных объектов?
   * @param response
   * @return {boolean}
   */
  hasIncludedData (response) {
    return response?.included?.length
  }

  /**
   * Были ли созданы связанные объекты?
   * @returns {boolean}
   */
  hasIncludedObjects () {
    return this.included.length > 0
  }

  /**
   * Распарсить полученные связанные объекты
   * @param {Array} responseData
   */
  fetchIncludedData (responseData) {
    this._included = []
    if (this.hasIncludedData(responseData)) {
      for (const includedObj of responseData.included) {
        this.addIncluded(this.createInstance(includedObj))
      }
    }
  }

  /**
   * Распарсить полученные объекты
   * @param {Array} responseData
   */
  fetchData (responseData) {
    for (const objData of responseData.data) {
      this.addData(this.createInstance(objData))
    }
  }

  /**
   * Создать экземпляр объекта, полученного в ответе
   * @param {Object} objData
   * @returns {Object}
   */
  createInstance (objData) {
    const instanceData = objData.attributes
    instanceData.id = objData.id
    instanceData.type = objData.type
    return classFactory(
      objData.type,
      instanceData,
      objData?.relationships,
      objData?.relationships ? this.included : {}
    )
  }

  /**
   * Установить pagination
   * @param {Object} responseData
   */
  fetchPagination (responseData) {
    const pagination = responseData.meta?.page
    if (pagination) {
      this.pagination = new Pagination(pagination)
    }
  }

  /**
   * Обработчик ошибок
   * @param {Object} errors
   */
  errorHandler (errors) {
    if (this.isApiErrorResponse(errors)) {
      for (const error of errors.response.data.errors) {
        this.addError(error)
      }
    } else {
      this.addError(errors)
    }
  }

  /**
   * Получить первый объект из списка
   * @returns {Object|null}
   */
  getFirst () {
    return this._data[0] ?? null
  }

  /**
   * Выполнить запрос
   * @returns {Promise<AxiosResponse<any>>}
   */
  execute () {
    this.initCondition()
    return Axios.get(this.url, this.params).then((response) => {
      if (this.validateData(response)) {
        const responseData = response.data
        this.fetchIncludedData(responseData)
        this.fetchData(responseData)
        this.fetchPagination(responseData)
      } else {
        this.addError({
          title: 'Неизвестная ошибка',
          detail: `Не получен ожидаемый объект ${this.expectedObjectType}`
        })
      }
    }).catch((errors) => {
      this.errorHandler(errors)
    })
  }
}

export default ApiReadClient
