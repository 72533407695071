<template>
  <div
    :id="`blog-event-${eventCard.slug}`"
    class="sw-card sw-card_event"
  >
    <div
      class="sw-card__featured-image sw-card__featured-image_clickable"
      :style="{ backgroundImage: eventCardBgImage }"
      @click="$router.push({name: 'blogEventView', params: { eventIdentifier: eventCard.slug }})"
    >
      <div class="date-block date-block_s">
        {{ eventCard.getLocaleIntervalDates(languageCode) }}
      </div>
      <div class="row no-gutters d-flex flex-column">
        <div class="sw-card__title-text sw-card__title-text_light sw-card__title-text_bold">
          {{ eventCard.title }}
        </div>
        <div
          class="sw-card_event__excerpt"
          v-html="eventCard.excerpt"
        />
      </div>
    </div>
    <div class="row no-gutters d-flex flex-row justify-content-start sw-card_event__location-organization">
      <div class="sw-card_event__location">
        {{ eventCard.location }}
      </div>
      <div class="sw-card_event__organization">
        {{ eventCard.organization }}
      </div>
    </div>
    <div
      v-if="eventCard.hasGalleryAlbum()"
      class="row no-gutters sw-card_event__album"
    >
      <sw-gallery-album-popup
        :def-album-visibility="albumVisible"
        :selected-photo-index="selectedPhotoIndex"
        :gallery-album="eventCard.getFirstGalleryAlbum()"
        @change:visible:hide="hideAlbum()"
      />
      <div
        v-for="(photo, index) in getSliderPhoto()"
        :key="`event-gallery-album-card-slide-${eventCard.slug}-${index}`"
        :class="getAlbumPhotoCssClass(index)"
      >
        <el-image
          class="photo-card photo-card_clickable photo-card_s"
          :src="photo.getThumbnailUrl()"
          fit="cover"
          lazy
          @click="showAlbum(index)"
        />
      </div>
      <div class="col-3 col-lg-4">
        <div
          class="photo-card photo-card__num_s"
        >
          <div class="photo-card__num-text">
            {{ galleryAlbumPhotoNum() }}
          </div>
        </div>
      </div>
    </div>
    <sw-selected-tag-list
      v-if="eventCard.tagList.length"
      :component-key="`sw-card_event-${eventCard.slug}`"
      :tag-list="eventCard.tagList"
      @change:tag="$emit('change:tag', $event)"
    />
  </div>
</template>
<script>

import BlogEventCard from '../../../classes/blog/event/BlogEventCard'
import SwSelectedTagList from '../../tag/SelectedTagList'
import GalleryAlbumPopup from '../../../mixins/GalleryAlbumPopup'
import SwGalleryAlbumPopup from '../../gallery/GalleryAlbumPopup'

export default {
  name: 'SwBlogEventCard',
  components: { SwGalleryAlbumPopup, SwSelectedTagList },
  mixins: [GalleryAlbumPopup],
  props: {
    eventCard: {
      type: BlogEventCard,
      required: true
    }
  },
  emits: ['change:tag'],

  data () {
    return {
      sliderPhotoNum: 4
    }
  },

  computed: {
    eventCardBgImage () {
      return `linear-gradient(180deg, var(--blog-event-card-main-image-start-gradient) 0%,
        var(--blog-event-card-main-image-end-gradient) 100%),
        url('${this.eventCard.featuredImage}')`
    },

    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  },

  methods: {
    /**
     * Список изображений для слайдера
     * @returns {AlbumPhoto[]|null}
     */
    getSliderPhoto () {
      if (this.eventCard.hasGalleryAlbum()) {
        const galleryAlbum = this.eventCard.getFirstGalleryAlbum()
        return galleryAlbum.photoList.slice(0, this.sliderPhotoNum)
      }
      return null
    },

    /**
     * Количество фото в альбоме
     * @returns {Number}
     */
    galleryAlbumPhotoNum () {
      const galleryAlbum = this.eventCard.getFirstGalleryAlbum()
      return galleryAlbum ? galleryAlbum.countPhoto() : 0
    },

    getAlbumPhotoCssClass (index) {
      const cssClasses = ['col-3', 'col-lg-2', 'pr-2']
      if (index > 2) {
        cssClasses.push('d-none')
        cssClasses.push('d-lg-flex')
      }
      return cssClasses
    }
  }

}
</script>

<style lang="scss">
@import "resources/sass/utils";

.sw-card_event {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media screen and (min-width: 569px) and (max-width: 1280px) {
    width: 98%;
  }

  @media screen and (max-width: 768px) {
    max-width: 351px;
  }

  .sw-card__featured-image {
    height: 288px;

    @media screen and (max-width: 768px) {
      height: 232px;
    }
  }

  .sw-card_event__excerpt {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1rem;
    color: var(--blog-event-card-excerpt);

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 0.75rem, 1rem);
    }
  }

  .sw-card_event__album,
  .sw-card_event__location-organization {
    width: 100%;
    max-width: 400px;
    align-self: center;
    padding: 12px 8px 0 8px;
  }

  .sw-card_event__location-organization {
    width: 100%;
    max-width: 400px;
    align-self: center;
    padding: 12px 8px 0 8px;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: bold;

    .sw-card_event__location {
      display: flex;
      flex-direction: row;
      margin-right: 8px;
      color: var(--blog-event-location);

      &::before {
        display: inline-block;
        content: '';
        width: 12px;
        min-width: 12px;
        height: 16px;
        background: transparent url("/static/img/icon/location-dark.svg") no-repeat center;
      }
    }

    .sw-card_event__organization {
      display: flex;
      flex-direction: row;
      color: var(--blog-event-organizer);
    }
  }

  .sw-card_event__album {
    .col-2 {
      padding-right: 8px;
    }

    .photo-card {
      height: 56px;
    }
  }
}
</style>
