/**
 * Склонение числительных
 * @type {{getLocaleForValue(Number, String): String}}
 */
const LocaleForValue = {

  methods: {
    /**
     * Склонение числительных
     * @param {Number} value
     * @param {String} baseMessage
     * @return {String}
     */
    getLocaleForValue (value, baseMessage) {
      let localeMessage = baseMessage
      if (value === 1) {
        localeMessage = localeMessage + '_1'
      } else if (value > 1 && value < 5) {
        localeMessage = localeMessage + '_2_4'
      } else {
        localeMessage = localeMessage + '_more'
      }
      return this.$t(localeMessage, { value })
    }
  }
}
export default LocaleForValue
