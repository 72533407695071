<template>
  <section class="team component-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
        content-id="team-structure-list"
        :title="$t('message.team.main_title')"
      />
    </div>
    <sw-team-structure-list />
  </section>
</template>

<script>
import SwTeamStructureList from '../../components/team/TeamStructureList'
import SwAssetsPageHeader from '../../components/page/AssetsPageHeader'
import SwTeamPageIndicators from '../../components/indicators/TeamPageIndicators'
export default {
  name: 'TeamView',
  components: { SwTeamPageIndicators, SwAssetsPageHeader, SwTeamStructureList }
}
</script>

<style scoped>
.team {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
</style>
