<template>
  <sw-page-indicators
    :indicator-list="getIndicatorList()"
  />
</template>

<script>
import SwPageIndicators from './PageIndicators'
export default {
  name: 'SwFrontPageIndicators',
  components: { SwPageIndicators },

  data () {
    return {
      indicatorList: {
        ru: [
          {
            title: 'Онлайн-курс',
            num: '21'
          },
          {
            title: 'Партнера',
            num: '93'
          },
          {
            title: 'Обучающихся',
            num: '>3500'
          },
          {
            title: 'Направлений подготовки',
            num: '50'
          }
        ],
        en: [
          {
            title: 'Online-courses',
            num: '21'
          },
          {
            title: 'Partners',
            num: '93'
          },
          {
            title: 'Students',
            num: '>3500'
          },
          {
            title: 'Degree programmes',
            num: '50'
          }
        ]
      }
    }
  },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    }
  },

  methods: {
    getIndicatorList () {
      return this.indicatorList[this.language]
    }
  }
}
</script>
