/**
 * Gallery Album Popup mixin
 * @type {{data(): {selectedPhotoIndex: number, albumVisible: boolean}, methods: {showAlbum(number): void, hideAlbum(): void}}}
 */
const GalleryAlbumPopup = {
  data () {
    return {
      /** @type Boolean Видимость popup альбома */
      albumVisible: false,
      /** @type Number Выбранный индекс фото */
      selectedPhotoIndex: 0
    }
  },

  methods: {
    /**
     * Показать альбом начиная с индекса
     * @param index
     */
    showAlbum (index) {
      this.albumVisible = true
      this.selectedPhotoIndex = index
    },

    /**
     * Скрыть альбом
     */
    hideAlbum () {
      this.albumVisible = false
      this.selectedPhotoIndex = 0
    }
  }
}
export default GalleryAlbumPopup
