/**
 * Тег
 *
 * @module Education
 * @class EducationCategory
 */
class EducationCategory {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.description = attributes.description
    this.parentId = attributes.parent_id
  }

  /**
   * Идентификатор категории
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок категории
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор категории
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Описание категории
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * Родительская категория
   * @return {String}
   */
  get parentId () {
    return this._parentId
  }

  set parentId (value) {
    this._parentId = value
  }
}

export default EducationCategory
