import BlogEventCard from '../../../classes/blog/event/BlogEventCard'
import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type {BlogEventCard[]} */
  eventCards: [],

  /** @type {Pagination|null} */
  pagination: null,

  /** @type {String} */
  eventCardsState: 'undefined'
})

const state = initialState()

const getters = {
  /**
   * События
   * @param state
   * @return {BlogEventCard[]}
   */
  getEventCards (state) {
    return state.eventCards
  },

  /**
   * Текущее состояние выводимых событий
   * @param state
   * @return {String}
   */
  getEventCardsState (state) {
    return state.eventCardsState
  },

  /**
   * @param state
   * @returns {Pagination|null}
   */
  getPagination (state) {
    return state.pagination
  }
}
const mutations = {
  ADD_EVENT_CARD (state, card) {
    state.eventCards.push(card)
  },

  SET_PAGINATION (state, pagination) {
    state.pagination = pagination
  },

  SET_EVENT_CARD_STATE (state, eventCardsState) {
    state.eventCardsState = eventCardsState
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  RESET_EVENT_CARDS (state) {
    state.eventCards = []
    state.pagination = null
  }
}
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  resetEventCards ({ commit }) {
    commit('RESET_EVENT_CARDS')
  },

  setEventCardsState ({ commit }, eventCardsState) {
    commit('SET_EVENT_CARD_STATE', eventCardsState)
  },

  async findEventCards (context, params) {
    const apiReadClient =
      new ApiReadClient('/api/blog/events/cards', { params }, 'BlogEventCard')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const eventCard of apiReadClient.data) {
        context.commit('ADD_EVENT_CARD', eventCard)
      }
      context.commit('SET_PAGINATION', apiReadClient.pagination)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
