<template>
  <div
      class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_blue"
      @click="restartTest">
    {{ $t('message.navigator.test_try_again') }}
  </div>
</template>

<script>
export default {
  name: "SwNavigatorRestartTest",

  methods: {
    restartTest () {
      this.$store.dispatch('navigator/reset')
      this.$router.push({name: 'navigatorView'})
    }
  }
}
</script>

<style scoped>

</style>