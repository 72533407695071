<template>
  <div class="navigator-question">
    <div class="row d-flex flex-row">
      <div class="col-auto navigator-question__index">
        {{ questionIndex }}.
      </div>
      <div class="col-7 d-flex flex-column pr-5">
        <div class="navigator-question__text">
          {{ question.question }}
        </div>

        <div class="navigator-question__points-hint mt-3" v-html="$t('message.navigator.question_points_hint')">
        </div>

        <div class="navigator-question__points mt-3">
          <div
              v-for="point of points"
              class="sw-btn sw-btn_b sw-btn_tag sw-btn_bold sw-btn_large-font"
              :key="`question_${question.id}_point_${point}`"
              @click="$emit('trajectory:question:answered', {id: question.id, point: point})"
          >
            {{ getScoreTrans(point) }}
          </div>
        </div>
      </div>
      <div class="col navigator-question__description pl-5">
        <div class="description__title">
          {{ $t('message.navigator.question_description_title') }}
        </div>
        <img class="description__img mt-3 mb-4" :src="question.featuredImage">
        <div class="description__text" v-html="question.description"/>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '../../../classes/education/Question';

export default {
  name: 'SwTrajectoryQuestion',

  props: {
    questionIndex: {
      type: Number,
      required: true
    },
    question: {
      type: Question,
      required: true
    }
  },

  emits: ['trajectory:question:answered'],

  computed: {
    points () {
      return [2, 1, 0, -1, -2]
    }
  },

  methods: {
    getScoreTrans (score) {
      const key = score.toString().replace('-', 'minus_')
      return this.$t(`message.navigator.question_point_${key}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../sass/utils";

.navigator-question {
  .navigator-question__index {
    width: 70px;
  }

  .navigator-question__index,
  .navigator-question__text {
    font-size: 2.25rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.25rem, 2.25rem);
    }
  }

  .navigator-question__points-hint {
    color: var(--sw-primary-text-color-light-3);
    font-size: 1.125rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1rem, 1.125rem);
    }
  }

  .navigator-question__points {
    .sw-btn:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  .navigator-question__description {
    border-left: 5px solid #D9D9D9;

    .description__title {
      color: var(--sw-primary-text-color-light-3);
      font-size: 2.25rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1.25rem, 2.25rem);
      }
    }

    .description__img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    .description__text {
      font-size: 1.125rem;
    }
  }
}
</style>
