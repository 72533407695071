<template>
  <div class="breadcrumbs">
    <div
        class="breadcrumbs__link"
        @click="$router.push({name:'frontpage', params: {contentId}})"
    >
      {{ $t('message.home_page') }}
    </div>
    <div class="breadcrumbs__delimiter"/>
    <template v-if="breadcrumbs">
      <template
          v-for="(item, index) in breadcrumbs"
          :key="`breadcrumbs_item_${index}`">
        <div
            :class="item.location ? 'breadcrumbs__link' : 'breadcrumbs__text'"
            @click="breadcrumbClick(item)"
        >
          {{ item.title }}
        </div>
        <div
            v-if="breadcrumbs.length !== (index + 1)"
            class="breadcrumbs__delimiter"/>
      </template>
    </template>
    <template v-else>
      <div class="breadcrumbs__text">
        {{ title }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SwGoToFrontpage',

  props: {
    contentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    breadcrumbs: {
      type: Array,
      required: false
    }
  },

  methods: {
    breadcrumbClick (breadcrumb) {
      if (breadcrumb.location) {
        this.$router.push(breadcrumb.location)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.12rem;
  line-height: 1.5rem;

  @media screen and (max-width: 1280px) {
    @include fluid-font-size(48rem, 80rem, 0.75rem, 1.12rem);
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    font-weight: bold;
  }

  .breadcrumbs__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .breadcrumbs__delimiter {
    display: block;
    content: '';
    width: 6px;
    height: 10px;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
