/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import router from './router'
import i18n from './i18n'
import ElementPlus from 'element-plus'

import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './public/App'
import { store } from './store'
window.Vue = require('vue')

// eslint-disable-next-line no-undef
const app = Vue.createApp(App)
app
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .use(VueAxios, axios)
  .use(store)
  .mount('#app')
