<template>
  <div class="education-menu">
    <div
        v-for="category in categoryList"
        :key="`education-menu-item-${category.slug}`"
        class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_down sw-btn_front-page-top"
        @click="scrollTo(category.slug)"
    >
        {{ category.title }}
    </div>
  </div>
</template>

<script>
import EducationCategory from '../../classes/education/EducationCategory'
import ScrollTo from '../../mixins/ScrollTo'

export default {
  name: 'SwFrontPageEducationMenu',
  mixins: [ScrollTo],

  props: {
    /** @type EducationCategory[] */
    categoryList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.education-menu {
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: left;

  > :nth-child(odd) {
    margin-right: 8px;
  }
}
</style>
