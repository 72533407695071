import Tag from '../tag/Tag';
import Question from './Question';
import EducationCourse from './EducationCourse';

/**
 * @class EducationTrajectory
 */
class EducationTrajectory {
  constructor (attributes) {
    this.description = attributes.description
    this.documents = attributes.documents
    this.eCourseLink = attributes.e_course_link
    this.eduProgram = attributes.edu_program
    this.featuredImage = attributes.featured_image
    this.id = attributes.id
    this.laborIntensity = attributes.labor_intensity
    this.plan = attributes.plan
    this.results = attributes.results
    this.shortDescription = attributes.short_description
    this.slug = attributes.slug
    this.title = attributes.title
  }

  /**
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок категории
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор категории
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Образовательная программа
   * @return {String}
   */
  get eduProgram () {
    return this._eduProgram
  }

  set eduProgram (value) {
    this._eduProgram = value
  }

  /**
   * Трудоемкость
   * @return {String}
   */
  get laborIntensity () {
    return this._laborIntensity
  }

  set laborIntensity (value) {
    this._laborIntensity = value
  }

  /**
   * Короткое описание
   * @return {String}
   */
  get shortDescription () {
    return this._shortDescription
  }

  set shortDescription (value) {
    this._shortDescription = value
  }

  /**
   * Описание
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * План обучения
   * @return {String}
   */
  get plan () {
    return this._plan
  }

  set plan (value) {
    this._plan = value
  }

  /**
   * Заглавное изображение
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }

  /**
   * Ссылка на электронный курс
   * @return {String}
   */
  get eCourseLink () {
    return this._eCourseLink
  }

  set eCourseLink (value) {
    this._eCourseLink = value
  }

  /**
   * Результаты обучения
   * @return {String}
   */
  get results () {
    return this._results
  }

  set results (value) {
    this._results = value
  }

  /**
   * Документ о прохождении
   * @return {String}
   */
  get documents () {
    return this._documents
  }

  set documents (value) {
    this._documents = value
  }

  /**
   * @return {Question[]}
   */
  get questions () {
    return this._questions
  }

  /**
   * @return {EducationCourse[]}
   */
  get educationCourses () {
    return this._educationCourses
  }

  setQuestions (relatedData) {
    this._questions = []
    const questionsData = this.relationships?.questions?.data
    if (questionsData) {
      for (const questionObj of questionsData) {
        if (questionObj.type === 'Question') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof Question && questionObj.id === relatedObj.id) {
              this._questions.push(relatedObj)
            }
          }
        }
      }
    }
  }

  setEducationCourses (relatedData) {
    this._educationCourses = []
    const coursesData = this.relationships?.educationCourses?.data
    if (coursesData) {
      for (const courseObj of coursesData) {
        if (courseObj.type === 'EducationCourse') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof EducationCourse && courseObj.id === relatedObj.id) {
              this._educationCourses.push(relatedObj)
            }
          }
        }
      }
    }
  }

  /**
   * Связанные объектв
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
    this.setQuestions(relatedData)
    this.setEducationCourses(relatedData)
  }
}

export default EducationTrajectory
