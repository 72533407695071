import BlogPostCard from './BlogPostCard'

/**
 * Пост
 * @module Blog
 * @class BlogPost
 */
class BlogPost extends BlogPostCard {
  constructor (attributes) {
    super(attributes)
    this.content = attributes.content
  }

  /**
   * Текст поста
   * @return {String}
   */
  get content () {
    return this._content
  }

  set content (value) {
    this._content = value
  }
}

export default BlogPost
