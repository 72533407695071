<template>
  <section class="frontpage">
    <div class="container-fluid p-0 d-flex align-items-center flex-column">
      <div class="frontpage__top-block w-100">
        <div class="frontpage__img-layer">
          <div
              class="frontpage__img-background"
          />
          <div class="frontpage__img-layer-gradient"/>
        </div>
        <div class="frontpage__top-layer">
          <div class="frontpage__top-layer-content">
            <div class="d-flex flex-column">
              <div class="col col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
                <div class="frontpage__title">
                  {{ $t('message.frontpage.main_title') }}
                </div>
                <div class="frontpage__sub-title">
                  {{ $t('message.frontpage.sub_title') }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center mt-3 align-items-lg-start flex-lg-row mt-lg-5">
              <sw-front-page-education-menu
                  v-if="educationCategories"
                  :category-list="educationCategories"
              />
              <div class="d-flex mt-3 mt-lg-0 ml-lg-5">
                <sw-front-page-top-additional-menu/>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row w-100 frontpage__indicators-block no-gutters justify-content-around">
        <sw-front-page-indicators/>
      </div>
      <sw-front-page-grants/>
      <sw-education-category-list
          :category-list="educationCategories"
      />
      <sw-front-page-navigator/>
      <sw-front-page-atlas/>
      <template v-if="isCoursesLoaded">
        <sw-blog-post-card-list/>
        <sw-blog-tribune-card-list/>
        <sw-blog-event-card-list/>
        <sw-gallery-album-card-list/>
        <sw-platform-links/>
        <sw-partner-list/>
      </template>
    </div>
  </section>
</template>

<script>
import SwGalleryAlbumCardList from '../components/gallery/GalleryAlbumCardList'
import SwPageTitle from '../components/page/PageTitle'
import SwEducationCategoryList from '../components/education/EducationCategoryList'
import SwFrontPageEducationMenu from '../components/education/FrontPageEducationMenu'
import SwBlogEventCardList from '../components/blog/event/BlogEventCardList'
import SwBlogTribuneCardList from '../components/blog/tribune/BlogTribuneCardList'
import SwFrontPageIndicators from '../components/indicators/FrontPageIndicators'
import ScrollTo from '../mixins/ScrollTo'
import SwPlatformLinks from '../components/platforms/PlatformLinks'
import SwPartnerList from '../components/partners/PartnerList'
import SwBlogPostCardList from '../components/blog/post/BlogPostCardList'
import SwFrontPageNavigator from '../components/education/navigator/FrontPageNavigator';
import SwFrontPageTopAdditionalMenu from '../components/page/FrontPageTopAdditionalMenu';
import SwFrontPageAtlas from '../components/atlas/FrontPageAtlas';
import SwFrontPageGrants from '../components/grants/FrontPageGrants.vue';

export default {
  name: 'Frontpage',
  components: {
    SwFrontPageGrants,
    SwFrontPageAtlas,
    SwFrontPageTopAdditionalMenu,
    SwFrontPageNavigator,
    SwBlogPostCardList,
    SwPartnerList,
    SwPlatformLinks,
    SwFrontPageIndicators,
    SwBlogTribuneCardList,
    SwBlogEventCardList,
    SwFrontPageEducationMenu,
    SwEducationCategoryList,
    SwPageTitle,
    SwGalleryAlbumCardList
  },
  mixins: [ScrollTo],

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    },

    educationCategories () {
      return this.$store.getters['educationCategories/getEducationCategories']
    },

    scrollToContent () {
      return this.$route.params.contentId
    },

    isCoursesLoaded () {
      return this.$store.getters['courseCards/isLoaded']
    }
  },

  watch: {
    /**
     * Слежение за изменением языка
     * При изменении необходимо загрузить категории, которые добавлены для данного языка
     */
    language () {
      this.$store.dispatch('educationCategories/fetchEducationCategories')
    },

    scrollToContent () {
      this.scrollTo(this.scrollToContent)
    }
  },

  created () {
    if (this.educationCategories.length === 0) {
      this.$store.dispatch('educationCategories/fetchEducationCategories')
    }
  },

  mounted () {
    if (this.scrollToContent) {
      this.scrollTo(this.scrollToContent)
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.frontpage {
  margin-left: auto;
  margin-right: auto;

  .frontpage__top-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 760px;
    max-width: 1920px;

    .frontpage__img-layer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      max-width: 1920px;
      height: 760px;
      background-repeat: no-repeat;
      background-position: center center;
      overflow: hidden;

      .frontpage__img-background {
        width: 1920px;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        object-fit: contain;
        -webkit-mask-image: url("/static/img/frontpage/layer-1.svg");
        mask-image: url("/static/img/frontpage/layer-1.svg");
        mask-repeat: no-repeat;
        mask-size: contain;
        background: url("/static/img/frontpage/1920/top-img.jpg") no-repeat center;
        background-position: top center;

        @media screen and (max-width: 1280px) {
          background: url("/static/img/frontpage/1280/top-img.jpg") no-repeat center;
        }

        @media screen and (max-width: 992px) {
          background: url("/static/img/frontpage/992/top-img.jpg") no-repeat center;
        }

        @media screen and (max-width: 768px) {
          width: 768px;
          height: 642px;
          background: url("/static/img/frontpage/768/top-img.jpg") no-repeat center;
          -webkit-mask-image: url("/static/img/frontpage/768/layer-1.svg");
          mask-image: url("/static/img/frontpage/768/layer-1.svg");
        }

        @media screen and (max-width: 568px) {
          background: url("/static/img/frontpage/568/top-img.jpg") no-repeat center;
        }
      }

      .frontpage__img-layer-gradient {
        position: absolute;
        top: 0;
        content: '';
        width: 1920px;
        height: 100%;
        display: none;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 30.94%, rgba(0, 0, 0, 0.1) 66.94%);
        object-fit: contain;
        -webkit-mask-image: url("/static/img/frontpage/layer-1.svg");
        mask-image: url("/static/img/frontpage/layer-1.svg");
        mask-repeat: no-repeat;
        mask-size: contain;

        @media screen and (max-width: 992px) {
          display: block;
        }

        @media screen and (max-width: 768px) {
          width: 768px;
          -webkit-mask-image: url("/static/img/frontpage/768/layer-1.svg");
          mask-image: url("/static/img/frontpage/768/layer-1.svg");
        }
      }
    }

    .frontpage__top-layer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      background-image: url("/static/img/frontpage/layer-3.svg"),
      url("/static/img/frontpage/layer-6.svg");
      background-repeat: no-repeat, no-repeat;
      background-position: center 510px, center 400px;
      z-index: 1;

      @media screen and (max-width: 768px) {
        background-image: url("/static/img/frontpage/768/layer-3.svg"),
        url("/static/img/frontpage/768/layer-6.svg");
        background-position: center 510px, center 430px;
      }

      .frontpage__top-layer-content {
        position: absolute;
        top: 255px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 1232px;

        @media screen and (max-width: 1280px) {
          padding: 0 2.81vw;
        }

        @media screen and (max-width: 992px) {
          top: 320px;
        }

        @media screen and (max-width: 768px) {
          top: 250px;
        }

        @media screen and (max-width: 376px) {
          top: 220px;
          padding: 0;
        }

        .frontpage__title {
          max-width: 780px;
          font-size: 2.5rem;
          line-height: 2.5rem;
          font-weight: bold;
          letter-spacing: -0.02em;
          font-feature-settings: 'kern' off;
          color: var(--frontpage-title);

          @media screen and (max-width: 992px) {
            text-align: center;
          }

          @media screen and (max-width: 568px) {
            font-size: 2.2rem;
            line-height: 2.5rem;
          }

          @media screen and (max-width: 468px) {
            font-size: 2rem;
            line-height: 2.2rem;
          }
        }

        .frontpage__sub-title {
          max-width: 416px;
          margin-top: 25px;
          font-size: 1.25rem;
          line-height: 2rem;
          font-weight: bold;
          letter-spacing: -0.02em;
          font-feature-settings: 'kern' off;
          color: var(--frontpage-title);

          @media screen and (max-width: 992px) {
            text-align: center;
            max-width: 100%;
          }

          @media screen and (max-width: 568px) {
            font-size: 1rem;
            line-height: 1.75rem;
            max-width: 300px;
          }
        }
      }
    }
  }

  .frontpage__indicators-block {
    margin-top: -100px;
    background-color: #fff;

    .page-indicator-list {
      max-width: 1232px;
      z-index: 3;
    }

    @media screen and (max-width: 992px) {
      margin-top: -125px;
    }

    @media screen and (max-width: 768px) {
      margin-top: -175px;
      z-index: 2;
    }

    @media screen and (max-width: 568px) {
      margin-top: -200px;
    }
  }

  .frontpage-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1232px;
    padding: 80px 0;

    @media screen and (max-width: 1280px) {
      padding: 80px 2.81vw;
    }

    @media screen and (max-width: 768px) {
      padding: 30px 4.7vw;
    }

    @media screen and (max-width: 568px) {
      padding: 38px 12px;
    }
  }

  .container-fluid {
    section:nth-child(odd) {
      background-color: var(--frontpage-odd-block);
    }

    section:nth-child(even) {
      background-color: var(--frontpage-even-block);
    }
  }
}
</style>
