<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault'

export default {
  name: 'App',
  components: {LayoutDefault},
  head: {
    link: [
      {
        rel: 'icon',
        href: '/static/favicon.ico'
      }
    ]
  },

  computed: {
    layout: function () {
      return this.$route.meta.layout || LayoutDefault
    }
  },

  created () {
    document.title = 'SNOWORD: Информационно-образовательная платформа'
  }
}
</script>

<style lang="scss">
</style>
