<template>
  <nav class="navbar sticky-top sticky-top-menu">
    <div
      v-for="menuItem in menuItems"
      :key="`education-course-view-sticky-top-menu-${menuItem.hash}`"
      class="sticky-top-menu__item"
      @click="clickStickyMenuItem(menuItem.hash)"
    >
      {{ menuItem.name }}
    </div>
  </nav>
</template>

<script>
import ScrollTo from '../../../mixins/ScrollTo'

export default {
  name: 'SwStickyTopMenu',
  mixins: [ScrollTo],

  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },

  methods: {
    clickStickyMenuItem (itemHash) {
      this.scrollTo(itemHash, -100)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.sticky-top-menu {
  justify-content: flex-start;
  margin-top: -22px;
  padding: 22px 0 22px 0;
  background-color: var(--course-sticky-top-menu-bg);
  border-bottom: 0.5px solid var(--light-border);

  @media screen and (max-width: 768px) {
    justify-content: center;
    padding-bottom: 12px;
  }

  .sticky-top-menu__item {
    padding-right: 20px;
    font-weight: bold;
    font-size: 1rem;
    color: var(--text-main);
    opacity: 0.5;
    cursor: pointer;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 0.88rem, 1rem);
    }

    &.sticky-top-menu__item_active,
    &:hover {
      opacity: 1;
      color: var(--sticky-top-menu-item-hover);
    }
  }
}
</style>
