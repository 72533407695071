<template>
  <div
    v-if="isLoadingData"
    v-loading="isLoadingData"
    class="content-waiting-loader"
  />
  <section
    v-if="album"
    :key="`gallery-album-${album.slug}`"
  >
    <sw-gallery-album-popup
      :def-album-visibility="albumVisible"
      :selected-photo-index="selectedPhotoIndex"
      :gallery-album="album"
      @change:visible:hide="hideAlbum()"
    />
    <div class="gallery-album-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
          :content-id="`gallery-album-${album.slug}`"
          :section-name="$t('message.gallery.gallery')"
          :title="album.title"
        />
      </div>
      <div class="gallery-album-view__body content-view__body">
        <div
          v-if="album.description"
          class="row no-gutters gallery-album-view__description"
          v-html="album.description"
        />
        <sw-content-page-section
          :margin-bottom="false"
        >
          <template #header>
            {{ $t('message.gallery.photo_list') }}
          </template>
          <template #main>
            <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 gallery-album-view__photo-slider">
              <div
                v-for="(photo, index) in album.photoList"
                :key="`gallery-album-slide-${album.slug}-${index}`"
                class="col mb-4 d-flex flex-column align-items-center"
              >
                <el-image
                  class="photo-card photo-card_clickable photo-card_b"
                  :src="photo.getThumbnailUrl()"
                  fit="cover"
                  lazy
                  @click="showAlbum(index)"
                />
                <div class="gallery-album-view__photo-title">
                  {{ photo.title }}
                </div>
              </div>
            </div>
          </template>
        </sw-content-page-section>
      </div>
    </div>
  </section>
</template>

<script>
import SwContentPageHeader from '../../components/page/ContentPageHeader'
import GalleryAlbum from '../../classes/gallery/GalleryAlbum'
import ApiReadClient from '../../classes/api/ApiReadClient'
import SwGalleryAlbumPopup from '../../components/gallery/GalleryAlbumPopup'
import LoadingData from '../../mixins/LoadingData'
import GalleryAlbumPopup from '../../mixins/GalleryAlbumPopup'
import SwContentPageSection from '../../components/page/ContentPageSection'
import SwError from '../../classes/error/Error'

export default {
  name: 'SwGalleryAlbumView',
  components: { SwContentPageSection, SwGalleryAlbumPopup, SwContentPageHeader },
  mixins: [LoadingData, GalleryAlbumPopup],

  data () {
    return {
      albumIdentifier: null,
      /** @type GalleryAlbum */
      album: null
    }
  },

  created () {
    this.startDataLoading()
    this.albumIdentifier = this.$route.params.albumIdentifier ?? null
    this.getAlbum()
  },

  methods: {
    getFirstPhotoUrl () {
      const photo = this.album.getPhoto(0)
      return photo.photoUrl
    },

    async getAlbum () {
      const params = new URLSearchParams()
      params.append('include', 'tags')
      const apiReadClient =
          new ApiReadClient(
              `/api/gallery/albums/${this.albumIdentifier}`,
              { params },
              'GalleryAlbum'
          )
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({ name: 'pageNotFound' })
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), { root: true })
          }
        }
      } else {
        this.album = apiReadClient.getFirst()
      }
      this.dataLoaded()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.gallery-album-view {
  .gallery-album-view__body {
    .gallery-album-view__description {
      margin-bottom: 40px;
      font-size: 1.25rem;
      color: var(--content-main-text);

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.875rem, 1.25rem);
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 34px;
      }

      @media screen and (max-width: 375px) {
        margin-bottom: 18px;
      }
    }

    .gallery-album-view__photo-slider {
      margin-bottom: 11px;

      .gallery-album-view__photo-title {
        font-size: 0.88rem;
        color: var(--text-main);
        opacity: 0.6;
      }
    }
  }
}
</style>
