<template>
  <sw-course-card-list
    v-for="category in categoryList"
    :key="`education-category-${category.slug}`"
    :category="category"
  />
</template>

<script>
import SwCourseCardList from './CourseCardList'
import EducationCategory from '../../classes/education/EducationCategory'
export default {
  name: 'SwEducationCategoryList',
  components: { SwCourseCardList },

  props: {
    /** @type EducationCategory[] */
    categoryList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
