import Tag from '../../../classes/tag/Tag'
import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type Tag[] */
  tags: [],
  /** @type Tag */
  currTag: null
})

const state = initialState()

const getters = {
  /**
   * Список тегов, связанных с галереей
   * @return Tag[]
   */
  getTags (state) {
    return state.tags
  },

  /**
   * Текущий тег галереи, выбранный пользователем
   * @return Tag
   */
  getCurrentTag (state) {
    return state.currTag
  }
}

const mutations = {
  ADD_TAG (state, tag) {
    state.tags.push(tag)
  },

  SET_CURRENT_TAG (state, tag) {
    state.currTag = tag
  },

  RESET_CURRENT_TAG (state, tag) {
    state.currTag = null
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  setCurrentTag ({ commit }, tag) {
    commit('SET_CURRENT_TAG', tag)
  },

  resetCurrentTag ({ commit }, tag) {
    commit('RESET_CURRENT_TAG', tag)
  },

  async fetchTags (context) {
    const apiReadClient =
      new ApiReadClient('/api/gallery/tags', {}, 'Tag')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const tag of apiReadClient.data) {
        context.commit('ADD_TAG', tag)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
