import messages from '../locales'
window.VueI18n = require('vue-i18n')

const i18n = VueI18n.createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages
})

export default i18n
