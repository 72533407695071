<template>
  <div
      v-if="isLoadingData"
      v-loading="isLoadingData"
      class="content-waiting-loader"
  />
  <sw-education-course-content
      v-if="course"
      :breadcrumbs="breadcrumbsProp"
      :course="course"
      :course-url="courseUrlProp"
  />
</template>

<script>
import ApiReadClient from '../../classes/api/ApiReadClient'
import SwError from '../../classes/error/Error'
import EducationCourse from '../../classes/education/EducationCourse'
import LoadingData from '../../mixins/LoadingData'
import LocaleForValue from '../../mixins/LocaleForValue'
import SwEducationCourseContent from '../../components/education/course/CourseContent';

export default {
  name: 'EducationCourseView',
  components: {SwEducationCourseContent},
  mixins: [LoadingData, LocaleForValue],
  data () {
    return {
      courseIdentifier: null,
      /** @type EducationCourse */
      course: null
    }
  },

  props: {
    courseIdentifierProp: {
      type: String,
      required: false,
    },
    courseUrlProp: {
      type: String,
      required: false,
    },
    breadcrumbsProp: {
      type: Array,
      required: false,
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    },
  },

  created () {
    this.startDataLoading()
    this.courseIdentifier = this.courseIdentifierProp ?? this.$route.params.courseIdentifier ?? null
    this.getCourse()
  },

  methods: {
    async getCourse () {
      const params = new URLSearchParams()
      params.append('include', 'rightHolders,authors')
      const apiReadClient =
          new ApiReadClient(
              `/api/education/courses/${this.courseIdentifier}`,
              {params},
              'EducationCourse'
          )
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({name: 'pageNotFound'})
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), {root: true})
          }
        }
      } else {
        this.course = apiReadClient.getFirst()
      }
      this.dataLoaded()
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
