import BlogEventCard from './BlogEventCard'
/**
 * Событие
 *
 * @module Blog
 * @class BlogEvent
 */

class BlogEvent extends BlogEventCard {
  constructor (attributes) {
    super(attributes)
    this.participants = attributes.participants
    this.description = attributes.description
    this.conductingFormat = attributes.conducting_format
    this.organizer = attributes.organizer
  }

  /**
   * Уастники события
   * @returns {String}
   */
  get participants () {
    return this._participants
  }

  set participants (value) {
    this._participants = value
  }

  /**
   * Описание события
   * @returns {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * Формат проведения
   * @returns {String}
   */
  get conductingFormat () {
    return this._conductingFormat
  }

  set conductingFormat (value) {
    this._conductingFormat = value
  }

  /**
   * Организатор события
   * @return {String}
   */
  get organizer () {
    return this._organizer
  }

  set organizer (value) {
    this._organizer = value
  }
}

export default BlogEvent
