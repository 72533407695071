<template>
  <div
    v-if="isLoadingData"
    v-loading="isLoadingData"
    class="content-waiting-loader"
  />
  <section
    v-if="tribune"
    :key="`blog-tribune-view-${tribune.slug}`"
    v-loading="isLoadingData"
  >
    <div class="blog-tribune-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
          :content-id="`blog-tribune-${tribune.slug}`"
          :section-name="$t('message.tribune.tribunes')"
          :title="tribune.title"
          :bg-image-url="tribune.featuredImage"
        />
      </div>
      <div class="blog-tribune-view__body content-view__body">
        <div class="row flex-column flex-sm-row justify-content-center no-gutters">
          <div class="col-12 col-sm-2 d-flex align-items-center  justify-content-center justify-content-sm-start blog-tribune-view__interview-date">
            {{ tribune.getLocaleInterviewDate(languageCode) }}
          </div>
          <div class="col-12 col-sm-10 blog-tribune-view__author">
            <div
              :style="{ backgroundImage: `url('${tribune.authorPhotoThumbnailUrl()}')` }"
              class="blog-tribune-view__author-photo"
            />
            <div class="blog-tribune-view__author-info">
              <div class="blog-tribune-view__author-name">
                {{ tribune.authorFullName }}
              </div>
              <div class="blog-tribune-view__author-about">
                {{ tribune.authorAbout }}
              </div>
            </div>
          </div>
        </div>
        <div class="blog-tribune-view__block-delimiter" />
        <template
          v-if="tribune.excerpt"
        >
          <div class="blog-tribune-view__excerpt">
            {{ tribune.excerpt }}
          </div>
          <div class="blog-tribune-view__block-delimiter" />
        </template>
        <div
          class="blog-tribune-view__content"
          v-html="tribune.content"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ApiReadClient from '../../../classes/api/ApiReadClient'
import SwError from '../../../classes/error/Error'
import SwContentPageHeader from '../../../components/page/ContentPageHeader'
import LoadingData from '../../../mixins/LoadingData'

export default {
  name: 'SwBlogTribuneView',
  components: { SwContentPageHeader },
  mixins: [LoadingData],

  data () {
    return {
      tribuneIdentifier: null,
      /** @type BlogTribune */
      tribune: null
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  },

  created () {
    this.tribuneIdentifier = this.$route.params.tribuneIdentifier ?? null
    this.getTribune()
  },

  methods: {
    async getTribune () {
      const apiReadClient =
        new ApiReadClient(`/api/blog/tribunes/${this.tribuneIdentifier}`, {}, 'BlogTribune')
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({ name: 'pageNotFound' })
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), { root: true })
          }
        }
      } else {
        this.tribune = apiReadClient.getFirst()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.blog-tribune-view {
  .blog-tribune-view__body {
    color: var(--text-main);

    .blog-tribune-view__block-delimiter {
      margin-top: 39px;
      padding-bottom: 38px;
      border-top: 0.5px solid var(--light-border);

      @media screen and (max-width: 1280px) {
        margin-top: 3.05vw;
        padding-bottom: 2.97vw;
      }

      @media screen and (max-width: 668px) {
        margin-top: 17px;
        padding-bottom: 16px;
      }
    }

    .blog-tribune-view__interview-date {
      font-weight: bold;
      font-size: 1.13rem;
      opacity: 0.6;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 1.13rem);
      }

      @media screen and (max-width: 668px) {
        margin-bottom: 10px;
      }
    }

    .blog-tribune-view__author {
      display: flex;
      flex-direction: row;

      .blog-tribune-view__author-photo {
        display: block;
        content: '';
        width: 120px;
        min-width: 120px;
        height: 120px;
        min-height: 120px;
        border-radius: 32px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .blog-tribune-view__author-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 10px;

        .blog-tribune-view__author-name {
          font-size: 1rem;

          @media screen and (max-width: 1280px) {
            @include fluid-font-size(48rem, 80rem, 0.88rem, 1rem);
          }
        }

        .blog-tribune-view__author-about {
          font-size: 0.81rem;
          opacity: 0.7;

          @media screen and (max-width: 1280px) {
            @include fluid-font-size(48rem, 80rem, 0.75rem, 0.81rem);
          }
        }
      }
    }

    .blog-tribune-view__excerpt {
      font-size: 1.5rem;
      opacity: 0.6;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1.13rem, 1.5rem);
      }
    }

    .blog-tribune-view__content {
      font-size: 1.13rem;
      opacity: 0.8;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 1.13rem);
      }
    }
  }
}
</style>
