<template>
  <sw-page-indicators
    :indicator-list="indicatorList"
  />
</template>

<script>
import SwPageIndicators from './PageIndicators'
export default {
  name: 'SwTeamPageIndicators',
  components: { SwPageIndicators },
  data () {
    return {
      indicatorList: [
        {
          title: 'Подзаголовок статистики',
          num: '1234'
        },
        {
          title: 'Подзаголовок статистики',
          num: '234'
        },
        {
          title: 'Подзаголовок статистики',
          num: '23'
        },
        {
          title: 'Подзаголовок статистики',
          num: '12'
        }
      ]
    }
  }
}
</script>
