<template>
  <div
    class="layout-front-page"
    data-theme="default"
  >
    <page-header />
    <sw-error />
    <router-view />
    <page-footer />
    <sw-page-back-top />
  </div>
</template>

<script>
import PageHeader from '../components/page/PageHeader'
import PageFooter from '../components/page/PageFooter'
import SwError from '../components/page/Error'
import SwPageBackTop from '../components/page/PageBackTop'

export default {
  name: 'LayoutFrontPage',
  components: { SwPageBackTop, SwError, PageFooter, PageHeader }
}
</script>

<style lang="scss" scoped>
</style>
