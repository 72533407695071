<template>
  <div
    :id="`education-course-${courseCard.slug}`"
    class="sw-card sw-card_course"
    @click="showDescription = !showDescription"
  >
    <div
      v-show="!showDescription"
      class="sw-card_course__observe"
    >
      <div class="course-card__body">
        <div class="row w-100 h-100 no-gutters">
          <div class="col-8 d-flex flex-column align-items-start">
            <div
              class="d-flex align-items-center"
              :class="{'course-card__has-certificate': courseCard.hasCertificate}"
            >
              <template v-if="courseCard.hasCertificate">
                {{ $t('message.education.course.has_certificate') }}
              </template>
            </div>
            <div class="course-card__title">
              {{ courseCard.title }}
            </div>
          </div>
          <div
            class="col-4 d-flex flex-column align-items-end justify-content-between"
          >
            <div class="date-block date-block_m date-block_absolute">
              {{ getLocaleForValue(courseCard.duration, 'message.education.course.duration') }}
            </div>
            <el-image
              :key="`education-course-featured-image-${courseCard.slug}`"
              :src="courseCard.getThumbnailImageUrl()"
              class="course-card__featured-image"
              lazy
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showDescription"
      class="sw-card_course__reverse"
    >
      <div class="row h-100">
        <div class="col-5 h-100 d-flex flex-column justify-content-between">
          <div class="course-card__description-title">
            {{ courseCard.title }}
          </div>
          <div
            class="sw-btn sw-btn_b sw-btn_primary"
            @click="$router.push({name: 'educationCourseView', params: {courseIdentifier: courseCard.slug} })"
          >
            {{ $t('message.more_details') }}
          </div>
        </div>
        <div class="col-7 h-100 course-card__description-excerpt">
          <el-scrollbar height="100%">
            {{ courseCard.excerpt }}
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCourseCard from '../../classes/education/EducationCourseCard'
import LocaleForValue from '../../mixins/LocaleForValue'

export default {
  name: 'SwCourseCard',
  mixins: [LocaleForValue],

  props: {
    courseCard: {
      type: EducationCourseCard,
      required: true
    }
  },

  data () {
    return {
      showDescription: false
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.sw-card {
  &.sw-card_course {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 608px;

    @media screen and (min-width: 992px) and (max-width: 1280px) {
      width: 98%;
    }

    .sw-card_course__observe,
    .sw-card_course__reverse {
      width: 100%;
      height: 240px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      cursor: pointer;
      @include fade-in-content();

      @media screen and (max-width: 768px) {
        padding: 13px;
        border-radius: 16px;
      }
    }

    .sw-card_course__reverse {
      color: var(--course-card-description);
      background: radial-gradient(25.79% 59.65% at 99.28% 98.58%, #fb9b0b 24.76%, rgba(251, 155, 11, 0) 100%),
      radial-gradient(43.23% 89.83% at 83.88% 33.75%, #ee5a07 49.45%, rgba(238, 108, 7, 0) 100%),
      radial-gradient(84.2% 360.62% at 19.16% 38.96%, #de2d00 49.81%, rgba(237, 107, 6, 0) 100%);
      box-shadow: 0 32px 32px -16px rgba(237, 107, 6, 0.4);

      .course-card__description-title {
        max-width: 191px;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.3rem;
      }

      .course-card__description-excerpt {
        font-weight: bold;

        .el-scrollbar__thumb {
          background-color: var(--course-card-description);
        }
      }
    }

    .sw-card_course__observe {
      justify-content: center;
      background-color: var(--course-card-bg);
      border: 0.5px solid var(--course-card-border);

      .course-card__body {
        width: 100%;
        height: 100%;

        .course-card__has-certificate {
          font-size: 1rem;
          color: var(--course-card-certificate);

          @media screen and (max-width: 1280px) {
            line-height: 0.87rem;
            @include fluid-font-size(48rem, 80rem, 0.75rem, 1rem);
          }

          &::before {
            display: block;
            content: '';
            width: 32px;
            height: 32px;
            margin-right: 12px;
            background: url("/static/img/icon/certificate.svg") no-repeat center;
            background-size: contain;
          }
        }

        .course-card__featured-image {
          width: 100%;
          max-width: 182px;
          height: 100%;
          max-height: 176px;
          margin-top: 16px;
          object-fit: cover;
          border-radius: 56px;

          @media screen and (max-width: 1280px) {
            border-radius: 4vw;
          }

          @media screen and (max-width: 768px) {
            border-radius: 24px;
          }

          @media screen and (max-width: 568px) {
            margin-top: 16px;
          }
        }

        .course-card__title {
          margin-top: auto;
          margin-bottom: auto;
          font-size: 1.5rem;
          line-height: 1.7rem;
          color: var(--text-main);

          @media screen and (max-width: 568px) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
</style>
