<template>
  <div
      v-if="isLoadingData"
      v-loading="isLoadingData"
      class="content-waiting-loader"
  />
  <education-course-view
      v-if="trajectory"
      :breadcrumbs-prop="breadcrumbs"
      :course-url-prop="trajectory.eCourseLink"
      :course-identifier-prop="courseIdentifier"/>
</template>

<script>

import ApiReadClient from '../../../classes/api/ApiReadClient';
import SwError from '../../../classes/error/Error';
import LoadingData from '../../../mixins/LoadingData';
import EducationCourseView from '../EducationCourseView';

export default {
  name: 'TrajectoryEducationCourseView',
  components: {EducationCourseView},
  mixins: [LoadingData],

  data () {
    return {
      courseIdentifier: null,
      trajectorySlug: null,
      /** @type EducationTrajectory */
      trajectory: null
    }
  },

  computed: {
    breadcrumbs () {
      return [
        {
          title: this.$t('message.navigator.navigator'),
          location: {name: 'navigatorView'}
        },
        {
          title: this.$t('message.education.trajectory.trajectories'),
          location: null,
        },
        {
          title: this.trajectory.title,
          location: {name: 'educationTrajectoryView', params: {trajectoryIdentifier: this.trajectory.slug}},
        },
      ]
    }
  },

  created () {
    this.startDataLoading()
    this.trajectorySlug = this.$route.params.trajectorySlug ?? null
    this.courseIdentifier = this.$route.params.courseSlug ?? null

    if (!this.trajectorySlug || !this.courseIdentifier) {
      this.$router.push({name: 'pageNotFound'})
    }

    this.fetchTrajectory()
  },

  methods: {
    async fetchTrajectory () {
      const apiReadClient =
          new ApiReadClient(
              `/api/education/trajectories/${this.trajectorySlug}`,
              {},
              'EducationTrajectory'
          )

      await apiReadClient.execute()

      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({name: 'pageNotFound'})
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), {root: true})
          }
        }
      } else {
        this.trajectory = apiReadClient.getFirst()
      }
      this.dataLoaded()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
