import { createStore } from 'vuex'
import navigator from './modules/education/navigator'
import educationCategories from './modules/education/educationCategories'
import gallery from './modules/gallery/gallery'
import galleryTag from './modules/gallery/galleryTag'
import eventCards from './modules/blog/eventCards'
import eventTag from './modules/blog/eventTag'
import tribuneCards from './modules/blog/tribuneCards'
import tribuneTag from './modules/blog/tribuneTag'
import postCards from './modules/blog/postCards'
import postTag from './modules/blog/postTag'
import error from './modules/error/error'
import courseCards from './modules/education/courseCards'
import partners from './modules/partner/partners'
import language from './modules/language/language'

export const store = createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {
        reset({ commit }) {
            commit('educationCategories/RESET')
            commit('navigator/RESET')
            commit('eventTag/RESET')
            commit('eventCards/RESET')
            commit('galleryTag/RESET')
            commit('gallery/RESET')
            commit('tribuneTag/RESET')
            commit('tribuneCards/RESET')
            commit('postTag/RESET')
            commit('postCards/RESET')
            commit('courseCards/RESET')
            commit('partners/RESET')
            commit('language/RESET')
        }
    },
    modules: {
        error,
        gallery,
        galleryTag,
        educationCategories,
        navigator,
        eventCards,
        eventTag,
        tribuneCards,
        tribuneTag,
        courseCards,
        postCards,
        postTag,
        partners,
        language
    }
})
