<template>
  <div class="frontpage-block row w-100 justify-content-center">
    <div class="grants" @click="$router.push({name: 'grantsView' })">
      <div class="grants__content">
        <div class="d-flex flex-column flex-sm-row w-100 no-gutters">
          <div class="d-flex col-12 col-sm-6">
            <div class="col-2 grants__logo"/>
            <div class="col-10 d-flex flex-column justify-content-center">
              <div class="grants__title">
                Именная стипендия
              </div>
              <div class="grants__sub-title">
                для коренных малочисленных народов Севера, Сибири и Дальнего Востока
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 d-flex flex-column justify-content-center">
            <div class="grants__text">
              Для студентов российских вузов
            </div>
            <div class="grants__text">
              Стипендия выдается на один учебный год, выплата 10 000 ₽ в месяц
            </div>
            <div class="grants__text">
              Узнать условия и отправить заявку
            </div>
          </div>
        </div>
      </div>
      <div class="grants__bottom"/>
    </div>
  </div>
</template>

<script>

import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader';

export default {
  name: 'SwFrontPageGrants',
  components: {SwFrontPageBlockHeader}
}
</script>

<style lang="scss" scoped>
.row {

  .grants {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    border: .5px solid var(--grants-card-border);
    border-radius: 24px;
    cursor: pointer;

    .grants__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1232px;
      height: fit-content;
      padding: 20px 20px 0 20px;
      color: var(--text-main);

      .d-flex {
        .grants__title {
          font-size: 1.625rem;
          font-weight: bold;
        }

        .grants__sub-title {
          max-width: 320px;
          font-size: 1.125rem;
        }

        .grants__text {
          font-size: 1.125rem;
          color: var(--page-sub-title);
        }

        .grants__logo {
          width: 137px;
          height: 137px;
          background-position: left;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("/static/img/grants/logo.png");
        }
      }
    }

    .grants__bottom {
      width: 100%;
      height: 21px;
      margin-top: 20px;
      content: '';
      background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(38, 163, 226, 1) 25%);
      border-radius: 0 0 24px 24px;
    }
  }
}
</style>
