import EducationCourseCard from './EducationCourseCard'
import EducationCourseRightHolder from './EducationCourseRightHolder'
import TeamMember from '../team/TeamMember'

/**
 * Курс
 *
 * @module Education
 * @class EducationCourse
 */
class EducationCourse extends EducationCourseCard {
  dateTimeFormatOptions () {
    return { year: 'numeric', month: 'long', day: 'numeric' }
  };

  constructor (attributes) {
    super(attributes)
    this.content = attributes.content
    this.url = attributes.url
    this.startDate = attributes.start_date
    this.endDate = attributes.end_date
    this.laboriousness = attributes.laboriousness
    this.laborCosts = attributes.labor_costs
    this.entranceRequirements = attributes.entrance_requirements
    this.curriculum = attributes.curriculum
    this.formedCompetencies = attributes.formed_competencies
    this.learningOutcomes = attributes.learning_outcomes
    this.trainingDirections = attributes.training_directions
  }

  /**
   * Содержимое курса
   * @return {String}
   */
  get content () {
    return this._content
  }

  set content (value) {
    this._content = value
  }

  /**
   * Ссылка на курс
   * @return {String}
   */
  get url () {
    return this._url
  }

  set url (value) {
    this._url = value
  }

  /**
   * Трудоемкость курса (зачетные единицы)
   * @return {Number}
   */
  get laboriousness () {
    return this._laboriousness
  }

  set laboriousness (value) {
    this._laboriousness = value
  }

  /**
   * Трудозатраты на курс (часов в неделю)
   * @return {Number}
   */
  get laborCosts () {
    return this._laborCosts
  }

  set laborCosts (value) {
    this._laborCosts = value
  }

  /**
   * Входные требования для курса
   * @return {String}
   */
  get entranceRequirements () {
    return this._entranceRequirements
  }

  set entranceRequirements (value) {
    this._entranceRequirements = value
  }

  /**
   * Учебный план
   * @return {String}
   */
  get curriculum () {
    return this._curriculum
  }

  set curriculum (value) {
    this._curriculum = value
  }

  /**
   * Формируемые компетенции
   * @return {String}
   */
  get formedCompetencies () {
    return this._formedCompetencies
  }

  set formedCompetencies (value) {
    this._formedCompetencies = value
  }

  /**
   * Результаты обучения
   * @return {String}
   */
  get learningOutcomes () {
    return this._learningOutcomes
  }

  set learningOutcomes (value) {
    this._learningOutcomes = value
  }

  /**
   * Направления подготовки
   * @return {String}
   */
  get trainingDirections () {
    return this._trainingDirections
  }

  set trainingDirections (value) {
    this._trainingDirections = value
  }

  /**
   * Дата начала курса
   * @return {Date}
   */
  get startDate () {
    return this._startDate
  }

  set startDate (value) {
    this._startDate = value ? new Date(value) : null
  }

  /**
   * Дата окончания курса
   * @return {Date}
   */
  get endDate () {
    return this._endDate
  }

  set endDate (value) {
    this._endDate = value ? new Date(value) : null
  }

  /**
   * Дата начала курса в languageCode формате
   * @param {string} languageCode
   * @return {string|null}
   */
  getLocaleStartDate (languageCode = 'ru-RU') {
    if (this.startDate) {
      return this.startDate.toLocaleDateString(languageCode, this.dateTimeFormatOptions()).replace(/\s*г\./, '')
    }
    return null
  }

  /**
   * Дата окончания курса в languageCode формате
   * @param {string} languageCode
   * @return {string|null}
   */
  getLocaleEndDate (languageCode = 'ru-RU') {
    if (this.endDate) {
      return this.endDate.toLocaleDateString(languageCode, this.dateTimeFormatOptions()).replace(/\s*г\./, '')
    }
    return null
  }

  /**
   * Правообладатель курса
   * @return {EducationCourseRightHolder|null}
   */
  get rightHolder () {
    return this._rightHolder
  }

  setRightHolder (relatedData) {
    const rightHolderData = this.relationships?.right_holder?.data
    if (rightHolderData) {
      for (const relatedObj of relatedData) {
        if (relatedObj instanceof EducationCourseRightHolder && rightHolderData.id === relatedObj.id) {
          this._rightHolder = relatedObj
          break
        }
      }
    }
  }

  /**
   * Авторы курса
   * @return {TeamMember[]}
   */
  get authors () {
    return this._authors
  }

  setAuthors (relatedData) {
    this._authors = []
    const authorsData = this.relationships?.authors?.data
    if (authorsData) {
      for (const authorObj of authorsData) {
        if (authorObj.type === 'TeamMember') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof TeamMember && authorObj.id === relatedObj.id) {
              this._authors.push(relatedObj)
            }
          }
        }
      }
    }
  }

  setRelatedObjects (relatedData) {
    this.setCategory(relatedData)
    this.setRightHolder(relatedData)
    this.setAuthors(relatedData)
  }
}

export default EducationCourse
