<template>
  <div class="page-title">
    <main>
      <span class="page-title__main">
        <slot name="main" />
      </span>
    </main>
    <sub>
      <span class="main-text">
        <slot name="sub" />
      </span>
    </sub>
  </div>
</template>

<script>
export default {
  name: 'SwPageTitle'
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.page-title {
  text-align: center;

  .page-title__main {
    font-size: 3.5rem;
    color: var(--text-main);

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.75rem, 3.5rem);
    }
  }
}
</style>
