<template>
  <section id="atlas" class="atlas component-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
          content-id="atlas"
          :title="$t('message.atlas.atlas')"
      />
    </div>
    <div class="content-view">
      <div class="content-view__body">
        <div class="atlas__title">
          Интерактивный атлас коренных малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока: языки и&nbsp;культуры
        </div>

        <img class="mt-4" src="/static/img/atlas/image-1.svg">

        <div class="atlas__sub-text mt-3 mt-md-4">
          Атлас&nbsp;&mdash; информационно-образовательный ресурс, содержащий систематизированные научные данные об&nbsp;историко-культурном,
          социально-антропологическом и&nbsp;лингвистическом многообразии коренных малочисленных народов Севера, Сибири
          и&nbsp;Дальнего Востока.
        </div>

        <div class="atlas__text mt-3 mt-md-4">
          Интерактивный атлас
          <ul class="atlas__list">
            <li>
              содействует сохранению, популяризации и&nbsp;развитию традиционной культуры и&nbsp;языков коренных
              малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока
            </li>
            <li>
              способствует привлечению талантливой молодежи из&nbsp;числа коренных малочисленных народов Севера, Сибири
              и&nbsp;Дальнего Востока к&nbsp;участию в&nbsp;мероприятиях, направленных на&nbsp;поддержку инициатив и&nbsp;повышение
              социальной активности молодежи
            </li>
            <li>
              содействует укреплению связей между коренными народами Севера, Сибири и&nbsp;Дальнего Востока.
            </li>
          </ul>
        </div>

        <div class="atlas__text mt-3 mt-md-4">
          Реализацию проекта курируют Департамент координации деятельности образовательных организаций Министерства
          науки и&nbsp;высшего образования&nbsp;РФ и&nbsp;Федеральное агентство по&nbsp;делам национальностей.
        </div>

        <div class="atlas__text mt-3 mt-md-4">
          Координаторами совместно выступают Российский государственный гуманитарный университет (РГГУ) и&nbsp;Ассоциация
          коренных малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока&nbsp;РФ (Ассоциация КМНСС и&nbsp;ДВ&nbsp;РФ).
        </div>

        <div class="mt-4 mt-md-5">
          <div class="atlas__sub-title">
            Народы и&nbsp;языки
          </div>
          <div class="atlas__text mt-3 mt-md-4">
            На&nbsp;сайте атласа размещены материалы о&nbsp;более чем 30&nbsp;коренных народах и&nbsp;их&nbsp;языках.
            Год за&nbsp;годом материалы дополняются.
          </div>
          <div class="atlas__text mt-2">
            Описание каждого народа включает: краткую историческую справку, информацию об&nbsp;образе жизни,
            демографические, социально-экономических данные, информацию о&nbsp;материальной и&nbsp;духовной культуре,
            современной культуре и&nbsp;творчестве, декоративно-прикладном искусстве, аудио-, видеоматериалы, фотографии
            и&nbsp;инфографику.
          </div>
        </div>

        <div class="mt-4 mt-md-5">
          <div class="atlas__sub-title">
            Картография
          </div>
          <img class="mt-3" src="/static/img/atlas/image-2.svg">
          <div class="atlas__text mt-2">
            На&nbsp;сайте атласа размещено несколько представлений карт с&nbsp;различной информацией о&nbsp;территориях
            традиционного проживания коренных народов.
          </div>
        </div>

        <div class="mt-4 mt-md-5">
          <div class="atlas__sub-title">
            Онлайн-курс, содержащий материалы Атласа
          </div>
          <img class="mt-3" src="/static/img/atlas/image-3.svg">
          <div class="atlas__text mt-2">
            Скоро на&nbsp;платформе Snoword появится онлайн-курс, содержащий материалы Атласа. Онлайн-курс посвящен
            вопросам истории культуры коренных малочисленных народов Севера, Сибири и&nbsp;Дальнего Востока Российской
            Федерации. Старт обучения запланирован на&nbsp;весну 2024&nbsp;г.
          </div>
        </div>

        <div class="atlas__copyright mt-4 mt-md-5">
          Изображения взяты с сайта <a href="https://atlaskmns.ru/" target="_blank">atlaskmns.ru</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import SwAssetsPageHeader from '../../components/page/AssetsPageHeader';
import LoadingData from '../../mixins/LoadingData';
import ScrollTo from '../../mixins/ScrollTo';

export default {
  name: 'SwAtlasView',
  components: {SwAssetsPageHeader}
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.atlas {
  min-height: calc(100vh - 80px - 320px);

  img {
    width: 100%;
  }

  .atlas__title {
    font-size: 2.5rem;
    line-height: 3rem;

    @media screen and (max-width: 1280px) {
      line-height: 2.5rem;
      @include fluid-font-size(48rem, 80rem, 1.75rem, 2.5rem);
    }
  }

  .atlas__text {
    font-size: 1.5rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.25rem, 1.5rem);
    }
  }

  .atlas__copyright {
    font-size: 1.25rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1rem, 1.25rem);
    }
  }

  .atlas__sub-text {
    font-size: 1.5rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.25rem, 1.5rem);
    }
  }

  .atlas__sub-title {
    font-size: 2.25rem;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.5rem, 2.25rem);
    }
  }

  .atlas__list {
    li {
      margin-top: 20px;

      @media screen and (max-width: 769px) {
        margin-top: 10px;
      }
    }
  }
}
</style>
