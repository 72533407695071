<template>
  <section
    id="posts"
    class="row w-100 justify-content-center"
  >
    <div
      v-if="tagList"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.post.posts') }}
        </template>
      </sw-front-page-block-header>
      <tag-list
        :tag-list="tagList"
        :curr-tag="currTag"
        component-key="blog-post-card-list"
        @change:tag="setCurrTag($event)"
      />
      <div
        v-if="postCardList"
        class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3"
      >
        <div
          v-for="postCard in postCardList"
          :key="`blog-post-col-card-${postCard.slug}`"
          class="col sw-card-list__col"
        >
          <sw-blog-post-card
            :key="`blog-post-card-${postCard.slug}`"
            :post-card="postCard"
            @change:tag="setCurrTag($event)"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setPostCardList()"
      />
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../../page/FrontPageBlockHeader'
import TagList from '../../tag/TagList'
import Tag from '../../../classes/tag/Tag'
import Pagination from '../../../classes/pagination/Pagination'
import SwShowMoreBtn from '../../page/control/ShowMoreBtn'
import SwBlogPostCard from './BlogPostCard'

export default {
  name: 'SwBlogPostCardList',
  components: { SwBlogPostCard, SwShowMoreBtn, SwFrontPageBlockHeader, TagList },

  props: {
    /**
     * Тег для которого выбрать посты
     * В качестве параметра используется slug тега
     */
    tagSlug: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      pageSize: 6
    }
  },

  computed: {
    /** @returns String */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    /** @returns Pagination */
    pagination () {
      return this.$store.getters['postCards/getPagination']
    },

    /** @return BlogPostCard[] */
    postCardList () {
      return this.$store.getters['postCards/getPostCards']
    },

    /** @return Tag[] */
    tagList () {
      return this.$store.getters['postTag/getTags']
    },

    /** @return Tag */
    currTag () {
      return this.$store.getters['postTag/getCurrentTag']
    },

    /** @return {String} */
    currTagSlug () {
      return this.currTag instanceof Tag ? this.currTag.slug : null
    },

    /**
     * Еще доступны трибуны для получения?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Ткекущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    /**
     * Слежение за изменением языка
     * При изменении необходимо загрузить карточки, которые добавлены для данного языка
     */
    language () {
      this.$store.dispatch('postTag/fetchTags')
      this.setPostCardList()
    },

    currTagSlug () {
      if (this.tagList.length !== 0) {
        this.$store.dispatch('postCards/resetPostCards')
        this.setPostCardList()
      }
    }
  },

  mounted () {
    if (this.tagList.length === 0) {
      this.$store.dispatch('postTag/fetchTags')
    }
    if (this.postCardList.length === 0) {
      this.setPostCardList()
    }
  },

  methods: {
    setPostCardList () {
      this.$store.dispatch('postCards/findPostCards', this.postCardListSearchParams())
    },

    postCardListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      params.append('include', 'tags')
      if (this.currTagSlug) {
        params.append('filter[tag_slug]', this.currTagSlug)
      }
      return params
    },

    /**
     * Установить текущий тег
     * @param {Tag} tag
     */
    setCurrTag (tag) {
      if (this.currTag === tag) {
        this.$store.dispatch('postTag/resetCurrentTag')
      } else {
        this.$store.dispatch('postTag/setCurrentTag', tag)
      }
    }
  }
}
</script>
