<template>
  <section
    id="platforms"
    class="row w-100 justify-content-center"
  >
    <div class="frontpage-block">
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.platform.platforms') }}
        </template>
      </sw-front-page-block-header>
      <div class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3">
        <div class="col sw-card-list__col">
          <div class="sw-card sw-card_padding_b sw-card_platform sw-card_platform_sw">
            <div class="sw-card_platform__header" />
            <div class="sw-card_platform__body">
              <div class="sw-card__title-text sw-card__title-text_bold sw-card__title-text_dark sw-card__title-text_h-100">
                {{ $t('message.platform.sw') }}
              </div>
              <a href="https://edu.snoword.ru">
                <div class="sw-btn sw-btn_b sw-btn_secondary sw-btn_secondary_gray_hover-white sw-btn_arrow_gray sw-btn_bold sw-btn_md_w_100">
                  {{ $t('message.go_to') }}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col sw-card-list__col">
          <div class="sw-card sw-card_padding_b sw-card_platform sw-card_platform_arctic">
            <div class="sw-card_platform__header" />
            <div class="sw-card_platform__body">
              <div class="sw-card__title-text sw-card__title-text_bold sw-card__title-text_light sw-card__title-text_h-100">
                {{ $t('message.platform.arctic') }}
              </div>
              <a href="http://arctic.sfu-kras.ru/">
                <div class="sw-btn sw-btn_b sw-btn_secondary sw-btn_secondary_white_hover-white sw-btn_arrow_light sw-btn_bold sw-btn_md_w_100">
                  {{ $t('message.go_to') }}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col sw-card-list__col">
          <div class="sw-card sw-card_padding_b sw-card_platform sw-card_platform_sfu">
            <div class="sw-card_platform__header" />
            <div class="sw-card_platform__body">
              <div class="sw-card__title-text sw-card__title-text_bold sw-card__title-text_light sw-card__title-text_h-100">
                {{ $t('message.platform.sfu') }}
              </div>
              <a href="https://sfu-kras.ru/">
                <div class="sw-btn sw-btn_b sw-btn_secondary sw-btn_secondary_white_hover-white sw-btn_arrow_light sw-btn_bold sw-btn_md_w_100">
                  {{ $t('message.go_to') }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader'
export default {
  name: 'SwPlatformLinks',
  components: { SwFrontPageBlockHeader }
}
</script>

<style lang="scss">
.sw-card_platform {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 304px;
  max-width: 400px;

  @media screen and (min-width: 569px) and (max-width: 1280px) {
    width: 98%;
  }

  @media screen and (max-width: 768px) {
    height: 248px;
  }

  .sw-card_platform__header {
    display: flex;
    content: '';
    height: 88px;
    min-height: 88px;
    border-bottom: 0.5px solid;
    background-repeat: no-repeat;
    background-position: left top;
    background-origin: content-box;

    @media screen and (max-width: 768px) {
      height: 72px;
      min-height: 72px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 60px;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 768px) {
        height: 48px;
      }
    }
  }

  .sw-card_platform__body {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;

    @media screen and (max-width: 992px) {
      margin-top: 17px;
    }
  }

  &.sw-card_platform_sw {
    background-color: var(--platform-card-sw-bg);

    .sw-card_platform__header {
      border-color: var(--light-border);

      &::before {
        background-image: url("/static/img/platform/sw-logo.svg");
      }
    }

    .sw-card_platform__body {
      color: var(--text-main);
    }
  }

  &.sw-card_platform_arctic {
    background: var(--platform-card-arctic-bg);

    .sw-card_platform__header {
      border-color: var(--light-white-border);

      &::before {
        background-image: url("/static/img/platform/arctic-logo.svg");
      }
    }

    .sw-card_platform__body {
      color: var(--text-white);
    }
  }

  &.sw-card_platform_sfu {
    background: var(--platform-card-sfu-bg);

    .sw-card_platform__header {
      border-color: var(--light-white-border);

      &::before {
        background-image: url("/static/img/platform/sfu-logo.svg");
      }
    }

    .sw-card_platform__body {
      color: var(--text-white);
    }
  }
}
</style>
