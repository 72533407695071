<template>
  <section class="navigator component-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
          content-id="navigator"
          :title="$t('message.navigator.career_navigator')"
      />
    </div>
    <div class="content-view">
      <div class="content-view__body">
        <div class="navigator__description  mt-3">
          <div>
            В&nbsp;тесте мы&nbsp;предлагаем оценить ряд утверждений о&nbsp;различных видах занятий, которые вы&nbsp;сможете освоить на&nbsp;нашем сайте.
          </div>
          <div class="mt-3">
            Выбирайте ответ в&nbsp;зависимости от&nbsp;того, насколько сильно вам нравится или не&nbsp;нравится это занятие, хочется или не&nbsp;хочется им&nbsp;заниматься.
          </div>
          <div class="mt-3">
            Здесь нет правильных или не&nbsp;правильных ответов, не&nbsp;размышляйте слишком долго над каждым вопросом, доверьтесь интуиции.
          </div>
        </div>

        <div class="mt-5">
          <div
              class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_blue"
              @click="$router.push({name: 'navigatorTestView' })"
          >
            {{ $t('message.navigator.start_test') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../../components/page/AssetsPageHeader'

export default {
  name: 'SwNavigatorView',
  components: {SwAssetsPageHeader},

  computed: {
    hasResult () {
      return this.$store.getters['navigator/hasResult']
    }
  },

  mounted () {
    if (this.hasResult) {
      this.$router.push({name: 'navigatorTestView'})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../sass/utils";

.navigator {
  .navigator__title {
    font-size: 1.375rem;
    font-weight: bold;
  }

  .navigator__description {
    font-size: 1.5rem;
  }
}
</style>
