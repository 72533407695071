<template>
  <el-backtop
    :bottom="100"
    :right="position"
  >
    <div class="el-backtop__button" />
  </el-backtop>
</template>

<script>
export default {
  name: 'SwPageBackTop',

  data () {
    return {
      clientWidth: 0
    }
  },

  computed: {
    position () {
      const position = Math.floor((this.clientWidth - 1232) / 2)
      return position < 25 ? 25 : position
    }
  },

  mounted () {
    this.clientWidth = document.body.clientWidth
    window.addEventListener('resize', () => {
      this.clientWidth = document.body.clientWidth
    })
  },

  methods: {
    getPosition () {
      const position = Math.floor((this.clientWidth - 1232) / 2)
      return position < 25 ? 25 : position
    }
  }
}
</script>

<style lang="scss" scoped>
.el-backtop {
  width: 80px;
  height: 72px;
  box-shadow: none;
  background: transparent;
  border-radius: 24px;

  &:hover {
    background: rgba(237, 107, 6, 0.05);
  }

  .el-backtop__button {
    content: '';
    width: 80px;
    height: 72px;
    background: url("/static/img/icon/backtop.svg") no-repeat center;

    &:hover {
      background-image: url("/static/img/icon/backtop-hover.svg");
    }
  }
}
</style>
