<template>
  <section
    id="events"
    class="row w-100 justify-content-center"
  >
    <div
      v-if="tagList"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.event.events') }}
        </template>
      </sw-front-page-block-header>
      <sw-blog-event-card-list-menu />
      <tag-list
        :tag-list="tagList"
        :curr-tag="currTag"
        component-key="blog-event-card-list"
        @change:tag="setCurrTag($event)"
      />
      <div
        v-if="eventCardList"
        class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3"
      >
        <div
          v-for="eventCard in eventCardList"
          :key="`blog-event-col-card-${eventCard.slug}`"
          class="col sw-card-list__col"
        >
          <sw-blog-event-card
            :key="`blog-event-card-${eventCard.slug}`"
            :event-card="eventCard"
            @change:tag="setCurrTag($event)"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setEventCardList()"
      />
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../../page/FrontPageBlockHeader'
import TagList from '../../tag/TagList'
import Tag from '../../../classes/tag/Tag'
import BlogEventCard from '../../../classes/blog/event/BlogEventCard'
import Pagination from '../../../classes/pagination/Pagination'
import SwShowMoreBtn from '../../page/control/ShowMoreBtn'
import SwBlogEventCard from './BlogEventCard'
import SwBlogEventCardListMenu from './BlogEventCardListMenu'

export default {
  name: 'SwBlogEventCardList',
  components: { SwBlogEventCardListMenu, SwBlogEventCard, SwShowMoreBtn, SwFrontPageBlockHeader, TagList },

  props: {
    /**
     * Тег для которого выбрать события
     * В качестве параметра используется slug тега
     */
    tagSlug: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      pageSize: 3,
      btnHeight: '100%'
    }
  },

  computed: {
    /** @returns String */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    /** @returns String */
    eventCardsState () {
      return this.$store.getters['eventCards/getEventCardsState']
    },

    /** @returns Pagination */
    pagination () {
      return this.$store.getters['eventCards/getPagination']
    },

    /** @return BlogEventCard[] */
    eventCardList () {
      return this.$store.getters['eventCards/getEventCards']
    },

    /** @return Tag[] */
    tagList () {
      return this.$store.getters['eventTag/getTags']
    },

    /** @return Tag */
    currTag () {
      return this.$store.getters['eventTag/getCurrentTag']
    },

    /** @return {String} */
    currTagSlug () {
      return this.currTag instanceof Tag ? this.currTag.slug : null
    },

    /**
     * Еще доступны события для вывода?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Текущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    /**
     * Слежение за изменением языка
     * При изменении необходимо загрузить карточки, которые добавлены для данного языка
     */
    language () {
      this.$store.dispatch('eventCards/setEventCardsState', 'current')
    },

    currTagSlug () {
      if (this.eventCardsState !== 'undefined' && this.tagList.length !== 0) {
        this.$store.dispatch('eventCards/resetEventCards')
        this.setEventCardList()
      }
    },

    eventCardsState () {
      /**
       * Именно такой вызов с дублем сохранения в storage
       * Иначе будет вызов изменения currTagSlug и дубль запросов
       */
      if (this.eventCardsState !== 'undefined') {
        if (this.currTagSlug) {
          this.refreshTagList()
        } else {
          this.refreshTagList()
          this.$store.dispatch('eventCards/resetEventCards')
          this.setEventCardList()
        }
      }
    }
  },

  beforeCreate () {
    this.$store.dispatch('eventCards/setEventCardsState', 'current')
  },

  mounted () {
    if (this.tagList.length === 0) {
      this.$store.dispatch('eventTag/fetchTags', this.eventTagListSearchParams())
    }
    if (this.eventCardList.length === 0) {
      this.setEventCardList()
    }
  },

  methods: {
    setEventCardList () {
      this.$store.dispatch('eventCards/findEventCards', this.eventCardListSearchParams())
    },

    refreshTagList () {
      this.$store.dispatch('eventTag/reset')
      this.$store.dispatch('eventTag/fetchTags', this.eventTagListSearchParams())
    },

    eventTagListSearchParams () {
      const params = new URLSearchParams()
      params.append('filter[state]', this.eventCardsState)
      return params
    },

    eventCardListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      params.append('filter[state]', this.eventCardsState)
      params.append('include', 'tags,albums')
      if (this.currTagSlug) {
        params.append('filter[tag_slug]', this.currTagSlug)
      }
      return params
    },

    /**
     * Установить текущий тег
     * @param {Tag} tag
     */
    setCurrTag (tag) {
      if (this.currTag === tag) {
        this.$store.dispatch('eventTag/resetCurrentTag')
      } else {
        this.$store.dispatch('eventTag/setCurrentTag', tag)
      }
    }
  }
}
</script>
