<template>
  <section>
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
        content-id="error"
        :title="$t('errors.not_found')"
        :show-title="false"
      />
    </div>
    <div class="content-view">
      <div class="content-view__body">
        <div class="page-not-found">
          <div class="page-not-found__icon" />
          <div class="page-not-found__error">
            {{ $t('errors.error') }} <span class="page-not-found__error-number">404</span>
          </div>
          <div class="page-not-found__error-description">
            {{ $t('errors.not_found') }}
          </div>
          <div
            class="sw-btn sw-btn_b sw-btn_secondary sw-btn_secondary_gray sw-btn_arrow_gray sw-btn_bold"
            @click="$router.push({name: 'frontpage'})"
          >
            {{ $t('message.go_to_home_page') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../components/page/AssetsPageHeader'
export default {
  name: 'PageNotFoundView',
  components: { SwAssetsPageHeader }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 568px) {
    margin-top: 91px;
  }

  .page-not-found__error {
    margin-top: 44px;
    font-size: 3.5rem;
    line-height: 3.5rem;

    @media screen and (max-width: 768px) {
      margin-top: 40px;
      font-size: 1.75rem;
      font-weight: bold;
    }

    @media screen and (max-width: 568px) {
      margin-top: 48px;
    }

    .page-not-found__error-number {
      color: var(--error-num);
    }
  }

  .page-not-found__error-description {
    margin-top: 13px;
    margin-bottom: 87px;
    font-size: 1.5rem;
    color: var(--text-main);
    opacity: 0.6;

    @media screen and (max-width: 768px) {
      margin-top: 7px;
      font-size: 1.13rem;
      margin-bottom: 49px;
    }

    @media screen and (max-width: 568px) {
      margin-bottom: 49px;
    }
  }

  .page-not-found__icon {
    display: block;
    content: '';
    width: 256px;
    height: 256px;
    background-color: var(--error-page-not-found-bg);
    background-image: url("/static/img/icon/broken-document.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 128px;
  }
}
</style>
