<template>
  <div
    :id="`blog-tribune-${tribuneCard.slug}`"
    class="sw-card sw-card_tribune"
  >
    <div
      class="sw-card__featured-image sw-card__featured-image_clickable"
      :style="{ backgroundImage: cardBgImage }"
      @click="$router.push({name: 'blogTribuneView', params: { tribuneIdentifier: tribuneCard.slug }})"
    >
      <div class="date-block date-block_s">
        {{ tribuneCard.getLocaleInterviewDate(languageCode) }}
      </div>
      <div class="sw-card__title-text sw-card__title-text_light sw-card__title-text_bold">
        {{ tribuneCard.title }}
      </div>
    </div>
    <div class="sw-card_tribune__author">
      <div
        class="sw-card_tribune__author-photo"
        :style="{ backgroundImage: cardAuthorPhoto }"
      />
      <div>
        <div class="sw-card_tribune__author-name">
          {{ tribuneCard.authorFullName }}
        </div>
        <div class="sw-card_tribune__author-about">
          {{ tribuneCard.authorAbout }}
        </div>
      </div>
    </div>
    <div
      class="sw-card_tribune__excerpt"
      v-html="tribuneCard.excerpt"
    />
    <sw-selected-tag-list
      v-if="tribuneCard.tagList.length"
      :component-key="`blog-tribune-card-${tribuneCard.slug}`"
      :tag-list="tribuneCard.tagList"
      @change:tag="$emit('change:tag', $event)"
    />
  </div>
</template>
<script>

import SwSelectedTagList from '../../tag/SelectedTagList'
import BlogTribuneCard from '../../../classes/blog/tribune/BlogTribuneCard'

export default {
  name: 'SwBlogTribuneCard',
  components: { SwSelectedTagList },
  props: {
    tribuneCard: {
      type: BlogTribuneCard,
      required: true
    }
  },
  emits: ['change:tag'],

  computed: {
    cardBgImage () {
      return `linear-gradient(180deg, var(--blog-tribune-card-main-image-start-gradient) 0%,
        var(--blog-tribune-card-main-image-end-gradient) 100%),
        url('${this.tribuneCard.featuredImage}')`
    },

    cardAuthorPhoto () {
      return `url('${this.tribuneCard.authorPhotoThumbnailUrl()}'`
    },

    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.sw-card_tribune {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media screen and (min-width: 569px) and (max-width: 1280px) {
    width: 98%;
  }

  @media screen and (max-width: 768px) {
    max-width: 351px;
  }

  .sw-card__featured-image {
    height: 216px;

    @media screen and (max-width: 768px) {
      height: 192px;
    }
  }

  .sw-card_tribune__excerpt,
  .sw-card_tribune__author {
    padding: 0 8px;
  }

  .sw-card_tribune__author {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 14px;

    .sw-card_tribune__author-photo {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
    }

    .sw-card_tribune__author-name {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: var(--blog-tribune-card-author-name);
    }

    .sw-card_tribune__author-about {
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--blog-tribune-card-author-about);
    }
  }

  .sw-card_tribune__excerpt {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: var(--blog-tribune-card-excerpt);

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 0.75rem, 0.9375rem);
    }

    @media screen and (max-width: 768px) {
      line-height: 1rem;
    }
  }
}
/*.blog-tribune-card__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  margin-bottom: 27px;

  .blog-tribune-card__body {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    max-width: 400px;
    min-height: 288px;
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;
    cursor: pointer;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .blog-tribune-card__title {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--blog-tribune-card-title);

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  .blog-tribune-card__author,
  .blog-tribune-card__excerpt {
    width: 100%;
    max-width: 400px;
    align-self: center;
    padding: 12px 8px 0 8px;
  }

  .blog-tribune-card__author {
    display: flex;
    flex-direction: row;

    .blog-tribune-card__author-photo {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      margin-right: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
    }

    .blog-tribune-card__author-name {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: var(--blog-tribune-card-author-name);
    }

    .blog-tribune-card__author-about {
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--blog-tribune-card-author-about);
    }
  }

  .blog-tribune-card__excerpt {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: var(--blog-tribune-card-excerpt);

    @media screen and (max-width: 768px) {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}*/
</style>
