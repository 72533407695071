<template>
  <div class="team-member">
    <el-image
      :src="member.getThumbnailPhotoUrl()"
      class="team-member__photo"
      lazy
    />
    <div class="team-member__info">
      <div class="team-member__full-name">
        {{ member.getFullName(language) }}
      </div>
      <div
        class="team-member__about"
        v-html="member.about"
      />
      <div
        v-if="member.contactUrl"
        class="team-member__contact"
      >
        <a
          :href="member.contactUrl"
          target="_blank"
        >
          {{ $t('message.team.member.contacts') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TeamMember from '../../classes/team/TeamMember'

export default {
  name: 'SwTeamMember',

  props: {
    member: {
      type: TeamMember,
      required: true
    }
  },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.team-member {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
  color: var(--text-main);

  @media screen and (max-width: 1280px) {
    margin-bottom: 5.47vw;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 35px;
  }

  @media screen and (max-width: 568px) {
    margin-bottom: 27px;
  }

  .team-member__photo {
    display: block;
    content: '';
    width: 160px;
    height: 160px;
    border-radius: 40px;

    @media screen and (max-width: 1280px) and (min-width: 769px) {
      width: 12.5vw;
      height: 12.5vw;
      min-width: 12.5vw;
      min-height: 12.5vw;
      border-radius: 3.16vw;
    }

    @media screen and (max-width: 768px) {
      width: 72px;
      height: 72px;
      min-width: 72px;
      min-height: 72px;
      border-radius: 24px;
    }
  }

  .team-member__info {
    max-width: 407px;
    padding-right: 10px;
    padding-left: 24px;

    @media screen and (max-width: 1280px) {
      padding-left: 1.88vw;
    }

    @media screen and (max-width: 768px) {
      padding-left: 16px;
    }

    .team-member__full-name {
      font-size: 1.25rem;
      line-height: 1.5rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.94rem, 1.25rem);
      }

      @media screen and (max-width: 768px) {
        font-weight: bold;
        line-height: 1.25rem;
      }
    }

    .team-member__about,
    .team-member__contact {
      margin-top: 16px;
      @media screen and (max-width: 1280px) {
        margin-top: 1.25vw;
      }

      @media screen and (max-width: 768px) {
        margin-top: 7px;
      }
    }

    .team-member__about {
      font-size: 0.94rem;
      opacity: 0.5;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 0.94rem);
      }
    }

    .team-member__contact {
      font-weight: bold;
      font-size: 0.86rem;
      opacity: 0.6;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.75rem, 0.86rem);
      }
    }
  }
}
</style>
