<template>
  <section
    id="tribunes"
    class="row w-100 justify-content-center"
  >
    <div
      v-if="tagList"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.tribune.tribunes') }}
        </template>
      </sw-front-page-block-header>
      <tag-list
        :tag-list="tagList"
        :curr-tag="currTag"
        component-key="blog-tribune-card-list"
        @change:tag="setCurrTag($event)"
      />
      <div
        v-if="tribuneCardList"
        class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3"
      >
        <div
          v-for="tribuneCard in tribuneCardList"
          :key="`blog-tribune-col-card-${tribuneCard.slug}`"
          class="col sw-card-list__col"
        >
          <sw-blog-tribune-card
            :key="`blog-tribune-card-${tribuneCard.slug}`"
            :tribune-card="tribuneCard"
            @change:tag="setCurrTag($event)"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setTribuneCardList()"
      />
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../../page/FrontPageBlockHeader'
import TagList from '../../tag/TagList'
import Tag from '../../../classes/tag/Tag'
import Pagination from '../../../classes/pagination/Pagination'
import SwShowMoreBtn from '../../page/control/ShowMoreBtn'
import SwBlogTribuneCard from './BlogTribuneCard'

export default {
  name: 'SwBlogTribuneCardList',
  components: { SwBlogTribuneCard, SwShowMoreBtn, SwFrontPageBlockHeader, TagList },

  props: {
    /**
     * Тег для которого выбрать события
     * В качестве параметра используется slug тега
     */
    tagSlug: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      pageSize: 6
    }
  },

  computed: {
    /** @returns String */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    /** @returns Pagination */
    pagination () {
      return this.$store.getters['tribuneCards/getPagination']
    },

    /** @return BlogTribuneCard[] */
    tribuneCardList () {
      return this.$store.getters['tribuneCards/getTribuneCards']
    },

    /** @return Tag[] */
    tagList () {
      return this.$store.getters['tribuneTag/getTags']
    },

    /** @return Tag */
    currTag () {
      return this.$store.getters['tribuneTag/getCurrentTag']
    },

    /** @return {String} */
    currTagSlug () {
      return this.currTag instanceof Tag ? this.currTag.slug : null
    },

    /**
     * Еще доступны трибуны для получения?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Ткекущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    /**
     * Слежение за изменением языка
     * При изменении необходимо загрузить карточки, которые добавлены для данного языка
     */
    language () {
      this.$store.dispatch('tribuneTag/fetchTags')
      this.setTribuneCardList()
    },

    currTagSlug () {
      if (this.tagList.length !== 0) {
        this.$store.dispatch('tribuneCards/resetTribuneCards')
        this.setTribuneCardList()
      }
    }
  },

  mounted () {
    if (this.tagList.length === 0) {
      this.$store.dispatch('tribuneTag/fetchTags')
    }
    if (this.tribuneCardList.length === 0) {
      this.setTribuneCardList()
    }
  },

  methods: {
    setTribuneCardList () {
      this.$store.dispatch('tribuneCards/findTribuneCards', this.tribuneCardListSearchParams())
    },

    tribuneCardListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      params.append('include', 'tags')
      if (this.currTagSlug) {
        params.append('filter[tag_slug]', this.currTagSlug)
      }
      return params
    },

    /**
     * Установить текущий тег
     * @param {Tag} tag
     */
    setCurrTag (tag) {
      if (this.currTag === tag) {
        this.$store.dispatch('tribuneTag/resetCurrentTag')
      } else {
        this.$store.dispatch('tribuneTag/setCurrentTag', tag)
      }
    }
  }
}
</script>
