<template>
  <div
    class="content-view__section"
    :class="{'content-view__section_margin-bottom': marginBottom}"
  >
    <div
      v-if="hasHeader"
      class="content-view__section-title"
    >
      <header>
        <slot name="header" />
      </header>
    </div>
    <div
      v-if="hasMain"
      class="content-view__section-body"
    >
      <main>
        <slot name="main" />
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwContentPageSection',

  props: {
    marginBottom: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasHeader () {
      return !!this.$slots.header
    },

    hasMain () {
      return !!this.$slots.main
    }
  }
}
</script>
