/**
 * Прокрутка до элемента
 * @type {{methods: {scrollTo(String, Number=): void}}}
 */
const ScrollTo = {
  methods: {
    /**
     * Прокрутка до элемента
     * @param {String} elementId идентификатор элемента
     * @param {Number} yOffset отсутп сверху px
     */
    scrollTo (elementId, yOffset = 0) {
      const element = document.querySelector(`#${elementId}`)
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }
}
export default ScrollTo
