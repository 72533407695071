<template>
  <div
    v-if="num > 0"
    class="show-more-btn"
    :style="{height}"
    @click="$emit('show:more')"
  >
    {{ $t('message.show_more', { num: num }) }}
  </div>
</template>

<script>
export default {
  name: 'SwShowMoreBtn',

  props: {
    num: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: '64px'
    }
  },
  emits: ['show:more']

}
</script>

<style lang="scss" scoped>
.show-more-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 35px;
  background-color: var(--show-more-btn-bg);
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--show-more-btn);
  border-radius: 24px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }

  &:hover {
    background-color: var(--show-more-btn-hover-bg);
  }

  &:active {
    background-color: var(--show-more-btn-active-bg);
    color: var(--show-more-btn-active);
  }
}
</style>
