<template>
  <div
    v-if="memberList.length"
    :id="structure.slug"
    class="team-structure"
  >
    <sw-page-chapter-title>
      <template #main>
        {{ structure.title }}
      </template>
      <template #sub>
        <div v-html="structure.description" />
      </template>
    </sw-page-chapter-title>
    <template v-if="memberList && pagination">
      <sw-team-member-list
        :member-list="memberList"
        :count-more-data="pagination.countMoreData()"
        :has-more-data="hasMoreData"
        @show:more="getMemberList()"
      />
    </template>
  </div>
</template>

<script>
import TeamStructure from '../../classes/team/TeamStructure'
import TeamMember from '../../classes/team/TeamMember'
import SwPageChapterTitle from '../page/PageChapterTitle'
import ApiReadClient from '../../classes/api/ApiReadClient'
import SwError from '../../classes/error/Error'
import Pagination from '../../classes/pagination/Pagination'
import SwTeamMemberList from './TeamMemberList'

export default {
  name: 'SwTeamStructure',
  components: { SwTeamMemberList, SwPageChapterTitle },

  props: {
    structure: {
      type: TeamStructure,
      required: true
    }
  },

  data () {
    return {
      /** @type TeamMember[] */
      memberList: [],
      pageSize: 500,
      /** @type Pagination */
      pagination: null
    }
  },

  computed: {
    /**
     * Текущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    },

    /**
     * Еще доступны события для вывода?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    }
  },

  created () {
    this.getMemberList()
  },

  methods: {
    async getMemberList () {
      const params = this.memberListSearchParams()
      const apiReadClient =
        new ApiReadClient(
          `/api/team/structures/${this.structure.slug}/members`,
          { params },
          'TeamMember'
        )
      await apiReadClient.execute()
      if (apiReadClient.hasError()) {
        for (const error of apiReadClient.errors) {
          this.$store.dispatch('error/addError', new SwError(error), { root: true })
        }
      } else {
        for (const member of apiReadClient.data) {
          this.memberList.push(member)
        }
        this.pagination = apiReadClient.pagination
      }
    },

    memberListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      return params
    }
  }
}
</script>

<style lang="scss">
.team-structure {
  max-width: 1232px;
  padding-top: 70px;

  @media screen and (max-width: 1280px) {
    padding-top: 5.47vw;
  }

  @media screen and (max-width: 768px) {
    padding-top: 38px;
  }

  .team-structure__get-more {
    height: 160px;

    @media screen and (max-width: 1280px) {
      height: 12.5vw;
    }

    @media screen and (max-width: 768px) {
      height: 72px;
      margin-bottom: 38px;
    }
  }
}
</style>
