<template>
  <div class="page-indicator-list">
    <div class="row no-gutters justify-content-between justify-content-lg-center">
      <div class="col-3 col-xs-2 d-flex justify-content-around align-items-start">
        <div class="page-indicator">
          <div class="page-indicator__num">
            {{ indicatorList[0].num }}
          </div>
          <div class="page-indicator__title">
            {{ indicatorList[0].title }}
          </div>
        </div>
      </div>
      <div class="col-1 p-0 d-none d-xs-flex justify-content-around align-items-start">
        <div class="page-indicator-delimiter" />
      </div>
      <div class="col-3 col-xs-2 d-flex justify-content-around align-items-start">
        <div class="page-indicator">
          <div class="page-indicator__num">
            {{ indicatorList[1].num }}
          </div>
          <div class="page-indicator__title">
            {{ indicatorList[1].title }}
          </div>
        </div>
      </div>
      <div class="col-1 p-0 d-none d-xs-flex justify-content-around align-items-start">
        <div class="page-indicator-delimiter" />
      </div>
      <div class="col-3 col-xs-2 d-flex justify-content-around align-items-start">
        <div class="page-indicator">
          <div class="page-indicator__num">
            {{ indicatorList[2].num }}
          </div>
          <div class="page-indicator__title">
            {{ indicatorList[2].title }}
          </div>
        </div>
      </div>
      <div class="col-1 p-0 d-none d-xs-flex justify-content-around align-items-start">
        <div class="page-indicator-delimiter" />
      </div>
      <div class="col-3 col-xs-2 d-flex justify-content-around align-items-start">
        <div class="page-indicator">
          <div class="page-indicator__num">
            {{ indicatorList[3].num }}
          </div>
          <div class="page-indicator__title">
            {{ indicatorList[3].title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwPageIndicators',

  props: {
    indicatorList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.page-indicator-list {
  width: 100%;
  max-width: 1232px;
  padding-bottom: 45px;

  .page-indicator-delimiter {
    content: '';
    width: 72px;
    height: 72px;
    background: url("/static/img/icon/spruce.svg") no-repeat;
    background-size: contain;

    @media screen and (max-width: 992px) {
      width: 7.26vw;
      height: 7.26vw;
      margin-top: 1vw;
    }
  }

  .page-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }

    .page-indicator__title {
      color: var(--page-indicator-title);
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.625rem, 1.25rem);
        line-height: 1rem;
      }
    }

    .page-indicator__num {
      color: var(--page-indicator-num);
      font-size: 3.5rem;
      line-height: 3.5rem;
      font-weight: bold;
      text-align: center;
      letter-spacing: -0.03em;
      font-feature-settings: 'kern' off;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1.75rem, 3.5rem);
      }
    }
  }
}
</style>
