<template>
  <section
    id="partners"
    class="row w-100 justify-content-center"
  >
    <div
      v-if="partnerList"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.partner.partners') }}
        </template>
      </sw-front-page-block-header>
      <div class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3">
        <div
          v-for="partner in partnerList"
          :key="`partner-col-${partner.id}`"
          class="col sw-card-list__col"
        >
          <sw-partner
            :key="`partner-card-${partner.id}`"
            :partner="partner"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setPartnerList()"
      />
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader'
import SwShowMoreBtn from '../page/control/ShowMoreBtn'
import Pagination from '../../classes/pagination/Pagination'
import SwPartner from './Partner'
export default {
  name: 'SwPartnerList',
  components: { SwPartner, SwShowMoreBtn, SwFrontPageBlockHeader },

  data () {
    return {
      pageSize: 30
    }
  },

  computed: {
    /** @returns {String} */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    /** @returns Pagination */
    pagination () {
      return this.$store.getters['partners/getPagination']
    },

    /** @return Partner[] */
    partnerList () {
      return this.$store.getters['partners/getPartners']
    },

    /**
     * Еще доступны партнеры для получения?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Текущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    language () {
      this.setPartnerList()
    }
  },

  mounted () {
    if (this.partnerList.length === 0) {
      this.setPartnerList()
    }
  },

  methods: {
    setPartnerList () {
      this.$store.dispatch('partners/getPartners', this.partnerListSearchParams())
    },

    partnerListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      return params
    }
  }
}
</script>
