/**
 * Карточка альбома галереи
 *
 * @module Gallery
 * @class GalleryAlbum
 */
import AlbumPhoto from './AlbumPhoto'
import Tag from '../tag/Tag'

class GalleryAlbum {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.excerpt = attributes.excerpt
    this.description = attributes.description
    this.publishedAt = attributes.publised_at
    this.photoList = attributes.photo_list
  }

  /**
   * Идентификатор альбома
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок альбома
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор альбома
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Короткое описание альбома
   * @return {String}
   */
  get excerpt () {
    return this._excerpt
  }

  set excerpt (value) {
    this._excerpt = value
  }

  /**
   * Описание альбома
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * Дата публикации альбома
   * @return {String}
   */
  get publishedAt () {
    return this._publishedAt
  }

  set publishedAt (value) {
    this._publishedAt = value
  }

  /**
   * Список изображений в альбоме
   * @return {AlbumPhoto[]}
   */
  get photoList () {
    return this._photoList
  }

  set photoList (value) {
    this._photoList = []
    for (const photoObj of value) {
      this._photoList.push(new AlbumPhoto(photoObj))
    }
  }

  /**
   * Список тегов, связанных с альбомом
   * @return {Tag[]}
   */
  get tagList () {
    return this._tagList
  }

  setTagList (relatedData) {
    this._tagList = []
    const tagsData = this.relationships?.tags?.data
    if (tagsData) {
      for (const tagObj of tagsData) {
        if (tagObj.type === 'Tag') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof Tag && tagObj.id === relatedObj.id) {
              this._tagList.push(relatedObj)
            }
          }
        }
      }
    }
  }

  /**
   * Связанные объектв
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
    this.setTagList(relatedData)
  }

  /**
   * Изображение по указанному индексу
   * @param {number} index
   * @return {AlbumPhoto|null}
   */
  getPhoto (index) {
    return this._photoList[index] ?? null
  }

  /**
   * По указанному индексу есть изображение?
   * @param {Number} index
   * @return {boolean}
   */
  hasPhoto (index) {
    return !!this._photoList[index]
  }

  /**
   * Количество фото в альбоме
   * @returns {number}
   */
  countPhoto () {
    return this._photoList.length
  }
}

export default GalleryAlbum
