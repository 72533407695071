<template>
  <div
      v-if="isLoadingData"
      v-loading="isLoadingData"
      class="content-waiting-loader"
  />
  <section
      v-if="trajectory"
      :key="`education-trajectory-view-${trajectory.slug}`"
  >
    <div class="education-trajectory-view content-view">
      <div class="row w-100 justify-content-center no-gutters">
        <sw-content-page-header
            :content-id="`education-trajectory-${trajectory.slug}`"
            :section-name="$t('message.education.trajectory.trajectories')"
            :title="trajectory.title"
            :breadcrumbs="breadcrumbs"
            :bg-image-url="trajectory.featuredImage"
        />
      </div>
      <div class="education-trajectory-view__body content-view__body">
        <sw-sticky-top-menu
            :menu-items="stickyTopMenu"
        />
        <sw-content-page-section id="info">
          <template #main>
            <div
                v-for="(infoField,index) in infoFields"
                :key="`trajectory-info-field-${index}`"
                class="education-trajectory-view__info-field"
            >
              <div class="education-trajectory-view__info-field-title">
                {{ infoField.title }}
              </div>
              <div
                  class="education-trajectory-view__info-field-value"
                  v-html="infoField.value"
              />
            </div>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="sign_up">
          <template #main>
            <div
                class="sw-btn sw-btn_b sw-btn_primary"
                @click="signUpToTrajectory()"
            >
              {{ $t('message.education.trajectory.sign_up') }}
            </div>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="description">
          <template #header>
            {{ $t('message.education.trajectory.description') }}
          </template>
          <template #main>
            <div v-html="trajectory.description"/>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="plan">
          <template #header>
            {{ $t('message.education.trajectory.plan') }}
          </template>
          <template #main>
            <div v-html="trajectory.plan"/>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="results">
          <template #header>
            {{ $t('message.education.trajectory.results') }}
          </template>
          <template #main>
            <div v-html="trajectory.results"/>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="courses">
          <template #header>
            {{ $t('message.education.trajectory.courses_short_description') }}
          </template>
          <template #main>
            <div class="education-trajectory-view__courses">
              <div
                  v-for="course in trajectory.educationCourses"
                  class="course row no-gutters d-flex flex-row"
              >
                <div class="course__image col-4">
                  <img :src="course.featuredImage">
                </div>
                <div class="course__about col-7">
                  <div class="course__title">
                    {{ course.title }}
                  </div>
                  <div class="course__excerpt">
                    {{ course.excerpt }}
                  </div>
                  <div
                      class="sw-btn sw-btn_b sw-btn_primary mt-3"
                      @click="goToCourse(course.slug)"
                  >
                    {{ $t('message.more_details') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="documents">
          <template #header>
            {{ $t('message.education.trajectory.documents') }}
          </template>
          <template #main>
            <div v-html="trajectory.documents"/>
          </template>
        </sw-content-page-section>

        <sw-content-page-section id="sign_up">
          <template #main>
            <div
                class="sw-btn sw-btn_b sw-btn_primary"
                @click="signUpToTrajectory()"
            >
              {{ $t('message.education.trajectory.sign_up') }}
            </div>
          </template>
        </sw-content-page-section>

      </div>
    </div>
  </section>
</template>

<script>
import LoadingData from '../../../mixins/LoadingData';
import LocaleForValue from '../../../mixins/LocaleForValue';
import ApiReadClient from '../../../classes/api/ApiReadClient'
import SwError from '../../../classes/error/Error'
import SwContentPageHeader from '../../../components/page/ContentPageHeader.vue'
import SwContentPageSection from '../../../components/page/ContentPageSection.vue'
import SwStickyTopMenu from '../../../components/page/control/StickyTopMenu.vue'

export default {
  name: 'EducationTrajectoryView',
  components: {SwStickyTopMenu, SwContentPageSection, SwContentPageHeader},
  mixins: [LoadingData, LocaleForValue],
  data () {
    return {
      trajectoryIdentifier: null,
      /** @type EducationTrajectory */
      trajectory: null
    }
  },

  computed: {
    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    },

    breadcrumbs () {
      return [
        {
          title: this.$t('message.navigator.navigator'),
          location: {name: 'navigatorView'}
        },
        {
          title: this.$t('message.education.trajectory.trajectories'),
          location: null
        }
      ]
    },

    infoFields () {
      const infoFields = []
      if (this.trajectory) {
        infoFields.push({
          title: this.$t('message.education.trajectory.labor_intensity'),
          value: this.trajectory.laborIntensity
        })
      }
      return infoFields
    },

    stickyTopMenu () {
      const menu = []

      menu.push({name: this.$t('message.education.trajectory.description'), hash: 'description'})
      menu.push({name: this.$t('message.education.trajectory.plan'), hash: 'plan'})
      menu.push({name: this.$t('message.education.trajectory.results'), hash: 'results'})
      menu.push({name: this.$t('message.education.trajectory.courses_short_description'), hash: 'courses'})
      menu.push({name: this.$t('message.education.trajectory.documents'), hash: 'documents'})

      return menu
    }
  },

  created () {
    this.startDataLoading()
    this.trajectoryIdentifier = this.$route.params.trajectoryIdentifier ?? null
    this.fetchTrajectory()
  },

  methods: {
    async fetchTrajectory () {
      const params = new URLSearchParams()
      params.append('include', 'educationCourses')
      const apiReadClient =
          new ApiReadClient(
              `/api/education/trajectories/${this.trajectoryIdentifier}`,
              {params},
              'EducationTrajectory'
          )

      await apiReadClient.execute()

      if (apiReadClient.hasError()) {
        if (apiReadClient.isPageNotFound()) {
          this.$router.push({name: 'pageNotFound'})
        } else {
          for (const error of apiReadClient.errors) {
            this.$store.dispatch('error/addError', new SwError(error), {root: true})
          }
        }
      } else {
        this.trajectory = apiReadClient.getFirst()
      }
      this.dataLoaded()
    },

    signUpToTrajectory () {
      window.location.href = this.trajectory.eCourseLink
    },

    goToCourse (courseSlug) {
      this.$router.push({
        name: 'trajectoryEducationCourseView',
        params: {
          trajectorySlug: this.trajectory.slug,
          courseSlug: courseSlug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.education-trajectory-view {
  .education-trajectory-view__body {
    .education-trajectory-view__info-field {
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
      margin-bottom: 22px;
      border-bottom: 0.5px solid var(--light-border);
      font-size: 1.13rem;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1rem, 1.13rem);
      }

      @media screen and (max-width: 568px) {
        flex-direction: column;
      }

      .education-trajectory-view__info-field-title {
        min-width: 350px;
        max-width: 350px;
        padding-right: 10px;
        color: var(--text-main);
        opacity: 0.6;

        @media screen and (max-width: 992px) {
          min-width: 35.29vw;
          max-width: 35.29vw;
        }

        @media screen and (max-width: 568px) {
          min-width: 100%;
          max-width: 100%;
        }
      }

      .education-trajectory-view__info-field-value {
        font-weight: bold;
        color: var(--trajectory-view-info-field-value);
      }
    }

    .education-trajectory-view__courses {
      .course:not(:last-of-type) {
        margin-bottom: 100px;
      }

      .course {
        .course__image {
          img {
            width: 100%;
            border-radius: 32px;
          }
        }

        .course__about {
          padding-left: 40px;
          display: flex;
          flex-direction: column;

          .course__title {
            font-weight: bold;
            color: var(--trajectory-view-info-field-value);
            margin-bottom: 9px;
          }
        }
      }
    }
  }
}
</style>
