<template>
  <div class="front-page-block-header">
    <div class="row no-gutters justify-content-center">
      <main>
        <div class="front-page-block-header__title">
          <slot name="title" />
        </div>
      </main>
    </div>
    <div class="row no-gutters justify-content-center">
      <sub>
        <div class="front-page-block-header__description">
          <slot name="description" />
        </div>
      </sub>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwFrontPageBlockHeader'
}
</script>

<style lang="scss" scoped>
.front-page-block-header {
  width: 100%;
  color: var(--text-main);
  margin-bottom: 28px;

  @media screen and (max-width: 768px) {
    margin-bottom: 26px;
  }

  .front-page-block-header__title {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 3.31rem;
    text-align: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'kern' off;

    @media screen and (max-width: 768px) {
      font-weight: normal;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  .front-page-block-header__description {
    margin-top: 7px;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.78rem;
    opacity: 0.5;
  }
}
</style>
