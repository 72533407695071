<template>
  <div class="selected-tag-list">
    <div
      v-for="(tag, index) in getTagList()"
      :key="`selected-tag-${componentKey}-${index}`"
      class="selected-tag-list__item"
      @click="$emit('change:tag', tag)"
    >
      #{{ tag.title }}
    </div>
  </div>
</template>
<script>
import Tag from '../../classes/tag/Tag'

export default {
  name: 'SwSelectedTagList',

  props: {
    /** @type { Tag[] } */
    tagList: {
      type: Array,
      required: true
    },
    componentKey: {
      type: String,
      required: true
    }
  },
  emits: ['change:tag'],

  methods: {
    /**
     * Список выбранных тегов
     * @return { Tag[] }
     */
    getTagList () {
      return this.tagList
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-tag-list {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  padding: 8px 8px 0 8px;

  .selected-tag-list__item {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--selected-tag-list-item);
    cursor: pointer;

    &:hover {
      color: var(--selected-tag-list-hover-item);
    }
  }

  .selected-tag-list__item:not(:last-child) {
    margin: 4px 8px 0 0;
  }

  .selected-tag-list__item:last-child {
    margin-top: 4px;
  }
}
</style>
