<template>
  <div
    :id="`gallery-album-${album.slug}`"
    class="sw-card sw-card_padding_m sw-card_photo-album"
  >
    <div
      class="sw-card_photo-album__body"
      @click="$router.push({name: 'galleryAlbumView', params: { albumIdentifier: album.slug }})"
    >
      <div class="row no-gutters justify-content-center sw-card__bottom-border">
        <div class="col-6 col-xs-5 col-md-6 mr-0.5 d-flex justify-content-center">
          <el-image
            v-if="firstPhoto"
            class="sw-card_photo-album__main-photo"
            :src="firstPhoto.getThumbnailUrl()"
            fit="cover"
            lazy
          />
        </div>
        <div class="col-6 col-xs-7 col-md-6">
          <div class="row row-cols-2 no-gutters h-100 sw-card_photo-album__photo-slider">
            <div
              v-for="(photo, index) in getSliderPhoto()"
              :key="`gallery-album-card-slide-${album.slug}-${index}`"
              class="col d-flex justify-content-center"
            >
              <el-image
                class="sw-card_photo-album__sub-photo"
                :src="photo.getThumbnailUrl()"
                fit="cover"
                lazy
              />
            </div>
            <div class="col d-flex justify-content-center">
              <div
                class="sw-card_photo-album__photo-num"
              >
                <div class="sw-card_photo-album__photo-num-icon">
                  {{ album.countPhoto() }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sw-card__title-text sw-card__title-text_v-center sw-card__title-text_dark sw-card__title-text_h-100">
        {{ album.title }}
      </div>
    </div>
  </div>
  <sw-selected-tag-list
    :component-key="`gallery-album-card-${album.slug}`"
    :tag-list="album.tagList"
    @change:tag="$emit('change:tag', $event)"
  />
</template>

<script>
import GalleryAlbum from '../../classes/gallery/GalleryAlbum'
import SwSelectedTagList from '../tag/SelectedTagList'
import SwGalleryAlbumPopup from './GalleryAlbumPopup'
export default {

  name: 'SwGalleryAlbumCard',
  components: { SwGalleryAlbumPopup, SwSelectedTagList },
  props: {
    album: {
      type: GalleryAlbum,
      required: true
    }
  },
  emits: ['change:tag'],

  data () {
    return {
      CARD_PHOTO_NUM: 5,
      SLIDER_PHOTO_NUM: 3
    }
  },

  computed: {
    sliderPhotoNum () {
      return this.album.countPhoto() <= this.CARD_PHOTO_NUM
        ? (this.album.countPhoto() - 1)
        : this.SLIDER_PHOTO_NUM
    },

    /**
     * Первое изображение из альбома
     * @returns {AlbumPhoto}
     */
    firstPhoto () {
      return this.album.getPhoto(0)
    }
  },

  methods: {
    /**
     * Список изображений для слайдера
     * @returns {AlbumPhoto[]}
     */
    getSliderPhoto () {
      return this.album.photoList.slice(1, this.SLIDER_PHOTO_NUM + 1)
    }
  }
}
</script>

<style lang="scss">

.sw-card_photo-album {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 304px;
  background-color: var(--album-card-bg);
  border: 0.5px solid var(--album-card-border);
  cursor: pointer;

  @media screen and (min-width: 569px) and (max-width: 1280px) {
    width: 98%;
  }

  @media screen and (max-width: 1280px) {
    height: auto;
  }

  .sw-card_photo-album__body {
    display: flex;
    flex-direction: column;
    height: 100%;

    img {
      border-radius: 8px;
    }

    .sw-card_photo-album__main-photo {
      width: 100%;
      height: 100%;
      padding-right: 8px;
    }

    .sw-card_photo-album__sub-photo {
      width: 100%;
      height: 100%;
    }

    .sw-card_photo-album__body-title {
      width: 100%;
      font-size: 1.25rem;
    }

    .sw-card_photo-album__photo-slider {
      .col:not(:first-child) {
        opacity: 0.5;
      }

      .col:first-child {
        padding: 0 4px 4px 0;
      }

      .col:nth-child(2) {
        padding: 0 0 4px 4px;
      }

      .col:nth-child(3) {
        padding: 4px 4px 0 0;
      }

      .col:nth-child(4) {
        padding: 4px 0 0 4px;
      }

      .sw-card_photo-album__photo-num {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--album-photo-num-card-bg);
        border-radius: 8px;

        .sw-card_photo-album__photo-num-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 0.875rem;
          line-height: 1.5rem;
          color: var(--album-photo-num-card);

          &::before {
            display: block;
            content: '';
            width: 17px;
            height: 15px;
            margin-right: 6px;
            background: var(--album-photo-num-card-bg) url('/static/img/icon/camera-gray.svg') no-repeat center;
          }
        }
      }
    }
  }
}
</style>
