const messages = {
  ru: {
    message: {
      sfu: 'Сибирский федеральный университет',
      copy_requirements: 'При использовании текстовых и графических материалов ссылка на сайт обязательна',
      contact_phone: '+7(391) 206 22 10',
      privacy_policy: 'Политика конфиденциальности',
      personal_data_agreement: 'Соглашение о персональных данных',
      about: 'О проекте',
      contacts: 'Контакты',
      success: 'Успешно',
      warning: 'Внимание',
      home_page: 'Главная',
      go_to_home_page: 'На главную',
      details: 'Подробности',
      more_details: 'Подробнее',
      more: 'Еще',
      info: 'Информация',
      all: 'Все',
      login: 'Войти',
      show_more: 'Показать ещё ({num})',
      there_is: 'Есть',
      go_to: 'Перейти',
      phone: 'Телефон',
      email: 'Почта',
      vkontakte: 'ВКонтакте',
      docs: 'Документы',
      atlas: {
        atlas: 'Атлас',
        title: 'Интерактивный атлас коренных малочисленных народов Севера, Сибири и Дальнего Востока: языки и культуры',
      },
      grants: {
        personalized_grant: 'Именная стипендия',
      },
      navigator: {
        career_navigator: 'Карьерный навигатор',
        choose_your_trajectory: 'Узнай какая траектория подходит именно тебе',
        navigator: 'Навигатор',
        test: 'Тест',
        take_test: 'Пройти тест',
        start_test: 'Начать тестирование',
        restart_test: 'Начать снова',
        test_try_again: 'Пройти тест снова',
        result: 'Результат',
        result_suitable_trajectories: 'Мы подобрали для вас самые подходящие траектории обучения.',
        result_choose_one_trajectory: 'Вы можете выбрать любую и начать с нее.',
        test_result: 'Результат теста',
        question_description_title: 'А знаете ли вы что... ?',
        question_points_hint: 'Оцените утверждение с&nbsp;точки зрения нравится&nbsp;ли вам и&nbsp;насколько&nbsp;бы вы&nbsp;хотели этим заниматься',
        question_point_minus_2: 'Очень не нравится, совершенно не хочется',
        question_point_minus_1: 'Не хотелось бы, не нравится',
        question_point_0: 'Затрудняюсь ответить',
        question_point_1: 'Нравится, но не очень сильно',
        question_point_2: 'Очень интересно, хотелось бы этим заниматься',
        navigator_test_step: '{step} из {steps}',
        go_to_trajectory: 'Вперед',
      },
      frontpage: {
        main_title: 'Образование для коренных малочисленных народов',
        sub_title: 'Севера, Сибири и Дальнего Востока Российской Федерации',
        hours_of_content: 'Часов учебного контента',
        edu_program: 'Обучающих программ',
        best_teachers: 'Лучших преподаваталей'
      },
      partner: {
        partners: 'Партнеры'
      },
      platform: {
        platforms: 'Платформы',
        sw: 'Образовательная платформа',
        arctic: 'Институт Севера и Арктики',
        sfu: 'СФУ — Сибирский федеральный университет'
      },
      team: {
        team: 'Команда',
        main_title: 'Команда проекта',
        member: {
          contacts: 'Контакты'
        }
      },
      education: {
        course: {
          authors: 'Авторы',
          content: 'Описание курса',
          start_date: 'Дата начала обучения',
          end_date: 'Дата окончания обучения',
          laboriousness: 'Трудоемкость',
          laboriousness_1: '{value} зачетная единица',
          laboriousness_2_4: '{value} зачетные единицы',
          laboriousness_more: '{value} зачетных единицы',
          labor_costs: 'Трудозатраты',
          labor_costs_1: '{value} час в неделю',
          labor_costs_2_4: '{value} часа в неделю',
          labor_costs_more: '{value} часов в неделю',
          entrance_requirements: 'Рекомендуемые входные требования',
          right_holder: 'Правообладатель',
          curriculum: 'Учебный план',
          formed_competencies: 'Формируемые компетенции',
          learning_outcomes: 'Результаты обучения',
          training_directions: 'Направления подготовки',
          has_certificate: 'Cертификат об окончании онлайн курса',
          go_to: 'Перейти к курсу',
          courses: 'Курсы',
          duration: 'Продолжительность',
          duration_1: '{value} неделя',
          duration_2_4: '{value} недели',
          duration_more: '{value} недель',
          sign_up: 'Записаться'
        },
        trajectory: {
          courses: 'Курсы',
          courses_short_description: 'Курсы траектории',
          description: 'Описание траектории',
          documents: 'Документы',
          edu_program: 'Образовательная программа',
          labor_intensity: 'Трудоемкость',
          plan: 'Учебный план',
          results: 'Результаты обучения',
          sign_up: 'Начать обучение',
          trajectories: 'Траектории',
          trajectory: 'Траектория',
        }
      },
      gallery: {
        gallery: 'Фотогалерея',
        photo_list: 'Фотографии'
      },
      event: {
        events: 'События',
        upcoming: 'Грядущие события',
        current: 'Текущие события',
        organizer: 'Организатор',
        conducting_format: 'Форма проведения',
        participants: 'Участники'
      },
      post: {
        posts: 'Новости'
      },
      tribune: {
        tribunes: 'Трибуны'
      }
    },
    errors: {
      not_found: 'Страница не найдена',
      error: 'Ошибка',
      undefined: 'Неизвестная ошибка',
      team: {
        structure: {
          get: 'Ошибка получения структуры'
        }
      },
      blog: {
        event: {
          list_get: 'Ошибка получения списка событий'
        }
      },
      education: {
        course: {
          list_get: 'Ошибка получения списка курсов',
          get: 'Ошибка получения описания курса'
        },
        category: {
          list_get: 'Ошибка получения списка образовательных категорий',
          get: 'Ошибка получения образовательной категории'
        }
      },
      gallery: {
        album: {
          list_get: 'Ошибка получения списка альбомов',
          get: 'Ошибка получения альбома'
        }
      },
      tag: {
        get_list: 'Ошибка получения списка тегов'
      }
    }
  },
  en: {
    message: {
      sfu: 'Siberian Federal University',
      copy_requirements: 'When using text and graphic materials, a reference to the site is required',
      contact_phone: '+7(391)206 52 95',
      privacy_policy: 'Privacy policy',
      personal_data_agreement: 'Consent to processing personal data',
      about: 'About',
      contacts: 'Contacts',
      success: 'Успешно',
      warning: 'Внимание',
      home_page: 'Home',
      go_to_home_page: 'Home',
      details: 'More',
      more_details: 'More',
      more: 'More',
      info: 'Information',
      all: 'All',
      login: 'Log in',
      show_more: 'More ({num})',
      there_is: 'Available',
      go_to: 'More',
      phone: 'Phone number',
      email: 'Email',
      vkontakte: 'VKontakte',
      docs: 'Documents',
      frontpage: {
        main_title: 'Education for indigenous minorities',
        sub_title: 'of the North, Siberia and the Far East of the Russian Federation',
        hours_of_content: 'Часов учебного контента',
        edu_program: 'Обучающих программ',
        best_teachers: 'Лучших преподаваталей'
      },
      partner: {
        partners: 'Partners'
      },
      platform: {
        platforms: 'Platforms',
        sw: 'Educational platform',
        arctic: 'School of the North and the Arctic',
        sfu: 'SibFU - Siberian Federal University'
      },
      team: {
        team: 'Team',
        main_title: 'Project Team',
        member: {
          contacts: 'Contact info'
        }
      },
      education: {
        course: {
          authors: 'Authors',
          content: 'About the course',
          start_date: 'Start date',
          end_date: 'End date',
          laboriousness: 'Credits',
          laboriousness_1: '{value} ECTS credits',
          laboriousness_2_4: '{value} ECTS credits',
          laboriousness_more: '{value} ECTS credits',
          labor_costs: 'Hours per week',
          labor_costs_1: '{value} hour per week',
          labor_costs_2_4: '{value} hrs per week',
          labor_costs_more: '{value} hrs per week',
          entrance_requirements: 'Рекомендуемые входные требования',
          right_holder: 'Ownership',
          curriculum: 'Curriculum',
          formed_competencies: 'Competencies',
          learning_outcomes: 'Results',
          training_directions: 'Advisable programs',
          has_certificate: 'Online course certificate of completion',
          go_to: 'Перейти к курсу',
          courses: 'Courses',
          duration: 'Period',
          duration_1: '{value} week',
          duration_2_4: '{value} weeks',
          duration_more: '{value} weeks',
          sign_up: 'Enrol'
        }
      },
      gallery: {
        gallery: 'Photos',
        photo_list: 'Photos'
      },
      event: {
        events: 'Events',
        upcoming: 'Upcoming events',
        current: 'Ongoing events',
        organizer: 'Organiser',
        conducting_format: 'Mode',
        participants: 'Participants'
      },
      post: {
        posts: 'News'
      },
      tribune: {
        tribunes: 'Expert opinion'
      }
    },
    errors: {
      not_found: 'Страница не найдена',
      error: 'Ошибка',
      undefined: 'Неизвестная ошибка',
      team: {
        structure: {
          get: 'Ошибка получения структуры'
        }
      },
      blog: {
        event: {
          list_get: 'Ошибка получения списка событий'
        }
      },
      education: {
        course: {
          list_get: 'Ошибка получения списка курсов',
          get: 'Ошибка получения описания курса'
        },
        category: {
          list_get: 'Ошибка получения списка образовательных категорий',
          get: 'Ошибка получения образовательной категории'
        }
      },
      gallery: {
        album: {
          list_get: 'Ошибка получения списка альбомов',
          get: 'Ошибка получения альбома'
        }
      },
      tag: {
        get_list: 'Ошибка получения списка тегов'
      }
    }
  }
}

export default messages
