<template>
  <div class="additional-menu">
    <div class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_down sw-btn_primary_blue sw-btn_front-page-top"
         @click="scrollTo('frontpage_navigator')">
      {{ $t('message.navigator.navigator') }}
    </div>
    <div class="sw-btn sw-btn_b sw-btn_primary sw-btn_primary_down sw-btn_primary_blue sw-btn_front-page-top"
         @click="scrollTo('frontpage_atlas')">
      {{ $t('message.atlas.atlas') }}
    </div>
  </div>
</template>

<script>
import ScrollTo from '../../mixins/ScrollTo'

export default {
  name: 'SwFrontPageTopAdditionalMenu',
  mixins: [ScrollTo]
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.additional-menu {
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: left;

  > :nth-child(odd) {
    margin-right: 8px;
  }

  .additional-menu__button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 191px;
    height: 56px;
    color: var(--education-menu-item);
    background-color: var(--education-menu-item-bg);
    border-radius: 16px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      min-width: 168px;
    }

    @media screen and (max-width: 376px) {
      min-width: 140px;
    }

    @media (hover) {
      &:hover {
        color: var(--education-menu-item-hover);
        background-color: var(--education-menu-item-bg-hover);

        .education-menu__text {
          color: var(--education-menu-hover-color);
        }
      }
    }

    .education-menu__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      font-size: 1.25rem;
      color: var(--education-menu-color);

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 0.875rem, 1.25rem);
      }
    }

    .additional-menu__button-icon {
      content: '';
      width: 16px;
      height: 16px;
      margin-left: 8px;
      background: url("/static/img/control/down-arrow-white.svg") no-repeat center;
      background-size: contain;
    }
  }
}
</style>
