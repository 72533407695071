<template>
  <footer class="footer">
    <div class="footer__main-section">
      <div class="footer__main-content">
        <div class="container-fluid p-0">
          <div class="row w-100 no-gutters d-flex flex-column flex-xs-row justify-content-xs-center">
            <div class="col-12 col-xs-6 d-flex flex-column align-items-center align-items-xs-start">
              <div><img src="/static/img/header/header-logo-v2.svg"></div>
            </div>
            <div class="col-xs-6 col-md-5 d-none d-xs-flex flex-column align-items-center align-items-xs-start">
              <div class="footer__contacts-title">
                {{ $t('message.contacts') }}
              </div>
            </div>
            <div class="col-md-1 justify-content-end d-none d-md-flex">
              <a
                target="_blank"
                href="https://vk.com/snoword"
              >
                <div class="footer__contact-icon footer__contact-icon_vk" />
              </a>
            </div>
          </div>
          <div class="row w-100 no-gutters d-flex flex-row">
            <div class="col-12 col-xs-6 d-flex flex-column align-items-center align-items-xs-start">
              <div class="footer__copyright">
                &copy; <a
                  target="_blank"
                  href="https://sfu-kras.ru"
                >{{ $t('message.sfu') }}</a>, {{ currentYear }}<br>
                {{ $t('message.copy_requirements') }}
              </div>
            </div>
            <div class="col-12 d-flex d-xs-none justify-content-center mt-4">
              <div class="footer__contacts-title">
                {{ $t('message.contacts') }}
              </div>
            </div>
            <div class="col-12 col-xs-6 col-md-5 d-flex flex-column align-items-center align-items-xs-start mt-3 mt-xs-0">
              <div class="footer__contacts d-flex flex-row">
                <div class="footer__contact footer__contact_email">
                  <a href="mailto:info@snoword.ru">info@snoword.ru</a>
                </div>
                <div class="footer__contact-delimiter" />
                <div class="footer__contact footer__contact_phone">
                  {{ $t('message.contact_phone') }}
                </div>
              </div>
              <div
                class="col p-0 justify-content-center justify-content-xs-start d-flex d-md-none align-items-end mt-4 mt-xs-0"
              >
                <a
                  target="_blank"
                  href="https://vk.com/snoword"
                >
                  <div class="footer__contact-icon footer__contact-icon_vk" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__privacy-section">
      <div class="footer__privacy-content">
        <div class="container-fluid p-0">
          <div class="row no-gutters footer__content-delimiter">
            <div class="col align-items-center align-items-xs-start d-flex flex-column flex-xs-row">
              <div
                class="footer__privacy-link"
                @click="privacyPolicyVisible = !privacyPolicyVisible"
              >
                {{ $t('message.privacy_policy') }}
              </div>
              <div class="footer__privacy-link-delimiter d-none d-xs-block" />
              <div class="footer__privacy-link mt-3 mt-xs-0">
                <a
                  target="_blank"
                  href="https://about.sfu-kras.ru/node/10201"
                >{{ $t('message.personal_data_agreement') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      key="privacy-policy-dialog"
      v-model="privacyPolicyVisible"
      :lock-scroll="true"
      :title="$t('message.privacy_policy')"
    >
      <div class="h2">
        Политика конфиденциальности
      </div>
      Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей информации,
      которую ФГАОУ ВО «Сибирский федеральный университет» и/или его аффилированные лица, могут получить о Пользователе во
      время использования им информационно-образовательной платформы SNOWORD, расположенного по адресам:
      https://snoword.ru, https://snoword.sfu-kras.ru, https://edu.snoword.ru (далее — Платформа Snoword). Использование
      Платформы Snoword означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями
      обработки его персональной информации. В случае несогласия с этими условиями Пользователь должен воздержаться от
      использования Платформы Snoword.<br><br><br>1. Персональная информация Пользователей, которую обрабатывает ФГАОУ ВО
      «Сибирский федеральный университет» посредством Платформы Snoword.<br>1.1. В рамках настоящей Политики под
      «персональной информацией Пользователя» понимаются:<br>1.1.1. Персональная информация, которую Пользователь
      предоставляет о себе самостоятельно в процессе использования Платформы Snoword, включая персональные данные
      Пользователя. Обязательная для предоставления информация помечена специальным образом.<br>1.1.2. Данные, которые
      автоматически передаются в Платформу Snoword в процессе их использования с помощью установленного на устройстве
      Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере
      Пользователя (или иной программе, с помощью которой осуществляется доступ к Платформе Snoword), технические
      характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Платформе
      Snoword, адреса запрашиваемых страниц и иная подобная информация.<br>1.2. Настоящая Политика применима только к
      информации, обрабатываемой в ходе использования Платформы Snoword. ФГАОУ ВО «Сибирский федеральный университет» не
      контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые Пользователь может
      перейти по ссылкам, доступным из Платформы Snoword.<br><br><br>2. Цели обработки персональной информации
      Пользователей<br>2.1. ФГАОУ ВО «Сибирский федеральный университет» собирает и хранит только ту персональную
      информацию, которая необходима для предоставления услуг Платформы Snoword, за исключением случаев, когда
      законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.<br>2.2.
      Персональную информацию Пользователя в Платформе Snoword ФГАОУ ВО «Сибирский федеральный университет» обрабатывает в
      следующих целях:<br>• идентификация стороны в рамках Платформы Snoword;<br>• предоставление Пользователю услуг
      Платформы Snoword, исполнение соглашений и договоров;<br>• связь с Пользователем, в том числе направление
      уведомлений, запросов и информации, касающихся использования Платформы Snoword, исполнения соглашений и договоров, а
      также обработка запросов и заявок от Пользователя;<br>• улучшение качества Платформы Snoword, удобства его
      использования, разработка нового функционала;<br>• таргетирование информационных и рекламных материалов;<br>•
      проведение статистических и иных исследований на основе обезличенных данных.<br><br><br>3. Условия обработки
      персональной информации Пользователя и её передачи третьим лицам<br>3.1. ФГАОУ ВО «Сибирский федеральный
      университет» хранит персональную информацию пользователей в соответствии с настоящей политикой, пользовательским
      соглашением и внутренними документами ФГАОУ ВО «Сибирский федеральный университет» по защите информации.<br>3.2. В
      отношении персональной информации Пользователя обеспечивается ее конфиденциальность, кроме случаев добровольного
      предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании
      отдельных функций Платформы Snoword, Пользователь соглашается с тем, что определённая часть его персональной
      информации становится общедоступной.<br>3.3. ФГАОУ ВО «Сибирский федеральный университет» вправе передать
      персональную информацию Пользователя третьим лицам в следующих случаях:<br>• пользователь выразил свое согласие на
      такие действия;<br>• передача необходима для исполнения распоряжения Пользователя;<br>• передача предусмотрена
      российским или иным применимым законодательством в рамках установленной законодательством процедуры;<br>• в целях
      обеспечения возможности защиты прав и законных интересов ФГАОУ ВО «Сибирский федеральный университет» или третьих
      лиц в случаях, когда у ФГАОУ ВО «Сибирский федеральный университет» имеются достаточные основания полагать, что
      Пользователь нарушает условия Соглашения и/или требования применимого законодательства.<br>3.4. При обработке
      персональных данных Пользователей ФГАОУ ВО «Сибирский федеральный университет» руководствуется Федеральным законом
      «О персональных данных» и другими нормативно-правовыми документами в области защиты информации.<br><br><br>4.
      Обработка персональной информации при помощи файлов Cookie и счетчиков<br>4.1. Пользователь осознает, что
      оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет могут обладать функцией
      запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее
      полученных файлов cookie.<br>4.2. ФГАОУ ВО «Сибирский федеральный университет» вправе установить, что предоставление
      Платформе Snoword возможно лишь при условии, что прием и получение файлов cookie разрешены Пользователем.<br>4.3.
      Структура файла cookie, его содержание и технические параметры определяются ФГАОУ ВО «Сибирский федеральный
      университет» и могут изменяться без предварительного уведомления Пользователя.<br>4.4. Счетчики, размещенные ФГАОУ
      ВО «Сибирский федеральный университет» на страницах Платформы Snoword, могут использоваться для анализа файлов
      cookie Пользователя, для сбора и обработки статистической информации об использовании Платформы Snoword, а также для
      обеспечения работоспособности Платформы Snoword в целом или его отдельных функций в частности. Технические параметры
      работы счетчиков определяются ФГАОУ ВО «Сибирский федеральный университет» и могут изменяться без предварительного
      уведомления Пользователя.<br><br><br>5. Меры, применяемые для защиты персональной информации Пользователей<br>5.1.
      ФГАОУ ВО «Сибирский федеральный университет» принимает необходимые и достаточные организационные и технические меры
      для защиты персональной информации Пользователя, обрабатываемой Платформой Snoword от неправомерного или случайного
      доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий
      с ней третьих лиц.<br><br><br>6. Изменение Политики конфиденциальности. Применимое законодательство<br>6.1. ФГАОУ ВО
      «Сибирский федеральный университет» имеет право вносить изменения в настоящую Политику<br>
    </el-dialog>
  </footer>
</template>

<script>
export default {
  name: 'SwPageFooter',
  data () {
    return {
      privacyPolicyVisible: false
    }
  },

  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 320px;

  .el-dialog {
    max-width: 1000px;

    @media screen and (max-width: 993px) {
      width: 80% !important;
    }
  }

  .footer__main-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    padding: 32px 0;
    background-color: var(--footer-bg);

    @media screen and (max-width: 1280px) {
      padding: 32px 2.58vw 32px 2.58vw;
    }

    .footer__main-content {
      max-width: 1232px;
      width: 100%;

      .footer__copyright {
        max-width: 460px;
        margin-top: 58px;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--footer-copyright);

        @media screen and (max-width: 768px) {
          max-width: 250px;
          margin-top: 31px;
        }

        @media screen and (max-width: 568px) {
          max-width: 343px;
          text-align: center;
        }
      }

      .footer__contacts-title {
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: var(--text-main);
      }

      .footer__contact-icon {
        width: 48px;
        height: 48px;
        content: '';

        &.footer__contact-icon_vk {
          background: transparent url('/static/img/icon/vk.svg') no-repeat center;
        }
      }

      .footer__contacts {
        .footer__contact-delimiter {
          width: 1px;
          height: 1.5rem;
          content: '';
          margin: 0 16px;
          background-color: var(--footer-contacts-delimiter);
          opacity: 0.2;
        }

        .footer__contact {
          width: min-content;
          font-size: 1rem;
          line-height: 1.5rem;
          white-space: nowrap;

          &.footer__contact_email {
            color: var(--footer-contact-email);
          }

          &.footer__contact_phone {
            color: var(--footer-contact-phone);
          }
        }
      }
    }
  }

  .footer__privacy-section {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
    padding-top: 15px;
    padding-bottom: 19px;
    border-top: 0.5px solid var(--footer-content-delimiter);
    background-color: var(--footer-bg);

    @media screen and (max-width: 1280px) {
      padding: 23px 2.58vw 23px 2.58vw;
    }

    .footer__privacy-content {
      max-width: 1232px;
      width: 100%;

      .footer__privacy-link-delimiter {
        content: '';
        width: 24px;
      }

      .footer__privacy-link {
        cursor: pointer;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--footer-privacy-link);

        &:hover {
          color: var(--text-main);
        }
      }
    }
  }
}
</style>
