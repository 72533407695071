<template>
  <div class="trajectory">
    <div class="trajectory__title">
      {{ trajectory.title }}
    </div>
    <div class="no-gutters d-flex flex-row">
      <div class="col-4 trajectory__image">
        <img :src="trajectory.featuredImage" />
      </div>
      <div class="col-8 pl-4 d-flex flex-column align-items-end">
        <div class="trajectory__description" v-html="trajectory.shortDescription" />
        <div
            class="sw-btn sw-btn_b sw-btn_primary"
            @click="goToTrajectory">
          {{ $t('message.navigator.go_to_trajectory') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationTrajectory from '../../../classes/education/EducationTrajectory';

export default {
  name: 'SwNavigatorResultTrajectory',

  props: {
    trajectory: {
      type: EducationTrajectory,
      required: true
    }
  },

  methods: {
    goToTrajectory() {
      this.$router.push({name: 'educationTrajectoryView', params: {trajectoryIdentifier: this.trajectory.slug}})
    }
  }

}
</script>

<style lang="scss" scoped>
.trajectory {
  .trajectory__title {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .trajectory__image {
    img {
      width: 100%;
    }
  }

  .trajectory__description {
    width: 100%;
    font-size: 1.125rem;
  }
}
</style>
