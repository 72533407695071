import SwError from '../../../classes/error/Error'
import BlogTribuneCard from '../../../classes/blog/tribune/BlogTribuneCard'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type {BlogTribuneCard[]} */
  tribuneCards: [],

  /** @type {Pagination|null} */
  pagination: null
})

const state = initialState()

const getters = {
  /**
   * Триубны
   * @param state
   * @return {BlogTribuneCard[]}
   */
  getTribuneCards (state) {
    return state.tribuneCards
  },

  /**
   * @param state
   * @returns {Pagination|null}
   */
  getPagination (state) {
    return state.pagination
  }
}
const mutations = {
  ADD_TRIBUNE_CARD (state, card) {
    state.tribuneCards.push(card)
  },

  SET_PAGINATION (state, pagination) {
    state.pagination = pagination
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  RESET_TRIBUNE_CARDS (state) {
    state.tribuneCards = []
    state.pagination = null
  }
}
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  resetTribuneCards ({ commit }) {
    commit('RESET_TRIBUNE_CARDS')
  },

  async findTribuneCards (context, params) {
    const apiReadClient =
        new ApiReadClient('/api/blog/tribunes/cards', { params }, 'BlogTribuneCard')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const tribuneCard of apiReadClient.data) {
        context.commit('ADD_TRIBUNE_CARD', tribuneCard)
      }
      context.commit('SET_PAGINATION', apiReadClient.pagination)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
