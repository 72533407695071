<template>
  <section
      id="frontpage_navigator"
      class="row w-100 justify-content-center"
  >
    <div class="navigator">
      <div class="frontpage-block">
        <sw-front-page-block-header>
          <template #title>
            {{ $t('message.navigator.career_navigator') }}
          </template>
        </sw-front-page-block-header>

        <div class="navigator__content">
          <div class="navigator__hint">
            {{ $t('message.navigator.choose_your_trajectory') }}
          </div>

          <div class="d-flex flex-column flex-xl-row mt-4 ml-4 mr-4 align-items-start align-items-xl-end">
            <div class="col-xl-6">
              <div class="navigator__block">
                <div>
                  Навигатор&nbsp;&mdash; это модифицированный вариант методики &laquo;Карты интересов&raquo;. Он&nbsp;позволит определить круг ваших интересов и&nbsp;степень их&nbsp;выраженности. После тестирования мы&nbsp;сможем предложить оптимальную траекторию развития ваших компетенций на&nbsp;нашей платформе.
                </div>
                <div class="characteristics-list mt-3">
                  <div class="characteristics-list__item">
                    Вопросы теста это утверждения о&nbsp;различных видах дейтельности
                  </div>
                  <div class="characteristics-list__item">
                    Вы&nbsp;сможете выбрать один из&nbsp;пяти вариантов ответа, описывающий ваше отношение к&nbsp;вопросу-утверждению
                  </div>
                  <div class="characteristics-list__item">
                    Тест и&nbsp;результаты теста&nbsp;&mdash; анонимны
                  </div>
                  <div class="characteristics-list__item">
                    Можно проходить несколько раз
                  </div>
                  <div class="characteristics-list__item">
                    Вы&nbsp;узнаете что подходит именно вам
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3 mt-xl-0 col-xl-6 d-flex flex-column align-items-center align-items-xl-start">
              <div class="navigator__block">
                Пройдите тест и&nbsp;выберите одну из&nbsp;предложенных траекторий обучения
              </div>

              <div
                  class="sw-btn sw-btn_b sw-btn_primary mt-4"
                  @click="$router.push({name: 'navigatorView' })"
              >
                {{ $t('message.navigator.take_test') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwFrontPageBlockHeader from '../../page/FrontPageBlockHeader';

export default {
  name: 'SwFrontPageNavigator',
  components: {SwFrontPageBlockHeader}
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.navigator {
  .navigator__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1232px;
    height: max-content;
    padding: 30px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/static/img/frontpage/navigator/navigator-block-bg.png");
    color: var(--text-main);
    border-radius: 30px;

    .navigator__hint {
      padding: 14px 30px;
      font-size: 1.625rem;
      text-align: center;
      background: rgba(255, 255, 255, 0.80);
      border-radius: 36px;

      @media screen and (max-width: 1280px) {
        @include fluid-font-size(48rem, 80rem, 1.25rem, 1.625rem);
      }

      @media screen and (min-width: 769px) {
        font-weight: bold;
      }
    }

    .navigator__block {
      width: 100%;
      padding: 43px 52px;
      font-size: 1.25rem;
      background: rgba(255, 255, 255, 0.80);
      border-radius: 17px;

      @media screen and (max-width: 769px) {
        padding: 20px 25px;
      }
    }

    .characteristics-list {
      .characteristics-list__item {
        display: flex;
        flex-direction: row;

        &:before {
          display: block;
          content: '';
          min-width: 24px;
          height: 17px;
          margin-top: 7px;
          margin-right: 20px;
          background-image: url('/static/img/frontpage/navigator/bull-blue.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}
</style>
