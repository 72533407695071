/**
 * Тег
 *
 * @module Tag
 * @class Tag
 */
class Tag {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
  }

  /**
   * Идентификатор тега
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок тега
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор тега
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }
}

export default Tag
