import EducationCategory from './EducationCategory'

/**
 * Карточка курса
 *
 * @module Education
 * @class EducationCourseCard
 */
class EducationCourseCard {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.excerpt = attributes.excerpt
    this.featuredImage = attributes.featured_image
    this.hasCertificate = attributes.has_certificate
    this.duration = attributes.duration
  }

  /**
   * Идентификатор категории
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок категории
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор категории
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Выдержка из описание категории
   * @return {String}
   */
  get excerpt () {
    return this._excerpt
  }

  set excerpt (value) {
    this._excerpt = value
  }

  /**
   * Заглавное изображение курса
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }

  /**
   * Cертификат об окончании онлайн курса?
   * @return {boolean}
   */
  get hasCertificate () {
    return this._hasCertificate
  }

  set hasCertificate (value) {
    this._hasCertificate = value === 1
  }

  /**
   * Продолжительность обучения (недель)
   * @return {Number}
   */
  get duration () {
    return this._duration
  }

  set duration (value) {
    this._duration = value
  }

  /**
   * Связанные объектв
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
    this.setCategory(relatedData)
  }

  /**
   * Образовательная категория курса
   * @return {EducationCategory|null}
   */
  get category () {
    return this._category
  }

  setCategory (relatedData) {
    const categoryData = this.relationships?.category?.data
    if (categoryData) {
      this.categoryId = categoryData.id
      for (const relatedObj of relatedData) {
        if (relatedObj instanceof EducationCategory && this.categoryId === relatedObj.id) {
          this._category = relatedObj
          break
        }
      }
    }
  }

  /**
   * Url на уменьшенное изображение
   * @return {string}
   */
  getThumbnailImageUrl () {
    const url = new URL(this.featuredImage)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }
}
export default EducationCourseCard
