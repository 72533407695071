<template>
  <section
    id="gallery"
    class="row w-100 justify-content-center"
  >
    <div
      v-if="tagList"
      class="frontpage-block"
    >
      <sw-front-page-block-header>
        <template #title>
          {{ $t('message.gallery.gallery') }}
        </template>
      </sw-front-page-block-header>
      <tag-list
        :tag-list="tagList"
        :curr-tag="currTag"
        component-key="gallery-album-card-list"
        @change:tag="setCurrTag($event)"
      />
      <div
        v-if="albumList"
        class="row no-gutters row-cols-1 row-cols-xs-2 row-cols-md-3 sw-card-list sw-card-list_col-1_col-xs-2_col-md-3"
      >
        <div
          v-for="album in albumList"
          :key="`gallery-col-card-${album.slug}`"
          class="col sw-card-list__col"
        >
          <gallery-album-card
            :key="`gallery-card-${album.slug}`"
            :album="album"
            @change:tag="setCurrTag($event)"
          />
        </div>
      </div>
      <sw-show-more-btn
        v-if="hasMoreData"
        :num="pagination.countMoreData()"
        @show:more="setAlbumList()"
      />
    </div>
  </section>
</template>

<script>
import GalleryAlbumCard from './GalleryAlbumCard'
import GalleryAlbum from '../../classes/gallery/GalleryAlbum'
import TagList from '../tag/TagList'
import SwFrontPageBlockHeader from '../page/FrontPageBlockHeader'
import SwShowMoreBtn from '../page/control/ShowMoreBtn'
import Tag from '../../classes/tag/Tag'
import Pagination from '../../classes/pagination/Pagination'
export default {
  name: 'SwGalleryAlbumCardList',
  components: { SwShowMoreBtn, SwFrontPageBlockHeader, TagList, GalleryAlbumCard },

  props: {
    /**
     * Тег для которого выбрать альбомы
     * В качестве параметра используется slug тега
     */
    tagSlug: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      pageSize: 3
    }
  },

  computed: {
    /** @returns String */
    language () {
      return this.$store.getters['language/getLanguage']
    },

    /** @returns Pagination */
    pagination () {
      return this.$store.getters['gallery/getPagination']
    },

    /** @return GalleryAlbum[] */
    albumList () {
      return this.$store.getters['gallery/getAlbums']
    },

    /** @return Tag[] */
    tagList () {
      return this.$store.getters['galleryTag/getTags']
    },

    /** @return Tag */
    currTag () {
      return this.$store.getters['galleryTag/getCurrentTag']
    },

    /** @return {String} */
    currTagSlug () {
      return this.currTag instanceof Tag ? this.currTag.slug : null
    },

    /**
     * Еще доступны события для получения?
     * @return {boolean}
     */
    hasMoreData () {
      return this.pagination instanceof Pagination
        ? this.pagination.countMoreData() > 0
        : false
    },

    /**
     * Ткекущая страница
     * @return {Number}
     */
    currentPage () {
      return this.pagination instanceof Pagination
        ? Number(this.pagination.currentPage)
        : 0
    }
  },

  watch: {
    /**
     * Слежение за изменением языка
     * При изменении необходимо загрузить карточки, которые добавлены для данного языка
     */
    language () {
      this.$store.dispatch('galleryTag/fetchTags')
      this.setAlbumList()
    },

    currTagSlug () {
      if (this.tagList.length !== 0) {
        this.$store.dispatch('gallery/reset')
        this.setAlbumList()
      }
    }
  },

  mounted () {
    if (this.tagList.length === 0) {
      this.$store.dispatch('galleryTag/fetchTags')
    }
    if (this.albumList.length === 0) {
      this.setAlbumList()
    }
  },

  methods: {
    setAlbumList () {
      this.$store.dispatch('gallery/findAlbums', this.albumListSearchParams())
    },

    albumListSearchParams () {
      const size = this.pageSize
      const number = this.currentPage + 1
      const params = new URLSearchParams()
      params.append('page[size]', size)
      params.append('page[number]', number)
      params.append('include', 'tags')
      if (this.currTagSlug) {
        params.append('filter[tag_slug]', this.currTagSlug)
      }
      return params
    },

    /**
     * Установить текущий тег
     * @param {Tag} tag
     */
    setCurrTag (tag) {
      if (this.currTag === tag) {
        this.$store.dispatch('galleryTag/resetCurrentTag')
      } else {
        this.$store.dispatch('galleryTag/setCurrentTag', tag)
      }
    }
  }
}
</script>
