function initialState () {
  const language = localStorage.getItem('language') ?? 'ru'
  return {
    language: language
  }
}

const state = initialState()

const getters = {
  /** @returns {String} */
  getLanguage (state) {
    return state.language
  },

  /** @returns {String} */
  getLanguageCode (state) {
    switch (state.language) {
      case 'en':
        return 'en-US'

      default:
        return 'ru-RU'
    }
  }
}

const mutations = {
  SET_LANGUAGE (state, language) {
    state.language = language
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  /**
   *
   * @param commit
   * @param state
   * @param {String} language
   */
  setLanguage ({ commit, state }, language) {
    localStorage.setItem('language', language)
    commit('SET_LANGUAGE', language)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
