<template>
  <div class="gallery-album-inside-view">
    <div
      class="row row-cols-3 row-cols-md-4"
    >
      <sw-gallery-album-popup
        :def-album-visibility="albumVisible"
        :selected-photo-index="selectedPhotoIndex"
        :gallery-album="galleryAlbum"
        @change:visible:hide="hideAlbum()"
      />
      <div
        v-for="(photo, index) in getSliderPhoto"
        :key="`${componentKey}-${index}`"
        :class="getPhotoCssClass(index)"
      >
        <el-image
          class="photo-card photo-card_clickable photo-card_b"
          :src="photo.getThumbnailUrl()"
          fit="cover"
          lazy
          @click="showAlbum(index)"
        />
      </div>
      <div class="col mb-4 d-flex flex-column align-items-center">
        <div
          class="photo-card photo-card__num_b"
        >
          <div class="photo-card__num-text">
            {{ galleryAlbum.countPhoto() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryAlbum from '../../classes/gallery/GalleryAlbum'
import SwGalleryAlbumPopup from './GalleryAlbumPopup'
import GalleryAlbumPopup from '../../mixins/GalleryAlbumPopup'

export default {
  name: 'SwGalleryAlbumInsideView',
  components: { SwGalleryAlbumPopup },
  mixins: [GalleryAlbumPopup],
  props: {
    componentKey: {
      type: String,
      required: true
    },
    galleryAlbum: {
      type: GalleryAlbum,
      required: true
    }
  },

  data () {
    return {
      sliderBpSettings: [
        {
          bpName: 'xs',
          photoNum: 2
        },
        {
          bpName: 'sm',
          photoNum: 5
        },
        {
          bpName: 'md',
          photoNum: 7
        }
      ]
    }
  },

  computed: {
    /**
     * Список изображений для слайдера
     * @returns {AlbumPhoto[]}
     */
    getSliderPhoto () {
      return this.galleryAlbum.photoList.slice(0, this.maxSliderPhotoNum)
    },

    maxSliderPhotoNum () {
      if (this.showAll) {
        return this.galleryAlbum.countPhoto()
      }
      const photoNums = this.sliderBpSettings.map((setting) => { return setting.photoNum })
      return photoNums.reduce(function (a, b) {
        return Math.max(a, b)
      })
    },

    minSliderPhotoNum () {
      if (this.showAll) {
        return this.galleryAlbum.countPhoto()
      }
      const photoNums = this.sliderBpSettings.map((setting) => { return setting.photoNum })
      return photoNums.reduce(function (a, b) {
        return Math.min(a, b)
      })
    }
  },

  methods: {
    getPhotoCssClass (index) {
      const numIndex = index + 1
      const cssClasses = ['col', 'mb-4', 'flex-column', 'align-items-center']
      if (numIndex <= this.minSliderPhotoNum || this.showAll) {
        cssClasses.push('d-flex')
      } else {
        cssClasses.push('d-none')
        for (const setting of this.sliderBpSettings) {
          if (numIndex <= setting.photoNum) {
            cssClasses.push(`d-${setting.bpName}-flex`)
            break
          }
        }
      }
      return cssClasses
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-album-inside-view {
  margin-bottom: -1.5rem;
}
</style>
