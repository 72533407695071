import EducationCategory from '../../../classes/education/EducationCategory'
import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type {EducationCategory[]} */
  educationCategories: []
})

const state = initialState()

const getters = {
  /** @returns {EducationCategory[]} */
  getEducationCategories (state) {
    return state.educationCategories
  }
}

const mutations = {
  ADD_EDUCATION_CATEGORY (state, category) {
    state.educationCategories.push(category)
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  async fetchEducationCategories (context) {
    const apiReadClient =
      new ApiReadClient('/api/education/categories', {}, 'EducationCategory')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const educationCategoryObj of apiReadClient.data) {
        context.commit('ADD_EDUCATION_CATEGORY', educationCategoryObj)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
