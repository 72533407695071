/**
 * Структура команды
 *
 * @module Team
 * @class TeamStructure
 */
class TeamStructure {
  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.description = attributes.description
    this.memberCount = attributes.member_count
  }

  /**
   * Идентификатор структуры
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок структуры
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор структуры
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Описание структуры
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * Количество участников
   * @return {Number}
   */
  get memberCount () {
    return this._memberCount
  }

  set memberCount (value) {
    this._memberCount = value
  }
}

export default TeamStructure
