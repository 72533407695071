/**
 * Ошибка
 *
 * @module Error
 * @class SwError
 */
class SwError {
  constructor (errorObj) {
    this.id = errorObj.id
    this.isShown = false
    this.status = errorObj.status
    this.title = errorObj.title
    this.detail = errorObj.detail
    this.source = errorObj.source
  }

  /**
   * Ошибка была выведена?
   * @return {Boolean}
   */
  get isShown () {
    return this._isShown
  }

  set isShown (value) {
    this._isShown = value
  }

  /**
   * Идентификатор ошибки
   * @return {String}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Статус ошибки
   * @return {String}
   */
  get status () {
    return this._status
  }

  set status (value) {
    this._title = value
  }

  /**
   * Заголовок ошибки
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Детали ошибки
   * @return {String}
   */
  get detail () {
    return this._detail
  }

  set detail (value) {
    this._detail = value
  }

  /**
   * Источник ошибки
   * @return {String}
   */
  get source () {
    return this._source
  }

  set source (value) {
    this._source = value
  }
}

export default SwError
