import AlbumPhoto from '../gallery/AlbumPhoto'
import BlogEvent from '../blog/event/BlogEvent'
import BlogEventCard from '../blog/event/BlogEventCard'
import BlogPost from '../blog/post/BlogPost'
import BlogPostCard from '../blog/post/BlogPostCard'
import BlogTribune from '../blog/tribune/BlogTribune'
import BlogTribuneCard from '../blog/tribune/BlogTribuneCard'
import ClassNotFoundError from './error/ClassNotFoundError'
import EducationCategory from '../education/EducationCategory'
import EducationCourse from '../education/EducationCourse'
import EducationCourseCard from '../education/EducationCourseCard'
import EducationCourseRightHolder from '../education/EducationCourseRightHolder'
import EducationTrajectory from '../education/EducationTrajectory'
import Question from '../education/Question'
import GalleryAlbum from '../gallery/GalleryAlbum'
import Partner from '../partner/Partner'
import Tag from '../tag/Tag'
import TeamMember from '../team/TeamMember'
import TeamStructure from '../team/TeamStructure'
import UndefinedError from './error/UndefinedError'

const classes = {
  AlbumPhoto,
  BlogEvent,
  BlogEventCard,
  BlogPost,
  BlogPostCard,
  BlogTribune,
  BlogTribuneCard,
  EducationCategory,
  EducationCourse,
  EducationCourseCard,
  EducationCourseRightHolder,
  EducationTrajectory,
  GalleryAlbum,
  Partner,
  Question,
  Tag,
  TeamMember,
  TeamStructure,
}

export default function classFactory (type, attributes, relationships = {}, relatedObjects = {}) {
  try {
    if (classes[type]) {
      const ClassName = classes[type]
      const instance = new ClassName(attributes)
      if (relationships && typeof instance.setRelationships === 'function') {
        instance.setRelationships(relationships)
        if (relatedObjects && typeof instance.setRelatedObjects === 'function') {
          instance.setRelatedObjects(relatedObjects)
        }
      }
      return instance
    } else {
      throw new ClassNotFoundError(type)
    }
  } catch (e) {
    if (e instanceof ClassNotFoundError) throw e
    else throw new UndefinedError(e.message)
  }
}
