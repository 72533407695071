import EducationCourseCard from '../../../classes/education/EducationCourseCard'
import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'
import Pagination from '../../../classes/pagination/Pagination'

const initialState = () => ({
  /** @type {EducationCourseCard[]} */
  courseCards: [],

  /** @type {Map} */
  categoriesPagination: new Map(),

  isLoaded: false
})

const state = initialState()

const getters = {
  /**
   * Карточки курсов
   * @param state
   * @return {EducationCourseCard[]}
   */
  getCourseCards (state) {
    return state.courseCards
  },

  /**
   * Карточки курсов, отфильтрованные по slug категории
   * @returns {function(*): T[]}
   * @param state
   */
  getCourseCardsByCategorySlug: (state) => (categorySlug) => {
    return state.courseCards.filter(courseCard => courseCard.category?.slug === categorySlug)
  },

  /**
   * Pagination для определенной категории
   * @param state
   * @returns {Pagination|null}
   */
  getPaginationByCategorySlug: (state) => (categorySlug) => {
    return state.categoriesPagination.get(categorySlug)
  },

  isLoaded (state) {
    return state.isLoaded
  }
}
const mutations = {
  ADD_COURSE_CARD (state, card) {
    state.courseCards.push(card)
  },

  SET_COURSE_CARD_LIST (state, cardList) {
    state.courseCards = cardList
  },

  SET_PAGINATION (state, { categorySlug, pagination }) {
    state.categoriesPagination.set(categorySlug, pagination)
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  SET_IS_LOADED (state, isLoadedState) {
    state.isLoaded = isLoadedState
  }
}
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  async findCourseCards (context, params) {
    context.commit('SET_IS_LOADED', false)
    const apiReadClient =
        new ApiReadClient('/api/education/courses/cards', { params }, 'EducationCourseCard')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const courseCard of apiReadClient.data) {
        context.commit('ADD_COURSE_CARD', courseCard)
      }
      context.commit('SET_PAGINATION', {
        categorySlug: params.get('filter[category_slug]'),
        pagination: apiReadClient.pagination
      })
    }
    context.commit('SET_IS_LOADED', true)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
