/**
 * Информация о постраничном ответе Api
 *
 * @module Pagination
 * @class Pagination
 */

class Pagination {
  constructor (paginationObj) {
    this.total = paginationObj.total
    this.from = paginationObj.from
    this.to = paginationObj.to
    this.perPage = paginationObj.per_page
    this.currentPage = paginationObj.current_page
    this.lastPage = paginationObj.last_page
  }

  /**
   * Общее количество записей
   * @returns {number}
   */
  get total () {
    return this._total
  }

  set total (value) {
    this._total = value
  }

  /**
   * С какой записи представлены данные
   * @return {String}
   */
  get from () {
    return this._from
  }

  set from (value) {
    this._from = value
  }

  /**
   * По какую записи представлены данные
   * @return {String}
   */
  get to () {
    return this._to
  }

  set to (value) {
    this._to = value
  }

  /**
   * Записей на страницу
   * @return {String}
   */
  get perPage () {
    return this._perPage
  }

  set perPage (value) {
    this._perPage = value
  }

  /**
   * Текущая страница
   * @return {Number}
   */
  get currentPage () {
    return this._currentPage
  }

  set currentPage (value) {
    this._currentPage = value
  }

  /**
   * Последняя страница
   * @return {String}
   */
  get lastPage () {
    return this._lastPage
  }

  set lastPage (value) {
    this._lastPage = value
  }

  /**
   * Количество доступных для загрузки записей
   * @return {number}
   */
  countMoreData () {
    return this.total - this.to
  }
}

export default Pagination
