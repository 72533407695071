<template>
  <section id="grants" class="grants component-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
          content-id="grants"
          :title="$t('message.grants.personalized_grant')"
      />
    </div>
    <div class="content-view">
      <div class="content-view__body">
        <div class="d-flex w-100">
          <div class="col-2 grants__logo"/>
          <div class="col-10 d-flex flex-column justify-content-center">
            <div class="grants__title">
              Именная стипендия
            </div>
            <div class="grants__sub-title">
              для коренных малочисленных народов Севера, Сибири и Дальнего Востока
            </div>
          </div>
        </div>

        <div class="grants__text mt-4">
          Приглашаем вас принять участие в конкурсе на получение именных стипендий для лиц, относящихся к коренным
          малочисленным народам Севера, Сибири и Дальнего Востока Российской Федерации, обучающихся в образовательных
          организациях высшего образования, подведомственных Министерству науки и высшего образования Российской
          Федерации, достигших <b>существенных успехов в изучении языков и культур</b> этих народов.
        </div>

        <div class="grants__text mt-4">
          Каждый год Минобрнауки выбирает 10 победителей, которым в течение учебного года выплачивается стипендия в
          размере 10 000 рублей.
        </div>

        <div class="grants__img mt-4">
          <img src="/static/img/grants/ceremonia2023_photo.jpg">
          <div class="grants__text grants__text_light mt-1">
            Фото с награждения победителей конкурса 2023 года
          </div>
        </div>

        <div class="grants__paragraph mt-4">
          Требования к участникам конкурса
        </div>
        <div class="grants__text mt-2">
          Принадлежность к одному из народов:
        </div>
        <div class="grants__nationality mt-2">
          Алеуты, Алюторцы, Вепсы, Долганы, Ительмены, Камчадалы, Кереки, Кеты, Коряки, Кумандинцы, Манси, Нанайцы,
          Нганасаны, Негидальцы, Ненцы, Нивхи, Ороки (ульта), Орочи, Саамы, Селькупы, Сойоты, Тазы, Теленгиты, Телеуты,
          Тофалары (тофа), Тубалары, Тувинцы-тоджинцы, Удэгейцы, Ульчи, Ханты, Челканцы, Чуванцы, Чукчи, Чулымцы, Шорцы,
          Эвенки, Эвены (ламуты), Энцы, Эскимосы, Юкагиры
        </div>
        <div class="grants__text mt-3">
          Основные требования к участникам конкурса:
        </div>
        <div class="grants__text mt-1">
          <ul>
            <li>для студентов 2-го и последующих курсов — закрытие сессий предыдущего учебного года на оценки «хорошо»
              или «отлично»;
            </li>
            <li>наличие достижений в научно-исследовательской или научно-практической деятельности в области сохранения
              самобытной культуры, традиций и обычаев малочисленных народов, организации культурно-просветительских
              мероприятий, освещающих традиционный образ жизни малочисленных народов (3 диплома или сертификата начиная
              с 01.01.2022г.);
            </li>
            <li>публикации в научных изданиях.</li>
          </ul>
        </div>

        <div class="grants__paragraph mt-4">
          Сроки проведения и подачи документов на конкурс 2024/2025 гг.
        </div>
        <div class="grants__text mt-2">
          Конкурс начался – 10.00 (мск) 18 марта 2024 г.<br>
          Сроки окончания приема документов – 15.00 (мск) 18 апреля 2024 г.
        </div>


        <div class="grants__paragraph mt-4">
          Как мы можем вам помочь, если вы студент
        </div>
        <table class="grants__help-table table table-striped">
          <thead>
          <tr>
            <th scope="col" style="width: 40%">Направление мероприятия</th>
            <th scope="col">СФУ<sup style="margin-left: 3px">1</sup></th>
            <th scope="col">из г.&nbsp;Красноярска</th>
            <th scope="col">из другого города России</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>«Первичный» инструктаж конкурсанта</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
          </tr>
          <tr>
            <td>Очное участие в мероприятиях языковой и культурной направленности</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="sup-marker">
                <div class="marker"></div>
                <div><sup>2</sup></div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Online-участие в мероприятиях языковой и культурной направленности</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"></div>
            </td>
          </tr>
          <tr>
            <td>Консультирование по подготовке научных статей</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"></div>
            </td>
          </tr>
          <tr>
            <td>Консультирование по публикации научных статей</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"></div>
            </td>
          </tr>
          <tr>
            <td>Обучения на курсах платформы СНОУВОРД по языковой и культурной направленности</td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"/>
            </td>
            <td>
              <div class="marker"></div>
            </td>
          </tr>
          <tr>
            <td>Сбор документов в деканате, др. подразделениях вуза</td>
            <td>
              <div class="marker"/>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Подготовка электронной версии заявки в рабочем кабинете вуза</td>
            <td>
              <div class="marker"/>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Отправка заявки, пакета документов в Минобрнауки РФ</td>
            <td>
              <div class="marker"/>
            </td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
        <div class="grants__text grants__text_light mt-3">
          <sup>1</sup> Города Красноярск, Саяногорск, Абакан
        </div>
        <div class="grants__text grants__text_light">
          <sup>2</sup> Оплата проезда и проживания в месте проведения мероприятия за счет собственных средств
          конкурсанта
        </div>

        <div class="grants__paragraph mt-4">
          Консультационная служба
        </div>
        <div
            v-for="contact in contacts"
            class="contact mt-3"
        >
          <div class="contact__photo">
            <img :src="`/static/img/grants/contacts/${contact.photo}`">
          </div>
          <div class="d-flex flex-column ml-4">
            <div>
              {{ contact.title }}
            </div>
            <div class="contact__full-name">
              {{ contact.fullName }}
            </div>
            <div class="contact__employee-position">
              {{ contact.employeePosition }}
            </div>
            <div class="contact__link">
              <a :href="`mailto:${contact.eMail}`">
                {{ contact.eMail }}
              </a>
            </div>
            <div v-if="contact.contactPhone" class="contact__link">
              <div>{{ contact.contactPhone }}</div>
              <template v-if="contact.messengers">
                <div v-for="messenger in contact.messengers" class="contact__messenger ml-2">
                  <img :src="`/static/img/icon/messengers/${messenger}.svg`"/>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="grants__text mt-3">
          Принимаем звонки по будням с 9:30 до 16:30 по красноярскому времени.
        </div>
        <div class="grants__text">
          Чтобы подать заявку или проконсультироваться, <a class="sw-link" href="#TalkMe/showTab?id=njw0a4t127wh8sfyyyctzms7qi3wzfdp">заполните
          анкету</a>.
        </div>

        <div class="grants__text grants__text_light mt-5">
          Более подробная информация на  официальной странице <a class="sw-link" href="https://стипендиатроссии.рф/personal2">Именной стипендии</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import SwAssetsPageHeader from '../../components/page/AssetsPageHeader';

export default {
  name: 'SwAtlasView',
  components: {SwAssetsPageHeader},

  data () {
    return {
      contacts: [
        {
          photo: 'Kamalova_photo.png',
          title: 'Руководитель',
          fullName: 'Камалова Элеонора Алексеевна',
          employeePosition: 'Ведущий специалист проектного офиса Института Севера и Арктики СФУ',
          eMail: 'stipendia@snoword.ru',
          contactPhone: '+7 929 335-35-50',
          messengers: ['telegram']
        },
        {
          photo: 'Kuznicova_photo.png',
          title: 'Консультант',
          fullName: 'Кузнецова Елизавета Борисовна',
          employeePosition: 'Специалист проектного офиса Института Севера и Арктики СФУ',
          eMail: 'stipendia@snoword.ru',
          contactPhone: '+7 983 614-30-60',
          messengers: ['telegram']
        },
        {
          photo: 'Astashova_photo.png',
          title: 'Консультант',
          fullName: 'Асташова Ульяна Владимировна',
          employeePosition: 'Специалист проектного офиса Института Севера и Арктики СФУ',
          eMail: 'stipendia@snoword.ru'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.grants {
  .grants__img {
    img {
      max-width: 100%;
    }
  }

  .grants__logo {
    width: 201px;
    height: 201px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("/static/img/grants/logo.png");
  }

  .grants__title {
    font-size: 3.125rem;
    font-weight: bold;
  }

  .grants__sub-title {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .grants__text {
    font-size: 1.625rem;

    &.grants__text_light {
      color: var(--gray-500);
    }
  }

  .grants__paragraph {
    font-size: 2.25rem;
    font-weight: bold;
  }

  .grants__nationality {
    padding: 20px;
    font-size: 1.625rem;
    background-color: #EEEEEE;
  }

  .grants__help-table {
    font-size: 1.625rem;

    th:not(:first-child) {
      text-align: center;
    }

    th, td {
      border: 0;
      vertical-align: middle;
    }

    .sup-marker {
      width: min-content;
      display: flex;
      align-items: center;
      margin: auto;
      padding-left: 10px;
    }

    .marker {
      content: '';
      width: 27px;
      height: 27px;
      margin: auto;
      background: url("/static/img/grants/marker_table.svg") center no-repeat;
      background-size: contain;
    }
  }

  .contact {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    font-size: 1.5rem;

    .contact__photo {
      img {
        width: 280px;
        height: 280px;
        border-radius: 50%;
      }
    }

    .contact__employee-position {
      color: var(--gray-500);
    }

    .contact__full-name {
      font-weight: bold;
    }

    .contact__link {
      display: flex;
      flex-direction: row;
      align-items: center;

      .contact__messenger {
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
</style>
