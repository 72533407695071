<template>
  <section class="about">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
        content-id="about"
        :title="$t('message.about')"
      />
    </div>
    <div class="content-view">
      <div class="content-view__body">
        <template v-if="language === 'ru'">
          <p>Развитие северных и арктических территорий Российской Федерации – это важнейшая стратегическая государственная задача современного периода. Сопровождение и ресурсное обеспечение важнейшего транспортного коридора первой половины XXI века – Северного морского пути – предполагает индустриальное, инфрастурктурное, социальное обновление статуса тех северных и арктических территорий, которые связаны с Северным морским путем.</p>
          <p>Север и Арктика – это территории, где добываются газ, нефть, другие полезные ископаемые, развиваются добывающие и перерабатывающие производства. Здесь сосуществуют различные сообщества – во-первых, это люди, которые проживают в экстремальных условиях веками – коренные малочисленные народы, во-вторых, это люди, которые осваивают эти территории в периоды индустриализации и реиндустриализации, русские старожилы, участники коммунистических и комсомольских строек советского периода, современные постоянные жители северных и арктических поселений, и, в-третьих, люди, которые регулярно приезжают на вахту и, отработав здесь временно, уезжают обратно к себе домой.</p>
          <p>Особой государственной поддержкой в соответствии с Конституцией Российской Федерации пользуются коренные малочисленные народы Севера, Сибири и Дальнего Востока. Многие представители этих народов проживают на территориях с экстремальным климатом и сложным ландшафтом. Многие сохраняют свой традиционный образ жизни, уникальные родные языки и культуры. Российская Федерация взяла на себя обязательство обеспечить и совершенствовать меры государственной поддержки коренным малочисленным народам, ведущим традиционный образ жизни, в том числе, поддерживать сохранение и воспроизводство их родных языков и культур.</p>
          <p>Сибирский федеральный университет – это научно-образовательная организация нового типа, сочетающая научные и образовательные проекты с социальными программами, с культурной инноватикой, с использованием цифровых технологий для сохранения и воспроизводства культурного наследия людей, населяющих и Красноярский край, и другие регионы Российской Федерации.</p>
          <p>В 2021 году наш университет начинает выполнять важнейшее государственное задание, связанное с  реализацией мер, направленных на оказание дополнительной социальной поддержки лицам, относящимся к коренным малочисленным народам Севера, Сибири и Дальнего Востока Российской Федерации, обучающимся в российских образовательных организациях высшего образования по программам, предусматривающим изучение языков и культур этих народов. Это большой комплексный проект, предусматривающий реализацию инновационных образовательных программ для довузовской подготовки абитуриентов, профессиональной ориентации абитуриентов и студентов, дополнительного профессионального образования студентов, которые являются представителями коренных малочисленных народов Севера, Сибири и Дальнего Востока Российской Федерации.</p>
          <p>Целевая аудитория нашего проекта – все абитуриенты и студенты, которые принадлежат к коренным малочисленным народам и стремятся быть максимально полезными для своих родных сообществ, для улучшения качества жизни малочисленных народов, для получения современных компетенций, позволяющих достойно жить в мире высоких технологий и высокоскоростной социальной и культурной динамики.</p>
          <p>Мы надеемся, что наш проект позволит получить российским абитуриентам и студентам доступ к качественной довузовской подготовке, пройти эффективную социальную и культурную адаптацию в высших учебных заведения, приобрести новые дополнительные профессиональные компетенции в сферах здравоохранения, инженерного дела, юриспруденции, социальной работы, лесного хозяйства, сельского хозяйства, педагогики.</p>
          <p>Довузовская подготовка ориентирована на освоение online-курсов по всем предметам Единых государственных экзаменов и ряда предметов, которые призваны дать современные компетенции и способствовать адаптации для жизни и учебы в современном мире. Среди таких предметов выделяется «Североведение», «Введение в родные культуры коренных малочисленных народов Севера, Сибири и Дальнего Востока Российской Федерации». Художественная культура коренных малочисленных народов помещается нами в контекст «Мировой художественной культуры». Особенностью всех этих курсов будет применение VR-технологий, актуальная архитектура нашей цифровой платформы, возможность получить как дистанционное, так и очное образование в Международной Северной школе и Международной бизнес-школе «Арктика» института Севера и Арктики Сибирского федерального университета.</p>
          <p>Нас ожидает большой, интересный и насыщенный путь к новым успехам и новым вершинам.</p>
          <p>Мы приглашаем к сотрудничеству всех, кто готов принять участие в нашем проекте.</p>
          <p>Мы надеемся, что коренные малочисленные народы Севера, Сибири и Дальнего Востока получать актуальное и интересное им образование, которое позволит сделать их традиционную жизнь достойной, успешной, современной, соответствующей самым высоким мировым стандартам качества жизни.</p>
        </template>
        <template v-else>
          <p>Today, the development of the northern and arctic territories of the Russian Federation is the most crucial strategic state task. Maintenance and resource support of the Northern Sea Route, a key transport corridor of the first half of the 21st century, presupposes an industrial, infrastructural, and social renewal of the status of the northern and Arctic territories adjacent to the Northern Sea Route.</p>
          <p>
            The North and the Arctic are territories where gas, oil and other minerals are extracted and where mining and processing companies are developing. Various communities coexist here:
            <ul>
              <li>Indigenous small-numbered peoples who have lived in extreme conditions for centuries.</li>
              <li>People who develop these territories during the industrialization and deindustrialization periods, Russian old-timers, participants in the communist and Komsomol construction projects of the Soviet period, and modern permanent residents of northern and arctic settlements.</li>
              <li>People who regularly come to shift-works and, having worked here temporarily, go back to their homes.</li>
            </ul>
          </p>
          <p />
          <p>The indigenous peoples of the North, Siberia and the Far East are eligible for special state support following the Constitution of the Russian Federation. Many representatives of these peoples live in areas with extreme climates and complex landscapes. Many preserve their traditional lifestyles, unique native languages and cultures. The Russian Federation has undertaken a commitment to provide and improve state support measures for indigenous peoples leading their traditional way of life, including contribution to preservation and reproduction of their native languages and cultures.</p>
          <p>Siberian Federal University is a scientific and educational organization of a new type, combining scientific and educational projects with social programs and cultural innovation, using digital technologies to preserve and reproduce the cultural heritage of people living in Krasnoyarsk Territory and other regions of the Russian Federation.</p>
          <p>In 2021, our university begins to fulfil the most important state task related to the implementation of measures aimed at providing additional social support to people belonging to the indigenous peoples of the North, Siberia and the Far East of Russia and studying at Russian higher education institutions under programs that envisage studying languages and cultures of these peoples. It is a large comprehensive project that provides for the implementation of innovative educational programs for pre-university training of applicants, vocational guidance of applicants and students, additional professional education for students who are representatives of the indigenous peoples of the North, Siberia and the Far East of the Russian Federation.</p>
          <p>The target group of our project is all applicants and students who belong to indigenous small-numbered peoples and strive to be as helpful as possible for their native communities, want to improve the quality of life of small-numbered peoples and obtain modern competencies that allow them to live with dignity in the world of advanced technologies and high-speed social and cultural dynamics.</p>
          <p>We hope that our project will allow Russian applicants and students to get access to high-quality pre-university training, to undergo effective social and cultural adaptation in higher education institutions, and help acquire new additional professional competencies in the fields of health care, engineering, law, social work, forestry, agriculture, economy, and pedagogy.</p>
          <p>The pre-university training is focused on online courses in all subjects of the Unified State Examinations and a number of subjects designed to give modern competencies and facilitate adaptation for life and study in the contemporary world. Northern Studies and Introduction to the Native Cultures of the Indigenous Peoples of the North, Siberia and the Far East of the Russian Federation stand out among such subjects. The program places the Artistic Culture of the indigenous small-numbered peoples in the context of the World Artistic Culture. Special features of these courses will be the use of VR technologies, the current architecture of our digital platform, the opportunity to receive both distance and in-person education at the International Northern School and the Arctic International Business School (School of the North and the Arctic, Siberian Federal University).</p>
          <p>A long exciting, and eventful path to new successes and new heights awaits.</p>
          <p>We invite everyone ready to take part in our project to cooperate.</p>
          <p>We hope that the indigenous small-numbered peoples of the North, Siberia and the Far East will receive relevant and interesting knowledge which will make their traditional life more modern and corresponding to the highest world standards of quality of life.</p>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../components/page/AssetsPageHeader'
export default {
  name: 'AboutView',
  components: { SwAssetsPageHeader },

  computed: {
    language () {
      return this.$store.getters['language/getLanguage']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.about {
  p:not(:last-child) {
    margin-bottom: 35px;

    @media screen and (max-width: 1280px) {
      margin-bottom: 2.73vw;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 21px;
    }
  }

  p,
  ul {
    font-size: 1.25vw;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 0.88rem, 1.25rem);
    }
  }

}
</style>
