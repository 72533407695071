<template>
  <div class="page-chapter-title">
    <div class="page-chapter-title__main">
      <slot name="main" />
    </div>
    <div class="page-chapter-title__sub">
      <slot name="sub" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwPageChapterTitle'
}
</script>

<style lang="scss" scoped>
@import "resources/sass/utils";

.page-chapter-title {
  margin-bottom: 71px;

  @media screen and (max-width: 1280px) {
    margin-bottom: 5.55vw;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 41px;
  }

  .page-chapter-title__main {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: var(--page-chapter-title-main);
    letter-spacing: -0.02em;
    font-feature-settings: 'kern' off;

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.25rem, 2rem);
    }

    @media screen and (max-width: 768px) {
      text-align: center;
      font-weight: normal;
    }
  }

  .page-chapter-title__sub {
    display: block;
    margin-top: 10px;
    font-size: 1.5rem;
    color: var(--page-chapter-title-sub);

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 1.13rem, 1.5rem);
    }

    @media screen and (max-width: 768px) {
      margin-top: 17px;
      text-align: center;
    }
  }
}
</style>
