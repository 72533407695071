/**
 * @class Question
 */
class Question {
  constructor (attributes) {
    this.id = attributes.id
    this.question = attributes.question
    this.description = attributes.description
    this.featuredImage = attributes.featured_image
  }

  /**
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  set question (value) {
    this._question = value
  }

  /**
   * @return {String}
   */
  get question () {
    return this._question
  }

  /**
   * @return {String}
   */
  get description () {
    return this._description
  }

  set description (value) {
    this._description = value
  }

  /**
   * Родительская категория
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }
}

export default Question
