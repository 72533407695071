import Tag from '../../tag/Tag'
import GalleryAlbum from '../../gallery/GalleryAlbum'

/**
 * Карточка события
 *
 * @module Blog
 * @class BlogEventCard
 */

class BlogEventCard {
  dateTimeFormatOptions () {
    return { year: 'numeric', month: 'long', day: 'numeric' }
  };

  constructor (attributes) {
    this.id = attributes.id
    this.title = attributes.title
    this.slug = attributes.slug
    this.featuredImage = attributes.featured_image
    this.startDate = attributes.start_date
    this.endDate = attributes.end_date
    this.location = attributes.location
    this.organization = attributes.organization
    this.excerpt = attributes.excerpt
  }

  /**
   * Идентификатор события
   * @returns {number}
   */
  get id () {
    return this._id
  }

  set id (value) {
    this._id = value
  }

  /**
   * Заголовок события
   * @return {String}
   */
  get title () {
    return this._title
  }

  set title (value) {
    this._title = value
  }

  /**
   * Текстовый идентификатор события
   * @return {String}
   */
  get slug () {
    return this._slug
  }

  set slug (value) {
    this._slug = value
  }

  /**
   * Короткое описание события
   * @return {String}
   */
  get excerpt () {
    return this._excerpt
  }

  set excerpt (value) {
    this._excerpt = value
  }

  /**
   * Заглавное изображение события
   * @return {String}
   */
  get featuredImage () {
    return this._featuredImage
  }

  set featuredImage (value) {
    this._featuredImage = value
  }

  /**
   * Дата начала события
   * @return {Date}
   */
  get startDate () {
    return this._startDate
  }

  set startDate (value) {
    this._startDate = new Date(value)
  }

  /**
   * Дата окончания события
   * @return {Date}
   */
  get endDate () {
    return this._endDate
  }

  set endDate (value) {
    this._endDate = new Date(value)
  }

  /**
   * Место проведения события
   * @return {String}
   */
  get location () {
    return this._location
  }

  set location (value) {
    this._location = value
  }

  /**
   * Организация
   * @return {String}
   */
  get organization () {
    return this._organization
  }

  set organization (value) {
    this._organization = value
  }

  /**
   * Список тегов, связанных с событием
   * @return {Tag[]}
   */
  get tagList () {
    return this._tagList
  }

  setTagList (relatedData) {
    this._tagList = []
    const tagsData = this.relationships?.tags?.data
    if (tagsData) {
      for (const tagObj of tagsData) {
        if (tagObj.type === 'Tag') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof Tag && tagObj.id === relatedObj.id) {
              this._tagList.push(relatedObj)
            }
          }
        }
      }
    }
  }

  /**
   * Связанные объектв
   * @return {String}
   */
  get relationships () {
    return this._relationships
  }

  setRelationships (value) {
    this._relationships = value
  }

  setRelatedObjects (relatedData) {
    this.setTagList(relatedData)
    this.setGalleryAlbum(relatedData)
  }

  /**
   * Альбомы, связанные с событием
   * @return {GalleryAlbum[]}
   */
  get galleryAlbumList () {
    return this._galleryAlbumList
  }

  /**
   * Первый альбомы, связанные с событием
   * @return {GalleryAlbum|null}
   */
  getFirstGalleryAlbum () {
    if (this.hasGalleryAlbum()) {
      return this._galleryAlbumList[0]
    }
  }

  setGalleryAlbum (relatedData) {
    this._galleryAlbumList = []
    const albumsData = this.relationships?.albums?.data
    if (albumsData) {
      for (const albumObj of albumsData) {
        if (albumObj.type === 'GalleryAlbum') {
          for (const relatedObj of relatedData) {
            if (relatedObj instanceof GalleryAlbum && albumObj.id === relatedObj.id) {
              this._galleryAlbumList.push(relatedObj)
            }
          }
        }
      }
    }
  }

  /**
   * К события привязаны альбомы?
   * @returns {boolean}
   */
  hasGalleryAlbum () {
    return this.galleryAlbumList.length > 0
  }

  /**
   * Url на уменьшенное изображение
   * @return {string}
   */
  featuredImageThumbnailUrl () {
    const url = new URL(this.featuredImage)
    const path = url.pathname.split('/')
    path.splice(path.length - 1, 0, 'thumbs')
    return url.protocol + '//' + url.hostname + path.join('/')
  }

  /**
   * Дата начала события в languageCode формате
   * @param {string} languageCode
   * @return {string}
   */
  getLocaleStartDate (languageCode) {
    return this.startDate.toLocaleDateString(languageCode, this.dateTimeFormatOptions()).replace(/\s*г\./, '')
  }

  /**
   * Дата окончания события в languageCode формате
   * @param {string} languageCode
   * @return {string}
   */
  getLocaleEndDate (languageCode) {
    return this.endDate.toLocaleDateString(languageCode, this.dateTimeFormatOptions()).replace(/\s*г\./, '')
  }

  _getMonth (languageCode, monthIndex) {
    const months = {
      'ru-RU': [
        'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'
      ],
      'en-US': [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
      ]
    }
    return months[languageCode][monthIndex]
  }

  getLocaleIntervalDates (languageCode = 'ru-RU') {
    if (this.startDate >= this.endDate) {
      return this.getLocaleStartDate(languageCode)
    }
    const startDateDay = this.startDate.getDate()
    const endDateDay = this.endDate.getDate()
    const startDateMonth = this.startDate.getMonth()
    const endDateMonth = this.endDate.getMonth()
    const startDateYear = this.startDate.getFullYear()
    const endDateYear = this.endDate.getFullYear()
    if (startDateYear !== endDateYear) {
      return `${this.getLocaleStartDate(languageCode)} - ${this.getLocaleEndDate(languageCode)}`
    }

    if (startDateMonth !== endDateMonth) {
      return `${startDateDay} ${this._getMonth(languageCode, startDateMonth)} 
        - ${endDateDay} ${this._getMonth(languageCode, endDateMonth)} ${startDateYear}`
    }
    return `${startDateDay} - ${endDateDay} ${this._getMonth(languageCode, startDateMonth)} ${startDateYear}`
  }
}

export default BlogEventCard
