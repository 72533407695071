<template>
  <section class="navigator-result content-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
          content-id="navigator"
          :title="$t('message.navigator.navigator')"
          :breadcrumbs="breadcrumbs"
      />
    </div>
    <div class="content-view__body flex-fill">
      <div
          v-if="!isTrajectoriesLoaded"
          v-loading="!isTrajectoriesLoaded"
          class="content-waiting-loader"
      />
      <div v-else>
        <div class="navigator-result__title">
          {{ $t('message.navigator.test_result') }}
        </div>

        <template v-if="suitableTrajectories.length > 0">
          <div class="navigator-result__hint mt-3">
            {{ $t('message.navigator.result_suitable_trajectories') }}
          </div>
          <div class="navigator-result__hint mb-4">
            {{ $t('message.navigator.result_choose_one_trajectory') }}
          </div>

          <div class="navigator-result__trajectories">
            <sw-navigator-result-trajectory
                v-for="trajectory in suitableTrajectories"
                :key="`navigator-result-trajectory-${trajectory.id}`"
                :trajectory="trajectory"
            />
          </div>
        </template>

        <div v-else class="navigator-result__empty">
          Результаты вашего теста не выявили у вас интереса к предложенным траекториям обучения.<br>
          Вы можете пройти тест ещё раз или выбрать онлайн курс для обучения самостоятельно на платформе
          <a href="https://edu.snoword.ru/">edu.snoword.ru</a>
        </div>

        <div class="row justify-content-center mt-5">
          <sw-navigator-restart-test/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../../components/page/AssetsPageHeader'
import SwNavigatorTrajectoryTest from '../../../components/education/navigator/NavigatorTrajectoryTest';
import SwNavigatorRestartTest from '../../../components/education/navigator/RestartTest';
import SwNavigatorResultTrajectory from '../../../components/education/navigator/NavigatorResultTrajectory';

export default {
  name: 'SwNavigatorResultView',
  components: {SwNavigatorResultTrajectory, SwNavigatorRestartTest, SwNavigatorTrajectoryTest, SwAssetsPageHeader},

  data () {
    return {
      isTestComplete: false,
      currIndex: 0,
      scores: new Map()
    }
  },

  computed: {
    educationTrajectories () {
      return this.$store.getters['navigator/getTrajectories']
    },

    isTrajectoriesLoaded () {
      return this.$store.getters['navigator/isLoaded']
    },

    storedScores () {
      return this.$store.getters['navigator/getScores']
    },

    currTrajectory () {
      return this.educationTrajectories[this.currIndex] ?? undefined;
    },

    hasResult () {
      return this.$store.getters['navigator/hasResult']
    },

    sortedScores () {
      return new Map(Array.from(this.storedScores).sort((a, b) => b - a));
    },

    suitableTrajectories () {
      let trajectories = []
      this.sortedScores.forEach((value, key) => {
        if (value > 0) {
          trajectories.push(this.educationTrajectories.find((educationTrajectory) => educationTrajectory.id === key))
        }
      })
      return trajectories.slice(0, 3)
    },

    breadcrumbs () {
      return [
        {
          title: this.$t('message.navigator.navigator'),
          route: null
        },
        {
          title: this.$t('message.navigator.result'),
          route: null
        }
      ]
    }
  },

  mounted () {
    if (!this.hasResult) {
      this.$router.push({name: 'navigatorView'})
    }

    if (this.educationTrajectories.length === 0) {
      this.fetchTrajectories()
    }
  },

  methods: {
    fetchTrajectories () {
      const params = new URLSearchParams()
      params.append('include', 'educationCourses,questions')

      this.$store.dispatch('navigator/fetchTrajectories', params)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../sass/utils";

.navigator-result {
  .content-view__body {
    .navigator-result__title {
      font-size: 2rem;
      font-weight: bold;
    }

    .navigator-result__hint,
    .navigator-result__empty {
      font-size: 1.5rem;
      color: var(--sw-primary-text-color-light-3);
    }

    .navigator-result__trajectories {
      .trajectory:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
