<template>
  <div class="tag-list">
    <div class="row no-gutters d-flex flex-row justify-content-center">
      <template
        v-for="(tag, index) in getTagList()"
        :key="`${componentKey}-change-tag-${index}`"
      >
        <div
          v-if="index < visibleTagNum"
          class="tag-list__tag"
        >
          <div
            :class="getTagBtnClass(tag)"
            role="button"
            aria-pressed="true"
            @click="$emit('change:tag', tag)"
          >
            #{{ tag.title }}
          </div>
        </div>
      </template>
      <div class="w-100 d-md-none" />
      <div
        v-if="moreTagNum > 0"
        class="tag-list__more-btn"
      >
        <div
          class="tag-list__more-btn-text"
          @click="showAll()"
        >
          {{ $t('message.more') }} {{ moreTagNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../../classes/tag/Tag'

export default {
  name: 'SwTagList',

  props: {
    /** @type {{ new (): Tag[] }} */
    tagList: {
      type: Array,
      required: true
    },
    /** @type Tag */
    currTag: {
      type: Tag
    },
    componentKey: {
      type: String,
      required: true
    },
    defVisibleTagNum: {
      type: Number,
      default: 4
    }
  },
  emits: ['change:tag'],

  data () {
    return {
      visibleTagNum: 0
    }
  },

  computed: {
    moreTagNum () {
      return this.tagList.length - this.visibleTagNum
    }
  },

  watch: {
    currTag: {
      immediate: true,
      deep: true,
      /** @param {Tag|null} newTag */
      handler (newTag) {
        if (!this.isTagVisible(newTag)) this.showAll()
      }
    }
  },

  created () {
    this.visibleTagNum = this.defVisibleTagNum
    if (!this.isTagVisible(this.currTag)) this.showAll()
  },

  methods: {
    /**
     * Список тегов
     * @return Tag[]
     */
    getTagList () {
      return this.tagList
    },

    /**
     * CSS класс для кнопки навигации между тегами галереи
     * @param {Tag} tag
     */
    getTagBtnClass (tag) {
      const classList = ['sw-btn', 'sw-btn_tag', 'sw-btn_bold']
      if (this.currTag && tag.slug === this.currTag.slug) {
        classList.push('sw-btn_tag_active')
      }
      return classList
    },

    /**
     * Проверка видимости тега на странице
     * @param {Tag} tag
     * @return {boolean}
     */
    isTagVisible (tag) {
      if (tag && this.moreTagNum > 0) {
        const tagSlugList = this.tagList.map(item => item.slug)
        if (tagSlugList.indexOf(tag.slug) > this.visibleTagNum) {
          return false
        }
      }
      return true
    },

    showAll () {
      this.visibleTagNum = this.tagList.length
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  width: 100%;
  max-width: 1232px;
  margin: -8px 0 24px 0;
  padding-bottom: 24px;
  border-bottom: 0.5px solid var(--tag-bottom-border);

  @media screen and(max-width: 1280px) {
    width: calc(100% - 16px);
  }

  @media screen and(max-width: 768px) {
    padding-bottom: 16px;
  }

  .tag-list__more-btn {
    height: 40px;
    margin: 8px 0 0 38px;
    font-size: 1rem;
    color: var(--tag-list-more-btn);

    @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-left: 0;
      font-size: 0.875rem;
    }

    .tag-list__more-btn-text {
      padding: 8px 8px;
      cursor: pointer;

      &:hover {
        color: var(--tag-list-more-hover);

        &::after {
          background: transparent url('/static/img/control/down-bracket-hover.svg') no-repeat center;
          background-size: contain;
        }
      }

      &::after {
        display: inline-block;
        content: '';
        width: 17px;
        height: 11px;
        margin-left: 8px;
        background: transparent url('/static/img/control/down-bracket.svg') no-repeat center;
        background-size: contain;

        @media screen and (max-width: 768px) {
          width: 12px;
          height: 8px;
        }
      }
    }
  }

  .tag-list__tag {
    margin: 8px 4px 0 4px;

    @media screen and (max-width: 768px) {
      margin: 8px 3px 0 3px;
    }

    .tag-list__btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 155px;
      height: 40px;
      padding: 8px 16px;
      background-color: var(--tag-bg);
      border-radius: 16px;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--tag-color);

      @media screen and(max-width: 768px) {
        min-width: auto;
        height: 32px;
        padding: 8px 10px;
        font-size: 0.625rem;
        line-height: 1rem;
      }

      &.tag-list__btn_active {
        background-color: var(--tag-active-bg);
        color: var(--tag-active-color);

        &:hover {
          background-color: var(--tag-active-bg);
          color: var(--tag-active-color);
        }

        &::after {
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          background-image: url("/static/img/icon/cross-light.svg");
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: var(--tag-hover-bg);
        color: var(--tag-hover-color);
      }
    }
  }
}
</style>
