<template>
  <div
    :id="`blog-post-${postCard.slug}`"
    class="sw-card sw-card_post"
  >
    <div
      class="sw-card__featured-image sw-card__featured-image_clickable"
      :style="{ backgroundImage: cardBgImage }"
      @click="$router.push({name: 'blogPostView', params: { postIdentifier: postCard.slug }})"
    >
      <div class="date-block date-block_s">
        {{ postCard.getLocalePublishedAtDate(languageCode) }}
      </div>
      <div class="sw-card__title-text sw-card__title-text_light sw-card__title-text_bold">
        {{ postCard.title }}
      </div>
    </div>
    <div
      class="sw-card_post__excerpt"
      v-html="postCard.excerpt"
    />
    <sw-selected-tag-list
      v-if="postCard.tagList.length"
      :component-key="`blog-post-card-${postCard.slug}`"
      :tag-list="postCard.tagList"
      @change:tag="$emit('change:tag', $event)"
    />
  </div>
</template>
<script>

import SwSelectedTagList from '../../tag/SelectedTagList'
import BlogPostCard from '../../../classes/blog/post/BlogPostCard'

export default {
  name: 'SwBlogPostCard',
  components: { SwSelectedTagList },
  props: {
    postCard: {
      type: BlogPostCard,
      required: true
    }
  },
  emits: ['change:tag'],

  computed: {
    cardBgImage () {
      return `linear-gradient(180deg, var(--blog-post-card-main-image-start-gradient) 0%,
        var(--blog-post-card-main-image-end-gradient) 100%),
        url('${this.postCard.featuredImage}')`
    },

    languageCode () {
      return this.$store.getters['language/getLanguageCode']
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.sw-card_post {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media screen and (min-width: 569px) and (max-width: 1280px) {
    width: 98%;
  }

  @media screen and (max-width: 768px) {
    max-width: 351px;
  }

  .sw-card__featured-image {
    height: 216px;

    @media screen and (max-width: 768px) {
      height: 192px;
    }
  }

  .sw-card_post__excerpt {
    padding: 12px 8px 0 8px;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: var(--blog-tribune-card-excerpt);

    @media screen and (max-width: 1280px) {
      @include fluid-font-size(48rem, 80rem, 0.75rem, 0.9375rem);
    }

    @media screen and (max-width: 768px) {
      line-height: 1rem;
    }
  }
}
</style>
