import GalleryAlbum from '../../../classes/gallery/GalleryAlbum'
import SwError from '../../../classes/error/Error'
import ApiReadClient from '../../../classes/api/ApiReadClient'

const initialState = () => ({
  /** @type {GalleryAlbum[]} */
  albums: [],

  /** @type {Pagination|null} */
  pagination: null
})
const state = initialState()

const getters = {
  /**
   * Альбомы галереи
   * @param state
   * @return {GalleryAlbum[]}
   */
  getAlbums (state) {
    return state.albums
  },

  /**
   * @param state
   * @returns {Pagination|null}
   */
  getPagination (state) {
    return state.pagination
  }
}
const mutations = {
  ADD_ALBUM (state, album) {
    state.albums.push(album)
  },

  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  SET_PAGINATION (state, pagination) {
    state.pagination = pagination
  },

  RESET_PAGINATION (state) {
    state.pagination = null
  }
}
const actions = {
  reset ({ commit }) {
    commit('RESET')
  },

  async findAlbums (context, params) {
    const apiReadClient =
      new ApiReadClient('/api/gallery/albums', { params }, 'GalleryAlbum')
    await apiReadClient.execute()
    if (apiReadClient.hasError()) {
      for (const error of apiReadClient.errors) {
        context.dispatch('error/addError', new SwError(error), { root: true })
      }
    } else {
      for (const galleryAlbum of apiReadClient.data) {
        context.commit('ADD_ALBUM', galleryAlbum)
      }
      context.commit('SET_PAGINATION', apiReadClient.pagination)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
