<template>
  <section class="navigator-test content-view">
    <div class="row w-100 justify-content-center no-gutters">
      <sw-assets-page-header
          content-id="navigator"
          :breadcrumbs="breadcrumbs"
          :title="$t('message.navigator.navigator')"
      />
    </div>
    <div
        v-if="!isTrajectoriesLoaded"
        v-loading="!isTrajectoriesLoaded"
        class="content-waiting-loader content-view__body"
    />
    <div v-else class="content-view__body" id="navigator_test_content">
      <sw-navigator-trajectory-test
          :question-index="currQuestionIndex"
          :trajectory="currTrajectory"
          @trajectory:question:answered="questionAnswered"
          @trajectory:test:completed="trajectoryTestCompleted"
      />
      <div class="w-100 mt-4 navigator-progress">
        <div class="navigator-progress__step">
          {{ $t('message.navigator.navigator_test_step', {step: currQuestionIndex, steps: questionsNum}) }}
        </div>
        <div class="navigator-progress__bar">
          <el-progress
              :stroke-width="24"
              :percentage="progressFill"
              :show-text="false"
          />
        </div>
      </div>
<!--      <div class="row justify-content-center mt-5">
        <sw-navigator-restart-test/>
      </div>-->
    </div>
  </section>
</template>

<script>
import SwAssetsPageHeader from '../../../components/page/AssetsPageHeader'
import SwNavigatorTrajectoryTest from '../../../components/education/navigator/NavigatorTrajectoryTest';
import SwNavigatorRestartTest from '../../../components/education/navigator/RestartTest';

export default {
  name: 'SwNavigatorTestView',
  components: {SwNavigatorRestartTest, SwNavigatorTrajectoryTest, SwAssetsPageHeader},

  data () {
    return {
      currTrajectoryIndex: 0,
      currQuestionIndex: 1,
      scores: new Map()
    }
  },

  computed: {
    educationTrajectories () {
      return this.$store.getters['navigator/getTrajectories']
    },

    trajectoriesWithQuestions () {
      return this.educationTrajectories.filter(trajectory => trajectory.questions?.length)
    },

    isTrajectoriesLoaded () {
      return this.$store.getters['navigator/isLoaded']
    },

    hasResult () {
      return this.$store.getters['navigator/hasResult']
    },

    currTrajectory () {
      return this.trajectoriesWithQuestions[this.currTrajectoryIndex] ?? undefined;
    },

    questionsNum () {
      return this.$store.getters['navigator/questionsNum']
    },

    progressFill () {
      return Math.round((this.currQuestionIndex - 1) / this.questionsNum * 100);
    },

    breadcrumbs () {
      return [
        {
          title: this.$t('message.navigator.navigator'),
          route: null
        },
        {
          title: this.$t('message.navigator.test'),
          route: null
        }
      ]
    }
  },

  mounted () {
    if (this.hasResult) {
      this.$router.push({name: 'navigatorResultView'})
    }
  },

  created () {
    if (!this.isTrajectoriesLoaded) {
      this.fetchTrajectories()
    }
  },

  methods: {
    trajectoryTestCompleted (data) {
      this.scores.set(data.id, data.score)

      if (this.currTrajectoryIndex === this.trajectoriesWithQuestions.length - 1) {
        this.$store.dispatch('navigator/setScores', this.scores)
        this.$router.push({name: 'navigatorResultView'})
      } else {
        this.currTrajectoryIndex++;
      }
    },

    questionAnswered () {
      this.currQuestionIndex++
    },

    fetchTrajectories () {
      const params = new URLSearchParams()
      params.append('include', 'educationCourses,questions')

      this.$store.dispatch('navigator/fetchTrajectories', params)
    }
  }
}
</script>

<style lang="scss">
.navigator-test {
  .navigator-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .navigator-progress__step {
      height: 30px;
      text-align: center;
      font-size: 1.125rem;
      color: var(--sw-primary-text-color-light-3);
    }

    .navigator-progress__bar {
      width: 100%;

      .el-progress-bar__inner {
        background-color: var(--blue-500);
      }
    }
  }
}
</style>
