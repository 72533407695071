<template />

<script>
import SwError from '../../classes/error/Error'

export default {
  name: 'SwError',

  props: {
    duration: {
      type: Number,
      default: 3000
    }
  },

  computed: {
    /** @returns {SwError[]} */
    errorList () {
      return this.$store.getters['error/getUnShownErrors']
    }
  },

  watch: {
    errorList: {
      handler: function () {
        this.errorNotification()
      },
      deep: true
    }
  },

  methods: {
    errorNotification () {
      let offset = 0
      for (const error of this.errorList) {
        this.$notify.error({
          title: error.title,
          message: error.detail,
          offset: offset,
          duration: this.duration
        })
        offset += 100
        this.$store.dispatch('error/setErrorIsShown', error.id)
      }
    }
  }
}
</script>

<style scoped>

</style>
