<template>
  <div
    v-if="albumVisible"
    :key="`gallery-album-popup-${galleryAlbum.slug}`"
    class="gallery-album-popup"
  >
    <el-dialog
      :key="`gallery-album-popup-dialog-${galleryAlbum.slug}`"
      v-model="albumVisible"
      :lock-scroll="true"
      :title="galleryAlbum.title"
      width="100%"
    >
      <div
        class=""
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div class="gallery-album-popup__current-image">
        <el-image
          :src="currentPhoto.photoUrl"
          lazy
          @load="setImageLoadedStatus(true)"
        />
      </div>
      <div class="gallery-album-popup__footer">
        <div class="gallery-album-popup__image-title">
          {{ currentPhoto.title }}
        </div>
        <div class="gallery-album-popup__control">
          <template v-if="currentPhotoIndex">
            <div
              class="gallery-album-popup__control-btn gallery-album-popup__control-btn_prev"
              :class="{ 'gallery-album-popup__control-btn_disabled' : !imageLoaded }"
              @click="decrementPhotoIndex()"
            />
          </template>
          <template v-else>
            <div class="gallery-album-popup__empty-control-btn" />
          </template>
          <div class="gallery-album-popup__photo-num">
            {{ (currentPhotoIndex + 1) }}<sup>{{ galleryAlbum.countPhoto() }}</sup>
          </div>
          <template v-if="hasMorePhoto">
            <div
              class="gallery-album-popup__control-btn gallery-album-popup__control-btn_next"
              :class="{ 'gallery-album-popup__control-btn_disabled' : !imageLoaded }"
              @click="incrementPhotoIndex()"
            />
          </template>
          <template v-else>
            <div class="gallery-album-popup__empty-control-btn" />
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import GalleryAlbum from '../../classes/gallery/GalleryAlbum'
import AlbumPhoto from '../../classes/gallery/AlbumPhoto'

export default {
  name: 'SwGalleryAlbumPopup',

  props: {
    galleryAlbum: {
      type: GalleryAlbum,
      required: true
    },
    defAlbumVisibility: {
      type: Boolean,
      default: false
    },
    selectedPhotoIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['change:visible:hide'],

  data () {
    return {
      albumVisible: null,
      currentPhotoIndex: 0,
      /** @type AlbumPhoto */
      currentPhoto: null,
      imageLoaded: false
    }
  },

  computed: {
    hasMorePhoto () {
      return (this.currentPhotoIndex + 1) < this.galleryAlbum.countPhoto()
    }
  },

  watch: {
    defAlbumVisibility () {
      this.albumVisible = this.defAlbumVisibility
    },

    albumVisible () {
      if (this.albumVisible === false) {
        this.$emit('change:visible:hide')
      }
    },

    currentPhotoIndex () {
      this.changeCurrentPhoto(this.currentPhotoIndex)
    },

    selectedPhotoIndex () {
      this.changeCurrentPhoto(this.selectedPhotoIndex)
    }
  },

  created () {
    this.albumVisible = this.defAlbumVisibility
    this.changeCurrentPhoto(this.selectedPhotoIndex)
  },

  methods: {
    setImageLoadedStatus (status) {
      this.imageLoaded = status
    },

    changeCurrentPhoto (index) {
      if (this.galleryAlbum.hasPhoto(index)) {
        this.setImageLoadedStatus(false)
        this.currentPhotoIndex = index
        this.currentPhoto = this.galleryAlbum.getPhoto(index)
      }
    },

    incrementPhotoIndex () {
      if (this.imageLoaded) {
        this.currentPhotoIndex++
      }
    },

    decrementPhotoIndex () {
      if (this.imageLoaded) {
        this.currentPhotoIndex--
      }
    }
  }
}
</script>

<style lang="scss">
@import "resources/sass/utils";

.gallery-album-popup {
  position: fixed;
  z-index: 1000;

  .el-overlay {
    padding: 0 24px;

    @media screen and (max-width: 1280px) {
      padding: 0 1.88vw;
    }

    @media screen and (max-width: 568px) {
      padding: 0;
    }

    .el-dialog {
      max-width: 1026px;
      height: 744px;
      background-color: var(--gallery-album-popup-bg);
      border-radius: 40px;

      @media screen and (max-width: 1280px) {
        width: 100%;
        height: auto;
        border-radius: 3.13vw;
      }

      @media screen and (max-width: 568px) {
        height: 100%;
        padding: 0;
        margin: 0 !important;
        border-radius: 0;
        overflow: hidden;
      }

      .el-dialog__header {
        padding: 32px;

        .el-dialog__title {
          display: block;
          max-width: 95%;
          font-size: 1.5rem;
          color: var(--gallery-album-popup-text);

          @media screen and (max-width: 1280px) {
            @include fluid-font-size(48rem, 80rem, 1.25rem, 1.5rem);
          }
        }

        .el-dialog__headerbtn {
          top: 26px;
          right: 32px;
          font-size: 1.5rem;

          @media screen and (max-width: 1280px) {
            @include fluid-font-size(48rem, 80rem, 1.25rem, 1.5rem);
          }

          @media screen and (max-width: 786px) {
            top: 30px;
          }
        }
      }

      .el-dialog__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 32px 32px 32px;

        .gallery-album-popup__empty-control-btn,
        .gallery-album-popup__control-btn {
          content: '';
          display: flex;
          justify-content: center;
          align-items: center;
          width: 88px;
          height: 72px;
          border-radius: 24px;

          @media screen and (max-width: 1280px) and (min-width: 768px) {
            width: 6.88vw;
            height: 5.63vw;
            border-radius: 1.88vw;
          }

          @media screen and (max-width: 768px) {
            width: 8.33vw;
            height: 6.25vw;
            border-radius: 2.1vw;
          }

          @media screen and (max-width: 568px) {
            width: 88px;
            height: 72px;
            border-radius: 24px;
          }
        }

        .gallery-album-popup__control-btn {
          content: '';
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--gallery-album-popup-control-bg);
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }

          &.gallery-album-popup__control-btn_disabled {
            opacity: 0.5;

            &.gallery-album-popup__control-btn_prev,
            &.gallery-album-popup__control-btn_next {
              &::before {
                width: 20px;
                height: 20px;
                border: 2px solid #fff;
                border-top: 2px solid transparent;
                border-radius: 50%;
                background: transparent;
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
              }
            }
          }

          &.gallery-album-popup__control-btn_prev {
            &::before {
              content: '';
              width: 12px;
              height: 24px;
              background: transparent url('/static/img/control/arrow-prev.svg') no-repeat center;
              background-size: contain;
            }
          }

          &.gallery-album-popup__control-btn_next {
            &::before {
              content: '';
              width: 12px;
              height: 24px;
              background: transparent url('/static/img/control/arrow-next.svg') no-repeat center;
              background-size: contain;
            }
          }
        }

        .gallery-album-popup__current-image {
          display: flex;
          justify-content: center;
          width: 962px;
          height: 536px;

          @media screen and (max-width: 1280px) {
            width: 80.16vw;
            height: 41.88vw;
          }

          @media screen and (max-width: 568px) {
            width: 100%;
            height: auto;
            max-height: 65vh;
          }

          .el-image {
            display: flex;
            justify-content: center;

            img {
              width: auto;
              height: 100%;
              border-radius: 16px;
            }
          }
        }

        .gallery-album-popup__footer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 24px;

          @media screen and (max-width: 568px) {
            position: absolute;
            bottom: 44px;
            flex-direction: column;
            justify-content: center;
            margin-top: 20px;
          }

          .gallery-album-popup__image-title {
            padding-right: 32px;
            font-weight: bold;
            font-size: 1.25rem;
            color: var(--gallery-album-popup-text);
            word-break: break-word;

            @media screen and (max-width: 1280px) {
              @include fluid-font-size(48rem, 80rem, 1rem, 1.25rem);
            }

            @media screen and (max-width: 768px) {
              @include fluid-font-size(35.5rem, 48rem, 0.75rem, 1rem);
            }

            @media screen and (max-width: 568px) {
              width: 100%;
              height: 60px;
              padding: 0 32px;
              font-size: 0.75rem;
              text-align: center;
            }
          }

          .gallery-album-popup__control {
            align-self: flex-end;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media screen and (max-width: 568px) {
              align-self: center;
            }

            .gallery-album-popup__photo-num {
              min-width: 100px;
              font-weight: bold;
              font-size: 2rem;
              text-align: center;

              @media screen and (max-width: 1280px) {
                @include fluid-font-size(48rem, 80rem, 1.5rem, 2rem);
              }

              @media screen and (max-width: 568px) {
                font-size: 2rem;
              }

              sup {
                margin-left: 3px;
                font-size: 63%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
